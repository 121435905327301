import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchLatestUpdatedMovies = createAsyncThunk(
  'banners/fetchLatestUpdatedMovies', // Action type
  async () => {
    try {
      
      const response = await fetch('https://api.iott.co.in/api/videos/getdashboardbanners');
      const data = await response.json();
      // console.log('Fetched banners:', data); 

      if (data && Array.isArray(data)) {
        return data; 
      }
    } catch (error) {
      return error.message; 
    }
  }
);

const bannersSlice = createSlice({
  name: 'banners', 
  initialState: {
    banners: [], 
    isLoading: false,
    error: null, 
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLatestUpdatedMovies.pending, (state) => {
        state.isLoading = true;
        state.error = null; 
      })
      .addCase(fetchLatestUpdatedMovies.fulfilled, (state, action) => {
        state.isLoading = false;
        state.banners = action.payload; 
      })
      .addCase(fetchLatestUpdatedMovies.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || 'Failed to fetch banners';
        // state.banners = []; // Clear banners on error
      });
  },
});

export default bannersSlice.reducer; 
