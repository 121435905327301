import {
  Box,
  Grid,
  Typography,
  TextField,
  Checkbox,
  Button,
  InputAdornment,
  IconButton,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import "./index.css";
import { Link } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
 
import { useNavigate, useLocation } from "react-router-dom";
 
import { Formik } from "formik";
import * as Yup from "yup";
 
import { useDispatch, useSelector } from "react-redux";
import { authRegistration } from "../../../Redux/reducers/authRegisterReducer";
import {
  authLogin,
  loginSuccess,
} from "../../../Redux/reducers/authLoginReducer";
import statusCodes from "../../../utils/statusCodes";
import { getIp } from "../../../utils/getIp";
import axios from "axios";
import endpoints from "../../../Endpoints/endpoints";
import { getAuthData } from "../../../utils/auth";
 
const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const authData = getAuthData;
 
  const [registerError, setRegisterError] = useState("");
 
  const { authRegisterResponse, authRegisterStatus } = useSelector(
    (state) => state.rootReducer.authRegisterReducer
  );
  const { authDetails, authLoginStatus } = useSelector(
    (state) => state.rootReducer.authLoginReducer
  );
 
  const [showPassword, setShowPassword] = useState(false);
 
  const [openDialog, setOpenDialog] = useState(false); // State to control dialog visibility
 
  const [loginObj, setLoginObj] = useState({});
 
  const [referralCode, setReferralCode] = useState(""); // State for storing referral code
  const [error, setError] = useState(""); // State for error messages
 
  const handleClickShowPassword = () => setShowPassword((show) => !show);
 
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
 
  const ipAddress = localStorage.getItem("ipAddress");
 
  useEffect(() => {
    if (ipAddress === "" || ipAddress === null || ipAddress === undefined) {
      getIp();
    }
  }, []);
  const getConditionStyle = (conditionMet) => ({
    color: conditionMet ? "#008000" : "#FF0000",
    // textDecoration: conditionMet ? "line-through" : "none",
    fontSize: "0.875rem",
  });
 
  // State to manage helper text visibility
  const [showHelperText, setShowHelperText] = React.useState(false);
 
  const validateSchema = Yup.object({
    name: Yup.string().required("Enter your name"),
    email: Yup.string()
      .required("Enter a Email")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address"
      ),
    mobileNumber: Yup.string()
      .required("Enter a valid mobile number")
      // .matches(/^\+?[0-9]{12}$/, "Phone number must be 12 digits and start with a '+' symbol, making a total of 13 characters")
      // // Custom validation to ensure the number is greater than 10 digits
      // .test("length-check", "Phone number must be greater than 10 digits", function (value) {
      //   const digits = value?.replace(/\D/g, ""); // Remove non-digit characters like '+'
      //   return digits && digits.length > 10;
      // }),
      .matches(/^(?:\+?[0-9]{10,12})$/, "Enter a valid mobile number"),
   
      password: Yup.string()
      .required("Password is required")
      .min(8, "Password length should be at least 8 characters")
      .matches(
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_])/,
        "Password is invalid. The password must be at least 8 characters long and include at least 1 uppercase letter, 1 lowercase letter, 1 special character, and 1 number."
      ),
    termsAndConditions: Yup.boolean().oneOf(
      [true],
      "You must accept the terms and conditions."
    ),
 
    // confirmPassword: Yup.string()
    //   .required("Confirm password is required")
    //   .oneOf([Yup.ref("password"), null], "Password Mismatch"),
    // termsAndConditions: Yup.boolean().oneOf(
    //   [true],
    //   "Please accept the Terms and Conditions"
    // ),
  });
  const handleTandC = () => {
    navigate("/t&c");
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
    navigate("/refferal_code");
  };
 
  const fetchReferralCode = async (userId) => {
    try {
      // console.log(userId);
      const response = await axios.post(
        // "https://api.iott.co.in/api/referral/get_referralcode",
        endpoints.referral_get_referralcode,
        { UserId: userId }
      );
      if (response.status === 200) {
        // console.log(response.data);
        navigate("/refferal_code");
      } else {
        throw new Error("Failed to fetch referral code.");
      }
    } catch (error) {
      setRegisterError("Error fetching referral code. Please try again later.");
      console.error("Error fetching referral code:", error);
    }
  };
 
  return (
    <Grid
      container
      className="bg-image"
      minHeight={"100vh"}
      width={"100vw"}
      p={5}
    >
      <Grid
        item
        xs={12}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box
          // width={"28vw"}
          sx={{ width: { xs: "100%", sm: "40%", lg: "30%" } }}
          bgcolor={"#010113"}
          border={"5px solid #FFDA78"}
          borderRadius={6}
        >
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ pt: { xs: 1, sm: 2 }, pb: { xs: 1.5, sm: 3 } }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "row", sm: "column" },
                alignItems: "center",
              }}
            >
              <img
                src={"https://bucketblob.iott.co.in/Icons/logo.png"}
                alt="logo"
                className="logo"
                onClick={() => navigate("/")}
              />
 
              <Typography
                mb={1}
                fontFamily={"Merriweather"}
                fontWeight={600}
                fontSize={26}
                color={"#FFDA78"}
                sx={{ fontSize: { xs: "20px", sm: "30px" } }}
              >
                SIGNUP
              </Typography>
            </Box>
            <Formik
              initialValues={{
                name: "",
                email: "",
                mobileNumber: "",
                password: "",
                confirmPassword: "",
                termsAndConditions: false,
              }}
              validationSchema={validateSchema}
              onSubmit={(values, { setSubmitting, setErrors }) => {
                // console.log("Form Values:", values.name);
                if (!values.termsAndConditions) {
                  // Notify user to check the checkbox
                  setErrors({
                    termsAndConditions:
                      "You must accept the terms and conditions.",
                  });
                  setSubmitting(false);
                  return; // Stop the submission process
                }
               
               
                const object = {
                  Name: values.name,
 
                  PhoneNo: values.mobileNumber,
 
                  Email: values.email,
 
                  Password: values.password,
 
                  DeviceName: ipAddress,
                };
 
                dispatch(authRegistration(object))
                  .then((response) => {
                    if (response.meta.requestStatus === "fulfilled") {
                      const userId = response.payload[0].Id;
                      // console.log(userId);
                      fetchReferralCode(userId);
                      navigate("/refferal_code");
                      localStorage.setItem(
                        "loginUser",
                        JSON.stringify(response.payload[0])
                      );
                      dispatch(authLogin(object))
                        .then((response) => {
                          // console.log("response Login:", response);
 
                          if (response.meta.requestStatus === "fulfilled") {
                            // setRegisterError("Registered Successfully !");
                            // setOpenDialog(true);
                            // fetchReferralCode();
                            // const userId = response.payload.userId; // Adjust according to your response structure
                            // fetchReferralCode(userId);
                            // navigate("/refferal_code");
                          } else if (
                            response.meta.requestStatus === "rejected"
                          ) {
                            if (response.error.name === "TypeError") {
                              setRegisterError(
                                "Check Your Internet Connectivity"
                              );
                            } else {
                              setRegisterError(
                                "Something Went Wrong, please try again later"
                              );
                            }
                          }
                        })
                        .catch((error) => {
                          // console.log("Login Error:", error);
                          // Handle login error...
                        });
                    } else if (response.meta.requestStatus === "rejected") {
                      if (
                        response.error.message ===
                        "Request failed with status code 400"
                      ) {
                        setRegisterError("User Exist");
                      } else if (response.error.name === "TypeError") {
                        setRegisterError("Check Your Internet Connectivity");
                      } else {
                        setRegisterError(
                          "Something Went Wrong, please try again later"
                        );
                      }
                    }
                  })
                  .catch((error) => {
                    console.error("Register Error:", error);
                  });
              }}
            >
              {(formik) => {
                return (
                  <form onSubmit={formik.handleSubmit} className="signup-form">
                    <TextField
                      sx={{
                        mb: 1,
                        "& .MuiInputBase-input::placeholder": {
                          color: "#FFDA78",
                          opacity: 1,
                        },
                        "& input:-webkit-autofill": {
                          WebkitBoxShadow: "0 0 0 1000px black inset",
                          WebkitTextFillColor: "#FFDA78",
                        },
                      }}
                      fullWidth
                      placeholder="Name"
                      variant="standard"
                      {...formik.getFieldProps("name")}
                      helperText={formik.touched.name && formik.errors.name}
                    />
 
                    <TextField
                      sx={{
                        mb: 1,
                        "& .MuiInputBase-input::placeholder": {
                          color: "#FFDA78",
                          opacity: 1,
                        },
                        "& input:-webkit-autofill": {
                          WebkitBoxShadow: "0 0 0 1000px black inset",
                          WebkitTextFillColor: "#FFDA78",
                        },
                      }}
                      fullWidth
                      placeholder="Email"
                      variant="standard"
                      {...formik.getFieldProps("email")}
                      helperText={formik.touched.email && formik.errors.email}
                    />
 
                    <TextField
                      sx={{
                        mb: 1,
                        "& .MuiInputBase-input::placeholder": {
                          color: "#FFDA78",
                          opacity: 1,
                        },
                        "& input:-webkit-autofill": {
                          WebkitBoxShadow: "0 0 0 1000px black inset",
                          WebkitTextFillColor: "#FFDA78",
                        },
                      }}
                      fullWidth
                      placeholder="Mobile Number"
                      variant="standard"
                      {...formik.getFieldProps("mobileNumber")}
                      helperText={
                        formik.touched.mobileNumber &&
                        formik.errors.mobileNumber
                      }
                      inputProps={{
                        maxLength: 13, // Limit input to 12 digits
                        inputMode: "numeric", // Ensure that only numeric input is allowed
                        pattern: "\\+?[0-9]*",
                      }}
                      onInput={(e) => {
                        // Allow only digits to be entered
                        e.target.value = e.target.value.replace(/[^+\d]/g, "");
                      }}
                    />
 
                    <TextField
                    sx={{
                      mb: 1,
                      "& .MuiInputBase-input::placeholder": {
                        color: "#FFDA78",
                        opacity: 1,
                      },
                      color: "#FFDA78",
                      "& input:-webkit-autofill": {
                        WebkitBoxShadow: "0 0 0 1000px black inset",
                        WebkitTextFillColor: "#FFDA78", // Text color for autofill
                      },
                    }}
                     
                      fullWidth
                      placeholder="Set - Password"
                      variant="standard"
                      type={showPassword ? "text" : "password"}
                      {...formik.getFieldProps("password")}
                      // helperText={
                      //   formik.touched.password &&
                      //   formik.errors.password
                      // }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment  position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff sx={{ color: "#FFDA78" }} />
                              ) : (
                                <Visibility sx={{ color: "#FFDA78" }} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onFocus={() => setShowHelperText(true)} // Show helper text on focus
                      onBlur={() => setShowHelperText(false)} // Hide helper text on blur
                      onCopy={(e) => e.preventDefault()} // Prevent copying from the password field
                      helperText={
                        formik.touched.password && formik.errors.password ? (
                          formik.errors.password // Display invalid password message
                        ) : (
                          showHelperText && ( // Display the helper text if showHelperText is true
                            <div>
                              <p style={{ color: "#FF0000", fontSize: "0.875rem" }}>
                                <span style={getConditionStyle(/[A-Z]/.test(formik.values.password))}>
                                  one uppercase letter,
                                </span>{" "}
                                <span style={getConditionStyle(/[a-z]/.test(formik.values.password))}>
                                  one lowercase letter,
                                </span>{" "}
                                <span style={getConditionStyle(/[0-9]/.test(formik.values.password))}>
                                  one number,
                                </span>{" "}
                                <span style={getConditionStyle(/[\W_]/.test(formik.values.password))}>
                                  one special character,
                                </span>{" "}
                                <span style={getConditionStyle(formik.values.password.length >= 8)}>
                                  Password length should be at least 8 characters.
                                </span>
                              </p>
                            </div>
                          )
                        )
                      }
                      FormHelperTextProps={{
                        sx: {
                          color: "#FF0000", // Red color for error messages
                          fontSize: "0.875rem",
                        },
                      }}
                    />
 
                    {/* <TextField
                      sx={{
                        mb: 1,
                        "& .MuiInputBase-input::placeholder": {
                          color: "#FFDA78",
                        },
                      }}
                      fullWidth
                      placeholder="Confirm Password"
                      variant="standard"
                      type={showPassword ? "text" : "password"}
                      {...formik.getFieldProps("confirmPassword")}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                          </InputAdornment>
                        ),
                      }}
                      onPaste={(e) => e.preventDefault()} // Prevent pasting in the confirm password field
                      helperText={
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword
                      }
                      FormHelperTextProps={{
                        sx: {
                          color: "#FF0000", // Red color for error messages
                          fontSize: "0.875rem",
                        },
                      }}
                    /> */}
 
                    <Box textAlign={"left"}>
                      <Checkbox
                        id="checkbox"
                        {...formik.getFieldProps("termsAndConditions")}
                      />
                      <label
                        className="terms-conditions"
                        htmlFor="checkbox"
                        onClick={handleTandC}
                        style={{
                          fontFamily: "Quicksand",
                          textDecoration: "underline",
                          fontSize: "9px",
                        }}
                      >
                        Terms and Conditions
                      </label>
                      <p className="terms-conditions-error">
                        {formik.touched.termsAndConditions &&
                          formik.errors.termsAndConditions}
                      </p>
                    </Box>
 
                    <Box mt={2} mb={1} fullWidth textAlign={"center"}>
                      <Button
                        type="submit"
                        fullWidth
                        disabled={authRegisterStatus === statusCodes.LOADING}
                        variant="contained"
                        sx={{
                          mt: 1,
                          backgroundColor: "#FFDA78",
                          color: "#010101",
                          fontWeight: "900",
                          fontFamily: "Merriweather",
                          "&:hover": {
                            backgroundColor: "#FFDA78", // Keep the same background color on hover
                          },
                        }}
                      >
                        {authRegisterStatus === statusCodes.PENDING ? (
                          <CircularProgress
                            size={24}
                            style={{ color: "#010101" }}
                          />
                        ) : (
                          "SIGN UP"
                        )}
                      </Button>
                    </Box>
 
                    {registerError && (
                      <Typography
                        textAlign={"center"}
                        color={"#B8001F"}
                        fontSize={14}
                        fontFamily={"Quicksand"}
                        mb={1}
                        mt={1}
                      >
                        {registerError}
                      </Typography>
                    )}
                  </form>
                );
              }}
            </Formik>
 
            {authRegisterStatus === statusCodes.LOADING && (
              <Grid container justifyContent={"center"} alignItems={"center"}>
                <CircularProgress color="warning" />
              </Grid>
            )}
            <Typography className="info" fontFamily={"Quicksand"}>
              Already have an account ?{" "}
              <span>
                <Link
                  to="/login"
                  style={{
                    color: "#FFDA78",
                    textDecoration: "underline",
                    fontFamily: "Merriweather",
                    fontSize: "12px",
                  }}
                >
                  Login
                </Link>
              </span>{" "}
            </Typography>
          </Box>
        </Box>
      </Grid>
      {/* <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle
          sx={{
            fontFamily: "Merriweather",
            fontSize: { xs: "12px", sm: "18px" },
            fontWeight: { xs: 600, sm: 700 },
          }}
        >
          Registration Successful
        </DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              fontFamily: "Quicksand",
              fontSize: { xs: "10px", sm: "15px" },
              fontWeight: { xs: 500, sm: 600 },
            }}
          >
            You have registered successfully. Please go login page
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog> */}
    </Grid>
  );
};
 
export default Register;