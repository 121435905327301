import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import { useEffect, useRef, useState } from "react";
import { fetchVideoById, setMovieState } from "../../../Redux/reducers/videos/getVideoByIdReducer";
import { AppBar, CircularProgress, Container, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PlayerControls from "../PlayerControls";
import screenfull from "screenfull";
import { useLocation } from "react-router-dom";
import "./index.css";
import statusCodes from "../../../utils/statusCodes";
import { fetchAddWatchHistory } from "../../../Redux/reducers/watchHistory/addWatchHistoryReducer";
import { fetchWatchTimeByUserId } from "../../../Redux/reducers/player/getWatchTimeReducer";
import { getAuthData } from "../../../utils/auth";
import localStorage from "redux-persist/es/storage";
import { fetchClearSingleWatchHistory } from "../../../Redux/reducers/watchHistory/clearSingleWatchHistoryReducer";
import { fetchWatchHistory } from "../../../Redux/reducers/watchHistory/getWatchHistoryReducer";
 
const useStyles = makeStyles({
  // playerWrapper: {
  //     width: "100%",
  //     display: "flex",
  //     justifyContent: "center",
  //     alignItems: "center",
  //     height: "100vh",
  //     backgroundColor:"#000"
  // },
 
  playerWrapper: {
    width: "100%",
  },
 
});
 
const format = (seconds) => {
  if (isNaN(seconds)) {
    return "00:00";
  }
 
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");
 
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  }
  return `${mm.toString().padStart(2, "0")}:${ss}`;
};
 
let count = 0;
 
const VideoPlayer = () => {
  const authData = getAuthData();
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [isBuffering, setIsBuffering] = useState(false);
  const playerState = useSelector(
    (state) => state.rootReducer.playerStateReducer
  );
  
  const {  videoById, videoByIdFetchStatus } = useSelector(
    (state) => state.rootReducer.getVideoByIdReducer
  );

  const {userId,
    watchTime,
    isMoviePresent,
    getWatchTimeFetchStatus } = useSelector(state => state.rootReducer.getWatchTimeReducer)

  const {categorizedWatchHistory} = useSelector(state => state.rootReducer.getWatchHistoryReducer)

  const [clear, setClear] = useState(false);

  // const categoryValue = movieState?.Category;
  // const movieId = movieState?.Id;
 
  // const categoryValue = videoById[0]?.Category;
  // const movieId = videoById[0]?.Id;
  const categoryValue = videoById.length > 0 ? videoById[0]?.Category : null;
  const movieId = videoById.length > 0 ? videoById[0]?.Id : null;
 
  // useEffect(() => {
  //   if (videoById.length === 0) {
  //     const fetchVideoByIdJSON = { fetchedMovieId: id, categoryValue: categoryValue };
  //     dispatch(fetchVideoById(fetchVideoByIdJSON));
  //   }
  // }, [dispatch, id, categoryValue, videoById]);
 
  


////////////////// anil update this functionality ////////////////////////

useEffect(() => {
  const fetchData = async () => {
    try {
      setLoading(true); 
      const fetchVideoByIdJSON = { fetchedMovieId: id, categoryValue: categoryValue };
      dispatch(fetchVideoById(fetchVideoByIdJSON));
    } catch (error) {
      console.error("Network error:", error);
      setLoading(false); 
    }finally {
      setLoading(false);
     }
  };

  if (videoById.length === 0) {
    fetchData();
  }
}, [dispatch, id, categoryValue, videoById]);

///////////////////////////////////////////////////////////////
  // useEffect(() => {
  //   const fetchVideoByIdJSON = {
  //     fetchedMovieId: movieId,
  //     categoryValue: categoryValue,
  //   };
  //   dispatch(fetchVideoById(fetchVideoByIdJSON));
  // }, [dispatch, id]);
 
  // useEffect(() => {
  //   // Initialize state from localStorage if available
  //   const storedVideoById = JSON.parse(localStorage.getItem("videoById"));
  //   if (storedVideoById) {
  //     dispatch(setMovieState({ videoById: storedVideoById }));
  //   } else {
  //     const fetchVideoByIdJSON = { fetchedMovieId: movieId, categoryValue: categoryValue };
  //     dispatch(fetchVideoById(fetchVideoByIdJSON));
  //   }
  // }, [dispatch, id, movieId, categoryValue]);

  useEffect(() => {
    const fetchWatchDataJSON = {
      fetchedMovieId: parseInt(id),
      userId: authData?.auth?.Id,
    }
    dispatch(fetchWatchTimeByUserId(fetchWatchDataJSON))
    //console.log('vid h here', fetchWatchDataJSON)
  }, [])

  
  
  useEffect(() => {
      dispatch(fetchWatchHistory(authData?.auth?.Id));
    }, [clear, dispatch, authData?.auth?.Id]);

  const iter = useRef(false)

  // for only pushing in today
  // useEffect(() => {
  //   const updateWatchHistory = async () => {
  //     if (authData?.auth?.Id && id && !iter.current) {        
  //       console.log('...', categorizedWatchHistory["Today"])
  //       iter.current = true
  //       if (categorizedWatchHistory && categorizedWatchHistory["Today"]) {
  //         const isAlreadyPresent = categorizedWatchHistory["Today"].some(item => item.Id == id)
  //         console.log('ue being called', isAlreadyPresent)
  //         if (isAlreadyPresent) {
  //           const clearWatchHistoryJSON = {
  //             movieId: id,
  //             userId: authData?.auth?.Id,
  //           };
  //           await dispatch(fetchClearSingleWatchHistory(clearWatchHistoryJSON))
  //         }          
  //       }
  //       const addWatchHistoryJson = {
  //         UserId: authData?.auth?.Id,
  //         MovieId: id,
  //       };
  //       console.log('ue being called out ', iter.current)
  //       await dispatch(fetchAddWatchHistory(addWatchHistoryJson));                      
  //     }
  //   }
  //   updateWatchHistory()    
  // }, []);
 
  // for removing in previous timelines (1 month ago, 1 week ago, etc..) and adding newly 
  useEffect(() => {
    const updateWatchHistory = async () => {
      if (authData?.auth?.Id && id && !iter.current) {                
        iter.current = true
        if (categorizedWatchHistory) {
          for (let item in categorizedWatchHistory) {
            //console.log('ue being called', categorizedWatchHistory[item])
            const isAlreadyPresent = categorizedWatchHistory[item].some(item => item.Id == id)
            if (isAlreadyPresent) {
              const clearWatchHistoryJSON = {
                movieId: id,
                userId: authData?.auth?.Id,
              };
              await dispatch(fetchClearSingleWatchHistory(clearWatchHistoryJSON))              
            }          
          }                            
        }
        const addWatchHistoryJson = {
          UserId: authData?.auth?.Id,
          MovieId: id,
        };
        //console.log('ue being called out ', iter.current)
        await dispatch(fetchAddWatchHistory(addWatchHistoryJson));                      
      }
    }
    updateWatchHistory()    
  }, []);
 
  const classes = useStyles();
  const playerRef = useRef(null);
  const playerContainerRef = useRef(null);
  const controlsRef = useRef(null);
 
  const movieData =
    playerState &&
    playerState.seasonNo !== null &&
    playerState.episodeNo !== null
      ? videoById[0]?.Seasons[playerState.seasonNo]?.Episodes[
          playerState.episodeNo
        ]?.EpisodeUrl
      : videoById[0]?.Id === 1884
      ? videoById[0]?.Quality1080
      : videoById[0]?.Quality720;
 
  const [state, setState] = useState({
    playing: false,
    muted: false,
    volume: 0.5,
    playbackRate: 1.0,
    played: 0,
    seeking: false,
    screenRotation: false,
  });
 
  const [timeDisplayFormat, setTimeDisplayFormat] = useState("normal");
 
  const [volumeValue, setVolumeValue] = useState(null);
 
  useEffect(() => {
    document.addEventListener("keydown", triggeringKeys, true);
    return () => document.removeEventListener("keydown", triggeringKeys);
  });
 
  const handleVolumeChange = (e, newValue) => {
    setState(prev => ({
      ...prev,
      volume: parseFloat(newValue / 100),
      muted: newValue === 0 ? true : false,
    }));
    setVolumeValue(parseFloat(newValue / 100));
  };
 
  const { playing, muted, volume, playbackRate, played, seeking } = state;
 
  const triggeringKeys = (e) => {
    if (e.key === "ArrowLeft") {      
      playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);      
    } else if (e.key === "ArrowRight") {
      playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
    }
  };

  function convertToSeconds(timeString) { const [hours, minutes, seconds] = timeString.split(':').map(Number); return (hours * 3600) + (minutes * 60) + seconds; }

  const didSeek = useRef(false)

  const continuePlay = () => {
    //console.log('watch time g', isMoviePresent)
    if (isMoviePresent && !didSeek.current && convertToSeconds(watchTime) > 300) {
      didSeek.current = true
      //console.log('watch time g', watchTime)
      playerRef.current.seekTo(convertToSeconds(watchTime), 'seconds')
    }
  }

  // const continuePlay = () => {
  //   playerRef.current.seekTo(convertToSeconds(watchTime), 'seconds')
  //   console.log('continue play called')
  // }

  // useEffect(() => {
  //   console.log('inside playerRef', playerRef.current)
  //   if (playerRef.current) {
  //     playerRef.current.seekTo(1350, 'seconds')
  //   }
  // }, playerRef)
 
  const handlePlayPause = () => {
    setState(prev => ({ ...prev, playing: !state.playing }));    
  };
 
  const handleRewind = () => {
    const newValue = playerRef.current.getCurrentTime() - 10 < 0 ? 0 : playerRef.current.getCurrentTime() - 10
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
    const isSeeking = parseInt(newValue) === parseInt(playerRef.current.getCurrentTime())
    // console.log('vd new value', playerRef.current.getCurrentTime(), newValue)
    if (!isSeeking && playerRef.current.getDuration()) {
      window.location.reload()
      //console.log('vd player', playerRef.current)
    }
  };
 
  const handleFastForward = () => {
    const newValue = playerRef.current.getCurrentTime() + 10 > playerRef.current.getDuration() ? playerRef.current.getDuration() : playerRef.current.getCurrentTime() + 10
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
    const isSeeking = parseInt(newValue) === parseInt(playerRef.current.getCurrentTime())
    if (!isSeeking && playerRef.current.getDuration()) {
      window.location.reload()
      //console.log('vd player', playerRef.current)
    }
  };
 
  const handleMute = () => {
    setState(prev => ({ ...prev, muted: !state.muted }));
  };
 
  const handleVolumeSeekUp = (e, newValue) => {
    setState(prev => ({
      ...prev,
      volume: parseFloat(newValue / 100),
      muted: newValue === 0 ? true : false,
    }));
  };
 
  const handlePlaybackRateChange = (rate) => {
    setState(prev => ({ ...prev, playbackRate: rate }));
  };
 
  const handleOnToggleFullScreen = () => {
    screenfull.toggle(playerContainerRef.current);
  };
 
  const handleProgress = (changeState) => {
    if (count > 1) {
      controlsRef.current.style.visibility = "hidden";
      count = 0;
    }
 
    if (controlsRef.current.style.visibility === "visible") {
      count += 1;
    }
 
    if (!state.seeking) {
      setState(prev => ({ ...prev, ...changeState}));
      // console.log('inside vd:', state.played )
      // setState(prev => ({ ...prev, ...changeState}));
    }

    if (playerRef.current) {
      const duration = playerRef.current.getDuration();
      if (duration === 0 || isNaN(duration)) {
        setLoading(true);
      } else {
        setLoading(false);
      }
    }
  };
 
  const handleSeekChange = (e, newValue) => {
    e.stopPropagation()
    setState(prev => ({ ...prev, played: parseFloat(newValue / 100) }));    
    //console.log('vd seek', state.seeking, parseFloat(newValue / 100))
  };
 
  const handleSeekMouseDown = (e) => {
    e.stopPropagation()
    setState(prev => ({ ...prev, seeking: true }));
    //console.log('vd mousedown', played, state.seeking)
  };
 
  const handleSeekMouseUp = (e, newValue) => {
    e.stopPropagation()
    setState(prev => ({ ...prev, seeking: false}));   
    playerRef.current.seekTo(
      (newValue / 100) * playerRef.current.getDuration()
    );
    const isSeeking = parseInt((newValue / 100) * playerRef.current.getDuration())===parseInt(playerRef.current.getCurrentTime())
    if (!isSeeking && playerRef.current.getDuration()) {
      window.location.reload()
      //console.log('vd player', playerRef.current)
    }
    // console.log('vd seek To', parseInt((newValue / 100) * playerRef.current.getDuration()), playerRef.current)
    // console.log('vd buffering ok', isBuffering)          
    // console.log('vd mouseup', newValue/100, played, playerRef.current.getDuration(), state.seeking)
    // setLoading(true); // Show loader when seeking
  };
  
  
 
  const handleChangeDisplayFormat = () => {
    setTimeDisplayFormat(
      timeDisplayFormat === "normal" ? "remaining" : "normal"
    );
  };
 
  //   const handleMouseMove = () => {
  //     controlsRef.current.style.visibility = "visible";
  //     count = 0;
  //   };
  const handleMouseMove = () => {
    if (controlsRef.current) {
      controlsRef.current.style.visibility = "visible";
      count = 0;
    }
  };
  // const currentTime = playerRef.current ? playerRef.current.getCurrentTime() : "00:00";
  // const duration = playerRef.current ? playerRef.current.getDuration() : "00:00";
 
  // const elapsedTime = timeDisplayFormat === "normal"
  //     ? format(currentTime)
  //     : `-${format(duration - currentTime)}`;
  // const totalDuration = format(duration);
 
  const currentTime = playerRef.current
    ? playerRef.current.getCurrentTime()
    : "00:00";
  const duration = playerRef.current
    ? playerRef.current.getDuration()
    : "00:00";
 
  const elapsedTime =
    timeDisplayFormat === "normal"
      ? format(currentTime)
      : `-${format(duration - currentTime)}`;
  const totalDuration = format(duration);

// //////////////////////////////version 3

const [allMovieData, setAllMovieData] = useState(() => {
  // Initialize state with data from localStorage or an empty array
  const savedData = sessionStorage.getItem("movieData");
  // return savedData ? JSON.parse(savedData) : [];
});

//console.log("Retrieved from localStorage:", localStorage.getItem("movieData"));
  const handleResolutionChange = (resolution) => {
    // Handle the resolution change logic here
    // console.log('Selected resolution:', resolution);
  };
 
  return (
    <div className="video-container">
      <AppBar position="fixed"></AppBar>
      <Container maxWidth="lg">
        <div
          ref={playerContainerRef}
          className={classes.playerWrapper}
          onMouseMove={handleMouseMove}
        >
          {/* {videoByIdFetchStatus === statusCodes.LOADING && ( */}
          {loading && (
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              bgcolor={"#000"}
              minHeight={"100vh"}
            >
              <CircularProgress color="warning" />
            </Grid>
            )}
          {/* )} */}
          {videoByIdFetchStatus === statusCodes.SUCCESS && !loading && (
            <>
              <div className="video-player">
                <ReactPlayer
                  ref={playerRef}
                  width={"100%"}
                  height="100%"
                  url={movieData}
                  muted={muted}
                  playing={playing}
                  volume={volume}
                  playbackRate={playbackRate}
                  onProgress={handleProgress}
                  onBuffer={() => setIsBuffering(true)} // Handle buffering
                  onBufferEnd={() => setIsBuffering(false)}
                  onReady={continuePlay}
                />
                  {isBuffering && (
                  <div className="buffering-overlay">
                    <CircularProgress color="warning" />
                  </div>
                )}
 
              </div>
              <PlayerControls
                data={videoById[0]}
                ref={controlsRef}
                onPlayPause={handlePlayPause}
                playing={playing}
                onRewind={handleRewind}
                onFastForward={handleFastForward}
                muted={muted}
                onMute={handleMute}
                onVolumeChange={handleVolumeChange}
                onVolumeSeekUp={handleVolumeSeekUp}
                volume={volume}
                playbackRate={playbackRate}
                onPlaybackRateChange={handlePlaybackRateChange}
                onToggleFullScreen={handleOnToggleFullScreen}
                played={played}
                onSeek={handleSeekChange}
                onSeekMouseDown={handleSeekMouseDown}
                onSeekMouseUp={handleSeekMouseUp}
                elapsedTime={elapsedTime}
                totalDuration={totalDuration}
                onChangeDisplayFormat={handleChangeDisplayFormat}
                onClickResolution={handleResolutionChange}
                authId = {authData?.auth?.Id}
                currentTime = {currentTime}
                // screenRotation={screenRotation}
              />
            </>
          )}
        </div>
      </Container>
    </div>
  );
};
 
export default VideoPlayer;
 
 