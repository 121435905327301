import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../../utils/statusCodes";
import endpoints from "../../../Endpoints/endpoints";

const addWatchHistoryApi = endpoints.addWatchHistory;

const initial_addWatchHistory = {
  addWatchHistory: [],
  addWatchHistoryFetchStatus: statusCodes.IDLE,
};

// export const fetchAddWatchHistory = createAsyncThunk(
//   "addFavorite/post",
//   async (addWatchHistoryJson) => {
//     try {
//       const addWatchHistoryMovie = await axios.post(
//         addWatchHistoryApi,
//         addWatchHistoryJson
//       );
//       return addWatchHistoryMovie;
//     } catch (error) {
//       const errorPayload = {
//         message: error.response.data,
//       };
//       // console.log(error.response.data);
//       throw errorPayload; // Throwing the error payload
//     }
//   }
// );

// const addWatchHistoryReducer = createSlice({
//   name: "addWatchHistoryReducer",
//   initialState: initial_addWatchHistory,
//   extraReducers: (builder) => {
//     builder
//       .addCase(fetchAddWatchHistory.pending, (state, action) => {
//         state.addWatchHistoryFetchStatus = statusCodes.LOADING;
//       })
//       .addCase(fetchAddWatchHistory.fulfilled, (state, action) => {
//         state.addWatchHistory = action.payload.data;
//         state.addWatchHistoryFetchStatus = statusCodes.SUCCESS;
//       })
//       .addCase(fetchAddWatchHistory.rejected, (state, action) => {
//         state.addWatchHistoryFetchStatus = statusCodes.ERROR;
//       });
//   },
// });


/////////// updated code on (15.11.2024)
export const fetchAddWatchHistory = createAsyncThunk(
  "addWatchHistory/post",
  async (addWatchHistoryJson, { rejectWithValue }) => {
    try {
      const response = await axios.post(addWatchHistoryApi, addWatchHistoryJson);
      return response.data; // Return only the response data
    } catch (error) {
      const errorPayload = error.response?.data?.message || "An error occurred";
      return rejectWithValue(errorPayload); // Use `rejectWithValue` for consistent error handling
    }
  }
);

const addWatchHistoryReducer = createSlice({
  name: "addWatchHistoryReducer",
  initialState: initial_addWatchHistory,
  reducers: {}, // No reducers needed if it's only managed via thunk
  extraReducers: (builder) => {
    builder
      .addCase(fetchAddWatchHistory.pending, (state) => {
        state.addWatchHistoryFetchStatus = statusCodes.LOADING;
      })
      .addCase(fetchAddWatchHistory.fulfilled, (state, action) => {
        state.addWatchHistory = action.payload; // Assign the serialized response data
        state.addWatchHistoryFetchStatus = statusCodes.SUCCESS;
      })
      .addCase(fetchAddWatchHistory.rejected, (state, action) => {
        state.addWatchHistoryFetchStatus = statusCodes.ERROR;
        console.error("Error posting watch history:", action.payload);
      });
  },
});




export default addWatchHistoryReducer.reducer;
