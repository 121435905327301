import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../utils/statusCodes";
import endpoints from "../../Endpoints/endpoints";

// const getByGenereApi = `${endpoints.getByGenere}?name=`;

const initial_banners = {
  comedyBanners: [],
  dramaBanners: [],
  actionBanners: [],
  romanceBanners: [],
  devotionalBanners: [],
  sciFiBanners: [],
  adventureBanners: [],
  horrorBanners: [],
  thrillerBanners: [],
  sportsBanners: [],
  familyBanners: [],
  comedyBannerFetchStatus: statusCodes.IDLE,
  dramaBannerFetchStatus: statusCodes.IDLE,
  actionBannerFetchStatus: statusCodes.IDLE,
  romanceBannerFetchStatus: statusCodes.IDLE,
  devotionalBannerFetchStatus: statusCodes.IDLE,
  sciFiBannerFetchStatus: statusCodes.IDLE,
  adventureBannerFetchStatus: statusCodes.IDLE,
  horrorBannerFetchStatus: statusCodes.IDLE,
  thrillerBannerFetchStatus: statusCodes.IDLE,
  sportsBannerFetchStatus: statusCodes.IDLE,
  fmailyBannerFetchStatus: statusCodes.IDLE,
};

const bannersByGenereReducer = createSlice({
  name: "bannersByGenereReducer",
  initialState: initial_banners,
  reducers: {
    clearAllGeners(state) {
      state.comedyBanners = [];
      state.dramaBanners = [];
      state.actionBanners = [];
      state.romanceBanners = [];
      state.devotionalBanners = [];
      state.sciFiBanners = [];
      state.adventureBanners = [];
      state.horrorBanners = [];
      state.thrillerBanners = [];
      state.sportsBanners = [];
      state.familyBanners = [];
      state.comedyBannerFetchStatus= statusCodes.IDLE;
      state.dramaBannerFetchStatus= statusCodes.IDLE;
      state.actionBannerFetchStatus= statusCodes.IDLE;
      state.romanceBannerFetchStatus= statusCodes.IDLE;
      state.devotionalBannerFetchStatus= statusCodes.IDLE;
      state.sciFiBannerFetchStatus= statusCodes.IDLE;
      state.adventureBannerFetchStatus= statusCodes.IDLE;
      state.horrorBannerFetchStatus= statusCodes.IDLE;
      state.thrillerBannerFetchStatus= statusCodes.IDLE;
      state.sportsBannerFetchStatus= statusCodes.IDLE;
      state.fmailyBannerFetchStatus= statusCodes.IDLE
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchByGenereDashboardBanners.pending, (state, action) => {
        state.genereBannerFetchStatus = statusCodes.LOADING;
        // console.log("dashboard banners by category data pending");
      })

      .addCase(fetchByGenereDashboardBanners.fulfilled, (state, action) => {
        // state.genere_banners = action.payload.data;
        // state.genereBannerFetchStatus = statusCodes.SUCCESS;
        // // console.log(state.genere_banners);
        const { gener, data } = action.payload;
        if (gener === "Comedy") {
          state.comedyBanners = data;
          state.comedyBannerFetchStatus = statusCodes.SUCCESS;
        } else if (gener === "Romance") {
          state.romanceBanners = data;
          state.romanceBannerFetchStatus = statusCodes.SUCCESS;
        } else if (gener === "Action") {
          state.actionBanners = data;
          state.actionBannerFetchStatus = statusCodes.SUCCESS;
        } else if (gener === "Drama") {
          state.dramaBanners = data;
          state.dramaBannerFetchStatus = statusCodes.SUCCESS;
        } else if (gener === "Devotional") {
          state.devotionalBanners = data;
          state.devotionalBannerFetchStatus = statusCodes.SUCCESS;
        } else if (gener === "Sci-Fi") {
          state.sciFiBanners = data;
          state.sciFiBannerFetchStatus = statusCodes.SUCCESS;
        } else if (gener === "Adventure") {
          state.adventureBanners = data;
          state.adventureBannerFetchStatus = statusCodes.SUCCESS;
        } else if (gener === "Horror") {
          state.horrorBanners = data;
          state.horrorBannerFetchStatus = statusCodes.SUCCESS;
        } else if (gener === "Thriller") {
          state.thrillerBanners = data;
          state.thrillerBannerFetchStatus = statusCodes.SUCCESS;
        } else if (gener === "Sports") {
          state.sportsBanners = data;
          state.sportsBannerFetchStatus = statusCodes.SUCCESS;
        } else if (gener === "Family") {
          state.familyBanners = data;
          state.fmailyBannerFetchStatus = statusCodes.SUCCESS;
        }
      })
      .addCase(fetchByGenereDashboardBanners.rejected, (state, action) => {
        // console.log("Error fetching comedy banners:", action.payload);
        // state.genereBannerFetchStatus = statusCodes.ERROR;
        // console.log("dashboard banners by category  data error");
      });
  },
});

// export const generBannerCache = new Map();

export const fetchByGenereDashboardBanners = createAsyncThunk(
  "homeDashboardGenereBanners/get",
  async (gener, { rejectWithValue }) => {
    //  if(generBannerCache.has(gener)){
    //   return generBannerCache.get(gener)
    //  }

    try {
      const banners = await axios.get(`${endpoints.getByGenere}?name=${gener}`);
      // generBannerCache.set(gener, banners.data)

      return { gener, data: banners.data };
    } catch (error) {
      // const errorPayload = {
      //   message: error.response.data.message,
      // };
      // throw errorPayload;
      return rejectWithValue(error.response.data);
    }
  }
);

export const { clearAllGeners } = bannersByGenereReducer.actions;
export default bannersByGenereReducer.reducer;
