import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../utils/statusCodes";
import endpoints from "../../Endpoints/endpoints"; 

const termAndConditionApi = endpoints.termAndCondition

const initial_termsAndCondition = { termsAndCondition: [], termsAndConditonFetchStatus: statusCodes.IDLE };

const termsAndConditonsReducer = createSlice({

  name: "termsAndConditonsReducer",
  initialState: initial_termsAndCondition,
  
  extraReducers: (builder) => {
    builder
      .addCase(fetchTermsAndConditions.pending, (state, action) => {
        state.termsAndConditonFetchStatus = statusCodes.LOADING;
        // console.log("Terms And conditons data pending");
      })
      .addCase(fetchTermsAndConditions.fulfilled, (state, action) => {
        state.termsAndCondition = action.payload;
        state.termsAndConditonFetchStatus = statusCodes.SUCCESS;
        // console.log(state.termsAndCondition);
      })
      .addCase(fetchTermsAndConditions.rejected, (state, action) => {
        state.termsAndConditonFetchStatus = statusCodes.ERROR;
        // console.log("Terms And conditons data error");
      });
  },
});


export const fetchTermsAndConditions = createAsyncThunk("termsAndConditions/get", async () => {

 
  try {
    const termAndConditionData = await axios.get(termAndConditionApi);
    return termAndConditionData.data;
  } catch (error) {
    const errorPayload = {
      message: error.response.data.message,
    };
    throw errorPayload;
  }


});

export default termsAndConditonsReducer.reducer;
