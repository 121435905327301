import { Box, Grid, Typography } from "@mui/material";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGetAllNotification } from "../../Redux/reducers/getAllNotificationReducer";
import { getAuthData } from "../../utils/auth";
import endpoints from "../../Endpoints/endpoints";
import axios from "axios";
 
const Notifications = () => {
  const dispatch = useDispatch();
  const authData = getAuthData();
  const [notifications, setNotifications] = useState([]);
  const { getAllNotificationsData, getAllNotificationFetchStatus } =
    useSelector((state) => state.rootReducer.getAllNotificationReducer);
  // console.log('inside get notifications')
  // console.log(getAllNotificationsData)
  useEffect(() => {
    if (getAllNotificationsData.length < 1) {
      dispatch(fetchGetAllNotification(authData?.authToken));
    }
  }, [dispatch, authData?.authToken, getAllNotificationsData.length]);
 
  const deleteNotification = async (movieId) => {
    try {
      const response = await axios.delete(endpoints.notification_Delete_Notification, {
        params: {
          MovieId: movieId,
        },
        headers: { Authorization: `Bearer ${authData?.authToken}` },
      });
 
      // console.log('Notification deleted successfully:', response.data);
      setNotifications(notifications.filter(notification => notification.MovieId !== movieId));
      dispatch(fetchGetAllNotification(authData?.authToken));
    } catch (error) {
      console.error('Error deleting notification:', error);
    }
  };

  function formatDateString(dateString) {
    // Step 1: Parse the date string into a Date object
    const dateObject = new Date(dateString);
  
    // Step 2: Format the date using Intl.DateTimeFormat
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(dateObject);
  
    return formattedDate; // Output: "13 Nov 2024"
  }
  
  
 
  return (
    <>
      <Grid
        container
        bgcolor={"#010113"}
        display={"flex"}
        justifyContent={"center"}
      >
        <Header />
        <Grid
          item
          mt={3}
          pb={4}
          bgcolor={"#FFDA78"}
          width={{ xs: "90vw", md: "60vw" }}
          //minHeight={"60vh"}
          sx={{
            height: '70vh',
            overflow: 'auto',
            "&::-webkit-scrollbar": {
            width: "7px", // Set your custom scrollbar width here
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#1c1c30",
            borderRadius: "2px",
          },
          }}
          borderRadius={"15px"}
          borderColor={"#fff"}
          mb={5}
        >
          <Box textAlign={"center"} pt={3}>
            <Typography fontFamily={"Quicksand"} fontWeight={700} fontSize={{xs: 20, md: 30}}>
              Notifications
            </Typography>
          </Box>
          <Box display={"flex"} justifyContent={"flex-end"} pb={4}>
            {/* Uncomment if you want to include a delete all button */}
            {/* <Typography
              fontFamily={"Quicksand"}
              fontWeight={700}
              fontSize={20}
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={deleteClick}
            >
              Delete All
            </Typography> */}
          </Box>
 
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={2}
          >
            {getAllNotificationFetchStatus === "loading" && (
              <h1 style={{ textAlign: "center" }}>Loading ....</h1>
            )}
            
            {getAllNotificationFetchStatus === "success" ? (
              getAllNotificationsData.length > 0 ? (
                getAllNotificationsData.map((item) => (
                  <Grid
                    key={item.Id}
                    p={2}
                    border={"2px solid #000000"}
                    borderRadius={"8px"}
                    width={{ xs: "95%", md: "85%" }}
                    height={"20%"}
                    display={"flex"}
                    flexDirection={{ xs: "column", sm: "row" }}
                    xs={12}
                    justifyContent={'space-evenly'}
                    sx={{backgroundColor:"white"}}
                  >
                    <Grid sx={{alignSelf:"center"}}>
                      <img
                        src={item.MoviePoster}
                        width={185}
                        height={"auto"}
                        style={{ borderRadius: "5px" }}
                        className="notification-img"                        
                      />
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      sx = {{
                        width:{xs: '100%', sm: '60%'}  
                      }}
                      justifyContent="space-between"
                      
                    >
                      <Typography
                        fontFamily={"Quicksand"}
                        fontWeight={700}
                        fontSize={{ xs: 18, md: 22 }}
                        textAlign={'center'}                  
                      >
                        {item.Text}
                      </Typography>
                      <Typography
                        fontFamily={"Quicksand"}
                        fontWeight={500}
                        fontSize={{ xs: 16, md: 20 }}
                        textAlign={'center'}
                      >
                        {item.Category}
                      </Typography>
                      <Typography
                        fontFamily={"Quicksand"}
                        fontWeight={300}
                        fontSize={{ xs: 14, md: 18 }}
                        textAlign={'center'}
                      >
                        {formatDateString(item.Day)}
                      </Typography>
                    </Grid>
                    {/* <Grid
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <MdDelete fontSize={20} style={{ cursor: "pointer" }} onClick={() => deleteNotification(item.MovieId)}/>
                    </Grid> */}
                  </Grid>
                ))
              ) : (
                <Typography
                  textAlign={"center"}
                  fontFamily={"Quicksand"}
                  fontWeight={600}
                  fontSize={20}
                  color={"#000"}
                >
                  No notifications yet
                </Typography>
              )
            ) : getAllNotificationFetchStatus === "error" ? (
              <Typography
                textAlign={"center"}
                fontFamily={"Quicksand"}
                fontWeight={600}
                fontSize={25}
                color={"#E4080A"}
              >
                Error fetching data. Please try again later
              </Typography>
            ) : null}
          </Box>
        </Grid>
        <Footer />
      </Grid>
    </>
  );
};
export default Notifications;
 
 
 
 
 