import React from "react";
import Header from "../Header";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Box, Typography } from "@mui/material";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import Searchbar from "../Searchbar";
import { setMovieState } from "../../Redux/reducers/videos/getVideoByIdReducer";
 
const SuggestedOrSearchMovies = () => {
  const dispatch = useDispatch();
 
  // Get movies data from Redux
  const { suggested_movies } = useSelector(
    (state) => state.rootReducer.getSuggestedMoviesReducer
  );
 
  const { searchResults } = useSelector(
    (state) => state.rootReducer.searchReducer
  );



  // const filteredSearchResults = searchResults.filter((banner, index, self) => index === self.findIndex(b => b.MovieName === banner.MovieName) );
  
  let filteredSearchResults = searchResults.filter((banner, index, self) => index === self.findIndex(b => b.MovieName === banner.MovieName) );
  if (filteredSearchResults.length === 1 && filteredSearchResults[0].message) {
    filteredSearchResults = []
  }

  const filteredSuggestedMovies = suggested_movies.filter((banner, index, self) => index === self.findIndex(b => b.MovieName === banner.MovieName) );
 
  // Determine the movies to display
  const moviesToDisplay = searchResults.length > 0 ? filteredSearchResults : filteredSuggestedMovies;
 
  // Heading text based on the search condition
  const headingText =
    searchResults.length > 0 ? "Search Results" : "Suggested Movies For you";
 
  // Dispatch action on movie click
  const onClickMvWatchBtn = (movie) => {
    dispatch(setMovieState(movie));
  };
 
  return (
    <>
      <Header />
      <Grid
        container
        bgcolor={"#000"}
        display={"flex"}
        flexDirection={"column"}
        gap={1}
        minHeight={500}
      >
        {/* Search Bar for Mobile View */}
        <Grid
          container
          xs={12}
          display={{ xs: "flex", md: "none" }}
          justifyContent={"center"}
          alignItems={"center"}
          p={1}
        >
          <Searchbar />
        </Grid>
 
        {/* Heading Section */}
        <Grid item p={2}>
          <Typography variant="h6" color={"#FFDA78"} fontWeight={"bold"}>
            {headingText}
          </Typography>
        </Grid>
 
        {/* Movies Display Section */}
        <Grid container p={2} spacing={2}>
          {moviesToDisplay.length === 0 ? (
            <Grid
              container
              minHeight={"50vh"}
              xs={12}
              p={2}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography
                fontFamily={"Quicksand"}
                fontSize={30}
                fontWeight={600}
                color={"#FFF"}
              >
                No Movies yet, visit again..!
              </Typography>
            </Grid>
          ) : (
            moviesToDisplay.map((movie, index) => (
              <Grid item xs={6} sm={4} md={2} key={movie?.Id || index}>
                <Box
                  onClick={() => onClickMvWatchBtn(movie)}
                  sx={{ textAlign: "center", cursor: "pointer" }}
                >
                  <Link
                    to={
                      movie?.MovieName
                        ? `/details/${movie.MovieName.replace(/\s+/g, "_")}`
                        : "/details/Unnamed"
                    }
                  >
                    <img
                      src={movie.MoviePoster}
                      alt={movie.MovieName || "Movie Poster"}
                      className="suggestedMovie"
                      style={{
                        maxWidth: "100%",
                        borderRadius: "8px",
                        border: "2px solid #FFDA78",
                      }}
                    />
                  </Link>
                  <Typography variant="subtitle1" sx={{ mt: 1, color: "#FFF" }}>
                    {movie.MovieName || "Untitled"}
                  </Typography>
                </Box>
              </Grid>
            ))
          )}
        </Grid>
 
        {/* Footer Section */}
        <Footer />
      </Grid>
    </>
  );
};
 
export default SuggestedOrSearchMovies;