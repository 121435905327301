import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

const Loader = ({ message }) => {
  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor="rgba(0, 0, 0, 0.5)"
      zIndex={1300}
    >
      <Box textAlign="center" color="#fff">
        <CircularProgress color="warning" />
        {message && (
          <Typography
            mt={2}
            fontFamily="Quicksand"
            fontWeight={1000}
            fontSize={18}
            color="#FFDA78"
          >
            {message}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default Loader;
