// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
// import endpoints from "../../../Endpoints/endpoints";
// import statusCodes from "../../../utils/statusCodes";

// const initialWatchTime = {
//     userId: null,
//     watchTime: null,
//     isMoviePresent: false,
//     getWatchTimeFetchStatus: statusCodes.IDLE,    
// }

// const getWatchTimeReducer = createSlice({
//     name: 'getWatchTimeReducer',
//     initialState: initialWatchTime,
//     extraReducers : (builder) => {
//         builder
//         .addCase(fetchWatchTimeByUserId.pending, (state, action) => {
//             state.getWatchTimeFetchStatus = statusCodes.LOADING;
//         })
//         .addCase(fetchWatchTimeByUserId.fulfilled, (state, action) => {            
//             const movieFound = action.payload.data['WatchRecords'].find(movie => movie.MovieId === action.meta.arg.fetchedMovieId);
//             // console.log('watch h action: ', action)
//             // console.log('watch h result', movieFound)
//             if (movieFound) {
//                 const watchTime = movieFound.WatchTime
//                 state.watchTime = watchTime
//                 state.isMoviePresent = true
//             }
//             else {
//                 state.watchTime = null
//                 state.isMoviePresent = false                
//             }
//             state.getWatchTimeFetchStatus = statusCodes.SUCCESS;    
//             state.userId = action.payload.data['UserId']
//         })
//         .addCase(fetchWatchTimeByUserId.rejected, (state, action) => {
            
//             state.getWatchTimeFetchStatus = statusCodes.ERROR;
//         })
//     }
// })

// export const fetchWatchTimeByUserId = createAsyncThunk(
//     "getWatchTimeReducer/get",
//     async (fetchWatchDataJSON) => {
//         try {
//             const watchTimeByUserId = await axios.get(`${endpoints.getWatchTime}?userid=${fetchWatchDataJSON.userId}`);
//             return watchTimeByUserId
//         }
//         catch (error) {
//             const errorPayload = {
//                 message: error.response.data.message,
//             };
//             throw errorPayload;
//         }
//     }
// )

// export default getWatchTimeReducer.reducer;


// /////////////////////// updated code //////////////////

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import endpoints from "../../../Endpoints/endpoints";
import statusCodes from "../../../utils/statusCodes";

const initialState = {
  userId: null,
  watchTime: null,
  isMoviePresent: false,
  fetchStatus: statusCodes.IDLE,
};

export const fetchWatchTimeByUserId = createAsyncThunk(
  "watchTime/fetchByUserId",
  async (fetchWatchDataJSON, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${endpoints.getWatchTime}?userid=${fetchWatchDataJSON.userId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        message: error.response?.data?.message || "Failed to fetch watch time",
      });
    }
  }
);

const watchTimeSlice = createSlice({
  name: "watchTime",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchWatchTimeByUserId.pending, (state) => {
        state.fetchStatus = statusCodes.LOADING;
      })
      .addCase(fetchWatchTimeByUserId.fulfilled, (state, action) => {
        const movieFound = action.payload?.WatchRecords?.find(
          (movie) => movie.MovieId === action.meta.arg.fetchedMovieId
        );

        state.watchTime = movieFound ? movieFound.WatchTime : null;
        state.isMoviePresent = Boolean(movieFound);
        state.userId = action.payload?.UserId || null;
        state.fetchStatus = statusCodes.SUCCESS;
      })
      .addCase(fetchWatchTimeByUserId.rejected, (state, action) => {
        state.fetchStatus = statusCodes.ERROR;
        state.error = action.payload?.message || "An error occurred";
      });
  },
});

export default watchTimeSlice.reducer;
