import React, { useEffect } from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import { fetchByCategoryDashboardBanners } from "../../Redux/reducers/bannersByCategoryReducer";
import statusCodes from "../../utils/statusCodes";
import { fetchallVideos } from "../../Redux/reducers/allVideosReducer";
import {
  clearAllLanguages,
  fetchVideoByLanguage,
} from "../../Redux/reducers/videos/getVideoByLanguageReducer";
import {
  clearAllGeners,
  fetchByGenereDashboardBanners,
} from "../../Redux/reducers/bannersByGenereReducer";
import { setMovieState } from "../../Redux/reducers/videos/getVideoByIdReducer";
 
const MoviesByGenerAndLanguage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { gener } = useParams();
  const location = useLocation();
 
  const allBanners = useSelector((state) => {
    switch (gener) {
      case "Comedy":
        return state.rootReducer.bannersByGenereReducer.comedyBanners;
      case "Romance":
        return state.rootReducer.bannersByGenereReducer.romanceBanners;
      case "Action":
        return state.rootReducer.bannersByGenereReducer.actionBanners;
      case "Drama":
        return state.rootReducer.bannersByGenereReducer.dramaBanners;
      case "Devotional":
        return state.rootReducer.bannersByGenereReducer.devotionalBanners;
      case "Sci-Fi":
        return state.rootReducer.bannersByGenereReducer.sciFiBanners;
      case "Adventure":
        return state.rootReducer.bannersByGenereReducer.adventureBanners;
      case "Horror":
        return state.rootReducer.bannersByGenereReducer.horrorBanners;
      case "Thriller":
        return state.rootReducer.bannersByGenereReducer.thrillerBanners;
      case "Sports":
        return state.rootReducer.bannersByGenereReducer.sportsBanners;
      case "Family":
        return state.rootReducer.bannersByGenereReducer.familyBanners;
      case "Webseries":
        return state.rootReducer.bannersByCategoryReducer.webSeriesBanners;
      case "Anime":
        return state.rootReducer.bannersByCategoryReducer.animeBanners;
      default:
        return [];
    }
  });

    const banners = allBanners.filter((banner, index, self) => index === self.findIndex(b => b.MovieName === banner.MovieName) );
 
  const bannersStatus = useSelector((state) => {
    switch (gener) {
      case "Comedy":
        return state.rootReducer.bannersByGenereReducer.comedyBannerFetchStatus;
      case "Romance":
        return state.rootReducer.bannersByGenereReducer
          .romanceBannerFetchStatus;
      case "Action":
        return state.rootReducer.bannersByGenereReducer.actionBannerFetchStatus;
      case "Drama":
        return state.rootReducer.bannersByGenereReducer.dramaBannerFetchStatus;
      case "Devotional":
        return state.rootReducer.bannersByGenereReducer
          .devotionalBannerFetchStatus;
      case "Sci-Fi":
        return state.rootReducer.bannersByGenereReducer.sciFiBannerFetchStatus;
      case "Adventure":
        return state.rootReducer.bannersByGenereReducer
          .adventureBannerFetchStatus;
      case "Horror":
        return state.rootReducer.bannersByGenereReducer.horrorBannerFetchStatus;
      case "Thriller":
        return state.rootReducer.bannersByGenereReducer
          .thrillerBannerFetchStatus;
      case "Sports":
        return state.rootReducer.bannersByGenereReducer.sportsBannerFetchStatus;
      case "Family":
        return state.rootReducer.bannersByGenereReducer.familyBannerFetchStatus;
      case "Webseries":
        return state.rootReducer.bannersByCategoryReducer
          .webSeriesBannerFetchStatus;
      case "Anime":
        return state.rootReducer.bannersByCategoryReducer
          .animeBannerFetchStatus;
      default:
        return statusCodes.IDLE;
    }
  });
 
  const { videoByLanguage, videoByLanguageFetchStatus } = useSelector(
    (state) => state.rootReducer.getVideoByLanguageReducer
  );
  const { allVideos, allVideosFetchStatus } = useSelector(
    (state) => state.rootReducer.allVideosReducer
  );

  const filteredAllVideos = allVideos.filter((banner, index, self) => index === self.findIndex(b => b.MovieName === banner.MovieName) );

 
  useEffect(() => {
    // dispatch(clearAllGeners());
    // dispatch(clearAllLanguages());
    
    dispatch(fetchByGenereDashboardBanners(gener));
    dispatch(fetchVideoByLanguage(gener));
 dispatch(fetchallVideos());
dispatch(fetchByCategoryDashboardBanners(gener));
 
    // if (bannersStatus === statusCodes.IDLE) {
    //   dispatch(fetchByGenereDashboardBanners(gener));
    // }
    // if (videoByLanguageFetchStatus === statusCodes.IDLE) {
    //   dispatch(fetchVideoByLanguage(gener));
    // }
    // if (allVideosFetchStatus === statusCodes.IDLE) {
    //   dispatch(fetchallVideos());
    //   dispatch(fetchByCategoryDashboardBanners(gener));
    // }
    // console.log("bannersStatus",bannersStatus);
  }, [gener]);
 
  const onClickMvWatchBtn = (each) => {
    // const formattedMovieName = each?.MovieName.replace(/\s+/g, "_");
// console.log(each)
    dispatch(setMovieState(each));
    // navigate(`/details/${formattedMovieName}`);
  };
 
  const isLoading =
    bannersStatus === statusCodes.LOADING ||
    videoByLanguageFetchStatus === statusCodes.LOADING ||
    allVideosFetchStatus === statusCodes.LOADING;

    // console.log(isLoading);
    // console.log(bannersStatus === statusCodes.LOADING);
    // console.log(videoByLanguageFetchStatus);
    // console.log(allVideosFetchStatus);

    // console.log("bannerslength", banners[0]);

  return (
    <>
      <Header />
      <Grid
        container
        bgcolor={"#000"}
        display={"flex"}
        flexDirection={"column"}
        gap={1}
        minHeight={"100%"}
      >
        <Grid p={4}>
          <Typography
            fontFamily={"Quicksand"}
            fontSize={30}
            color={"#FFDA78"}
            fontWeight={600}
          >
            {gener}
          </Typography>
        </Grid>
        {isLoading ? (
          <Grid container justifyContent={"center"} alignItems={"center"}>
            <CircularProgress color="warning" />
          </Grid>
        ) : banners.length > 0 ||
          videoByLanguage.length > 0 ||
          allVideos.length > 0 ? (
          <>
            {location.state === "generes" && (
              <Grid
                container
                bgcolor={"#000"}
                xs={12}
                p={2}
                alignItems={"center"}
              >
                {/* {console.log(banners[0])} */}
                 {
                 isLoading ? (
                  <Grid container justifyContent={"center"} alignItems={"center"}>
                    <CircularProgress color="warning" />
                  </Grid>
                ) : 
                 banners[0]?.Id !== undefined  ? (
                  banners?.map((item) => (
                    <Grid item xs={6} sm={4} md={2} key={item.MovieName} p={1}>
                      <Box
                        sx={{ textAlign: "center", cursor: "pointer" }}
                        onClick={() => onClickMvWatchBtn(item)}
                      >
                      <Link to={`/details/${item?.MovieName.replace(/\s+/g, "_")}`}>

                        <img
                          src={item.MoviePoster}
                          alt={item.MovieName}
                          loading="lazy"
                          style={{
                            maxWidth: "100%",
                            border: "2px solid #FFDA78",
                            borderRadius: "8px",
                          }}
                        />
                        </Link>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            mt: 1,
                            color: "#FFF",
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "2em",
                          }}
                        >
                          {item.MovieName}
                        </Typography>
                      </Box>
                    </Grid>
                  ))
                ) :
                (
                  <Grid
                    container
                    bgcolor={"#000"}
                    xs={12}
                    p={2}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Typography
                      fontFamily={"Quicksand"}
                      fontSize={30}
                      fontWeight={600}
                      color={"#FFF"}
                    >
                      {/* <Grid container justifyContent={"center"} alignItems={"center"}>
                       <CircularProgress color="warning" />
                      </Grid> */}
                      No {gener} videos yet, visit again..!
                    </Typography>
                  </Grid>
                )}
              </Grid>
            )}
            {location.state === "Movies" && (
              <>
                {allVideosFetchStatus === statusCodes.LOADING && (
                  <Grid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <CircularProgress color="warning" />
                  </Grid>
                )}
                {allVideosFetchStatus === statusCodes.SUCCESS && (
                  <Grid
                    container
                    bgcolor={"#000"}
                    xs={12}
                    p={2}
                    alignItems={"center"}
                  >
                    {filteredAllVideos.map((item) => (
                      <Grid
                        item
                        xs={6}
                        sm={4}
                        md={2}
                        key={item.MovieName}
                        p={1}
                      >
                        <Box
                          sx={{ textAlign: "center", cursor: "pointer" }}
                          onClick={() => onClickMvWatchBtn(item)}
                        >
                          <Link to={`/details/${item?.MovieName.replace(/\s+/g, "_")}`}>
                          <img
                            src={item.MoviePoster}
                            alt={item.MovieName}
                            loading="lazy"
                            style={{
                              maxWidth: "100%",
                              border: "2px solid #FFDA78",
                              borderRadius: "8px",
                            }}
                          />
                          </Link>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              mt: 1,
                              color: "#FFF",
                              maxHeight: 10,
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxHeight: "2em",
                            }}
                          >
                            {item.MovieName}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                )}
                {allVideosFetchStatus === statusCodes.ERROR && (
                  <Grid
                    container
                    bgcolor={"#000"}
                    xs={12}
                    p={2}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Typography
                      fontFamily={"Quicksand"}
                      fontSize={30}
                      fontWeight={600}
                      color={"#FFF"}
                    >
                      No Movies found!.
                    </Typography>
                  </Grid>
                )}
              </>
            )}
            {location.state === "languages" && (
              <Grid
                container
                bgcolor={"#000"}
                xs={12}
                p={2}
                alignItems={"center"}
              >
                {videoByLanguage[0]?.Id !== undefined ? (
                  videoByLanguage.map((item) => (
                    <Grid item xs={6} sm={4} md={2} key={item.MovieName} p={1}>
                      <Box
                        sx={{ textAlign: "center", cursor: "pointer" }}
                        onClick={() => onClickMvWatchBtn(item)}
                      >
                        <Link to={`/details/${item?.MovieName.replace(/\s+/g, "_")}`}>
                        <img
                          src={item.MoviePoster}
                          alt={item.MovieName}
                          loading="lazy"
                          style={{
                            maxWidth: "100%",
                            border: "2px solid #FFDA78",
                            borderRadius: "8px",
                          }}
                          />
                          </Link>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            mt: 1,
                            color: "#FFF",
                            maxHeight: 10,
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "2em",
                          }}
                        >
                          {item.MovieName}
                        </Typography>
                      </Box>
                    </Grid>
                  ))
                ) : (
                  <Grid
                    container
                    bgcolor={"#000"}
                    xs={12}
                    p={2}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Typography
                      fontFamily={"Quicksand"}
                      fontSize={30}
                      fontWeight={600}
                      color={"#FFF"}
                    >
                      No {gener} videos yet, visit again..!
                    </Typography>
                  </Grid>
                )}
              </Grid>
            )}
            {(location.state === "Webseries" || location.state === "Anime") && (
              <Grid
                container
                bgcolor={"#000"}
                xs={12}
                p={2}
                alignItems={"center"}
              >
                {banners.map((item) => (
                  <Grid item xs={6} sm={4} md={2} key={item.MovieName} p={1}>
                    <Box
                      sx={{ textAlign: "center", cursor: "pointer" }}
                      onClick={() => onClickMvWatchBtn(item)}
                    >
                      <Link to={`/details/${item?.MovieName.replace(/\s+/g, "_")}`}>
                      <img
                        src={item.MoviePoster}
                        alt={item.MovieName}
                        loading="lazy"
                        style={{
                          maxWidth: "100%",
                          border: "2px solid #FFDA78",
                          borderRadius: "8px",
                        }}
                        />
                        </Link>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          mt: 1,
                          color: "#FFF",
                          maxHeight: 10,
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxHeight: "2em",
                        }}
                      >
                        {item.MovieName}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}
          </>
        ) : (
          <Grid
            container
            bgcolor={"#000"}
            xs={12}
            p={2}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              fontFamily={"Quicksand"}
              fontSize={{ xs: 18, sm: 22, md: 30 }}
              fontWeight={600}
              color={"#FFF"}
            >
              {/* No {gener} videos yet.. visit again!. */}
            </Typography>
          </Grid>
        )}
        <Footer />
      </Grid>
    </>
  );
};
 
export default MoviesByGenerAndLanguage;
 
 