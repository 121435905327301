import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../utils/statusCodes";
import endpoints from "../../Endpoints/endpoints"; 

const privacyPoilcyApi = `${endpoints.privacyPoilcy}?title=Privacy_policy`

const initial_privacy_policy = { privacyPolicy: {}, privacyPolicyFetchStatus: statusCodes.IDLE };

const privacyPolicyReducer = createSlice({
  name: "privacyPolicyReducer",
  initialState: initial_privacy_policy,
  extraReducers: (builder) => {
    builder
      .addCase(fetchPrivacyPolicy.pending, (state, action) => {
        state.privacyPolicyFetchStatus = statusCodes.LOADING;
        // console.log("Privacy Policy data pending");
      })
      .addCase(fetchPrivacyPolicy.fulfilled, (state, action) => {
        state.privacyPolicy = action.payload;
        state.privacyPolicyFetchStatus = statusCodes.SUCCESS;
        // console.log(state.privacyPolicy);
      })
      .addCase(fetchPrivacyPolicy.rejected, (state, action) => {
        state.privacyPolicyFetchStatus = statusCodes.ERROR;
        // console.log("Privacy Policy data error");
      });
  },
});


export const fetchPrivacyPolicy = createAsyncThunk("PrivacyPolicy/get", async () => {

 
  try {
    const privacyPolicyData = await axios.get(privacyPoilcyApi);
    return privacyPolicyData.data;
  } catch (error) {
    const errorPayload = {
      message: error.response.data.message,
    };
    throw errorPayload;
  }


});

export default privacyPolicyReducer.reducer;
