import { createSlice } from "@reduxjs/toolkit";

const movieHoverReducer = createSlice({
    name: 'movieHoverReducer',
    initialState: null,
    reducers: {
        setHoveredState(state, action) {            
            return action.payload
        }
    }
})

export const {setHoveredState} = movieHoverReducer.actions;
export default movieHoverReducer.reducer;