import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../../utils/statusCodes";
import endpoints from "../../../Endpoints/endpoints";
 
let initial_video_by_id = {
  movieState: {}, //sesha added
  videoById: [],
  videoByIdFetchStatus: statusCodes.IDLE,
};
 
// const getVideoByIdReducer = createSlice({
//   name: "getVideoByIdReducer",
//   initialState: initial_video_by_id,
//   reducers: {
//     setMovieState(state, action) {
//       console.log("checking arj", action.payload);
//       state.movieState = action.payload;
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(fetchVideoById.pending, (state) => {
//         state.videoByIdFetchStatus = statusCodes.LOADING;
//         // console.log("getvideo by id data pending");
//       })
//       .addCase(fetchVideoById.fulfilled, (state, action) => {
//         state.videoById = action.payload.data;
//         console.log(state.videoById);
//         state.videoByIdFetchStatus = statusCodes.SUCCESS;
//         localStorage.setItem("videoById", JSON.stringify(state.videoById));
//         // console.log(state.videoById);
//       })
//       // .addCase(fetchVideoById.rejected, (state, action) => {
//       //   state.videoByIdFetchStatus = statusCodes.ERROR;
//       //   // console.log("getvideo by id data error");
//       // });
 
//       .addCase(fetchVideoById.rejected, (state, action) => {
//         state.videoByIdFetchStatus = statusCodes.ERROR;
//         console.error("Error fetching video by ID:", action.error.message); // Log or handle the error here
//       });
//   },
// });



 
// export const fetchVideoById = createAsyncThunk(
//   "getVideoById/get",
//   async (fetchVideoByIdJSON) => {
//     console.log("categoryValue:", fetchVideoByIdJSON);
//     console.log("id:", fetchVideoByIdJSON);
 
//     try {
//       let videoById;
//       if (
//         fetchVideoByIdJSON.categoryValue === "WebSeries" ||
//         fetchVideoByIdJSON.categoryValue === "Anime"
//       ) {
//         console.log('getWebSeriresById is calling')
//         videoById = await axios.get(
//           `${endpoints.getWebSeriresById}?id=${fetchVideoByIdJSON.fetchedMovieId}`
//         );
//       } else {
//         console.log('getVedioByid is calling')
//         videoById = await axios.get(
//           `${endpoints.getVideoByid}?id=${fetchVideoByIdJSON.fetchedMovieId}`
//         );
//       }
//       return videoById;
//     } catch (error) {
//       const errorPayload = {
//         message: error.response.data.message,
//       };
//       throw errorPayload;
//     }
//   }
// );
 
// export const fetchVideoById = createAsyncThunk(
//   "getVideoById/get",
//   async (fetchVideoByIdJSON) => {
//     console.log("categoryValue:", fetchVideoByIdJSON);
//     console.log("id:", fetchVideoByIdJSON);
   
 
//     if (
//       fetchVideoByIdJSON.categoryValue == "WebSeries" ||
//       fetchVideoByIdJSON.categoryValue == "Anime"
//     ) {
//       try {
 
//         console.log('getWebSeriresById is calling')
//         const videoById = await axios.get(
//           `${endpoints.getWebSeriresById}?id=${fetchVideoByIdJSON.fetchedMovieId}`
//         );
//         // console.log(videoById);
//         return videoById;
//       } catch (error) {
//         const errorPayload = {
//           message: error.response.data.message,
//         };
//         throw errorPayload;
//       }
//     }
//     else {
//       if (
//         fetchVideoByIdJSON.categoryValue !== "WebSeries" ||
//         fetchVideoByIdJSON.categoryValue !== "Anime"
//       ) {
//       try {
//         console.log('getVedioByid is calling')
//         const videoById = await axios.get(
//           `${endpoints.getVideoByid}?id=${fetchVideoByIdJSON.fetchedMovieId}`
//         );
//         console.log(videoById);
//         return videoById;
//       } catch (error) {
//         const errorPayload = {
//           message: error.response.data.message,
//         };
//         throw errorPayload;
//       }
//     }
//     }
//   }
// );
 

// updated code on(15.11.2024)


// const getVideoByIdReducer = createSlice({
//   name: "getVideoByIdReducer",
//   initialState: initial_video_by_id,
//   reducers: {
//     setMovieState(state, action) {
//       console.log("setMovieState payload:", action.payload);
//       state.movieState = action.payload;
//     },
//     updateWatchedDuration(state, action) {
//       const { videoId, playedSeconds } = action.payload;
//       const video = state.videoById.find((video) => video.Id === videoId);
//       if (video) {
//         video.TotalWatchedDuration = playedSeconds;
//       }
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(fetchVideoById.pending, (state) => {
//         state.videoByIdFetchStatus = statusCodes.LOADING;
//       })
//       .addCase(fetchVideoById.fulfilled, (state, action) => {
//         state.videoById = action.payload; 
//         state.videoByIdFetchStatus = statusCodes.SUCCESS;

//         const videoData = action.payload;

//         const updatedVideoData = {
//           ...videoData,
//           TotalWatchedDuration: video.TotalWatchedDuration || "0 secs",
//         };
//         state.videoById = updatedVideoData;

//         localStorage.setItem("videoById", JSON.stringify(state.videoById));
//         localStorage.setItem("videoById", JSON.stringify(state.videoById)); 
        
        
//         console.log("Fetched and updated video data:", state.videoById);
//         console.log("setMovieState payload:", action.payload);
//         console.log("Fetched video data:", state.videoById);

//       })
//       .addCase(fetchVideoById.rejected, (state, action) => {
//         state.videoByIdFetchStatus = statusCodes.ERROR;
//         console.error("Error fetching video by ID:", action.error.message);
//       });
//   },
// });


// export const fetchVideoById = createAsyncThunk(
//   "getVideoById/get",
//   async (fetchVideoByIdJSON) => {
//     console.log("categoryValue:", fetchVideoByIdJSON.categoryValue);
//     console.log("id:", fetchVideoByIdJSON.fetchedMovieId);

//     const { fetchedMovieId, categoryValue } = fetchVideoByIdJSON;

//     const endpoint =
//       categoryValue === "WebSeries" || categoryValue === "Anime"
//         ? endpoints.getWebSeriresById
//         : endpoints.getVideoByid;

//     const response = await axios.get(`${endpoint}?id=${fetchedMovieId}`);
//     return response.data; // Return only serializable `data`
//   }
// ); 


const getVideoByIdReducer = createSlice({
  name: "getVideoByIdReducer",
  initialState: initial_video_by_id,
  reducers: {
    setMovieState(state, action) {
      // console.log("setMovieState payload:", action.payload);
      state.movieState = action.payload;
    },
    updateWatchedDuration(state, action) {
      const { videoId, playedSeconds } = action.payload;
      const video = state.videoById.find((video) => video.Id === videoId);
      if (video) {
        video.TotalWatchedDuration = playedSeconds;
      }
    },
    clearState(state) {
      state.videoById = [];
      state.videoByIdFetchStatus = statusCodes.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVideoById.pending, (state) => {
        state.videoByIdFetchStatus = statusCodes.LOADING;
      })
      .addCase(fetchVideoById.fulfilled, (state, action) => {
        state.videoById = action.payload;
        state.videoByIdFetchStatus = statusCodes.SUCCESS;

        const videoData = action.payload;

        // Ensure we handle videoData as an array and map through it
        const updatedVideoData = videoData.map((video) => ({
          ...video,
          TotalWatchedDuration: video.TotalWatchedDuration || "0 secs",
        }));

        state.videoById = updatedVideoData;

        // Save the updated data to localStorage (removed duplicate call)
        localStorage.setItem("videoById", JSON.stringify(updatedVideoData));

        // console.log("Fetched and updated video data:", updatedVideoData);
      })
      .addCase(fetchVideoById.rejected, (state, action) => {
        state.videoByIdFetchStatus = statusCodes.ERROR;
        console.error("Error fetching video by ID:", action.error.message || action.error);
      });
  },
});

export const fetchVideoById = createAsyncThunk(
  "getVideoById/get",
  async (fetchVideoByIdJSON) => {
    
    const { fetchedMovieId, categoryValue } = fetchVideoByIdJSON;

    const endpoint =
      categoryValue === "WebSeries" || categoryValue === "Anime"
        ? endpoints.getWebSeriresById
        : endpoints.getVideoByid;

    const response = await axios.get(`${endpoint}?id=${fetchedMovieId}`);
    return response.data; // Return only serializable `data`
  }
);


export const { setMovieState,updateWatchedDuration, clearState } = getVideoByIdReducer.actions;
export default getVideoByIdReducer.reducer;
 
 