import React, { useState, useEffect } from 'react';
import { Box, Typography, Container } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import {Link} from 'react-router-dom'
import Grid from "@mui/material/Grid";
// import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper/modules';
import { Button } from "@mui/material";
import "./index.css"
import { useDispatch,useSelector } from "react-redux";
import {fetchLatestUpdatedMovies} from '../../Redux/reducers/bannersByLatestMovies'





import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const LatestMovies = (props) => {
  const { category, title } = props;
   const [activeIndex, setActiveIndex] = useState(0); // State for active index
  const dispatch = useDispatch();

const { banners, isLoading, error } = useSelector(state => state.rootReducer.fetchLatestUpdatedMovies);

  useEffect(() => {
    dispatch(fetchLatestUpdatedMovies());
  }, []);

  // console.log("sliderInfo", banners);


// useEffect(() => {
//   dispatch(fetchBannersByMovie()); // Dispatch the async action to fetch latest movies
// }, [dispatch]);

  // useEffect(() => {
  //   const fetchBanners = async () => {
  //     try {
  //       const response = await fetch('https://api.iott.co.in/api/videos/getdashboardbanners');
  //       const data = await response.json();
  //       console.log('Fetched banners:', data); // Log the fetched data to the console

  //       if (data && Array.isArray(data) && data.length > 0) {
  //         setBanners(data);
  //       } else {
  //         console.log('No banners found');
  //         setBanners([]);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching banners:', error);
  //       setBanners([]);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   fetchBanners();
  // }, []);
  


  return (
    <Grid  sx={{ py: 4 }} style={{ marginLeft:"25px", alignItems:"center", justifyContent:"center", width:"auto", }}>
      <Typography
        fontFamily={"Quicksand"}
        color={"#FFDA78"}
        // fontWeight={700}
        gutterBottom
        sx={{ fontWeight: 'bold', mb: 4 }}
        fontSize={{ xs: 10, sm: 20 }}
      >
        {title}
      </Typography>

      <Grid sx={{ boxShadow: 1, borderRadius: 3, overflow: 'hidden', p:1 }} style={{justifyContent:"center", alignItems:"center"}} > {/* Slightly increased padding and border radius */}
        {isLoading ? (
          <Typography align="center"  sx={{ mt: 4 }}>
            Loading...
          </Typography>
        ) : banners.length > 0 ? (
          <Swiper
            effect="coverflow"
            grabCursor
            centeredSlides
            loop
            slidesPerView="auto"
            autoplay={{
              delay: 3000, // 3 seconds
              disableOnInteraction: false,
            }}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
            }}
            // pagination={{ clickable: true }}
            navigation
            modules={[EffectCoverflow, Pagination, Navigation,Autoplay]}
            onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
            // className="swiper_container"
          >
            {/* {console.log('banners are: '+ JSON.stringify(banners))} */}
            {banners.map((banner, index) => (
              <SwiperSlide key={index} style={{ width: '279.9px' }} >
                <Box
                  sx={{
                    position: 'relative',
                    textAlign: 'center',
                    filter: index === activeIndex ? 'none' : 'blur(5px)', // Blur non-active slides
                  }}
                >
                  {index === activeIndex ? (
                 <Link to={`/details/${banner?.Name.replace(/\s+/g, "_")}`}> 
                  <Box 
                    component="img"
                    src={banner.URL}
                    alt={banner.Name || `Banner ${index + 1}`}
                    sx={{
                      width: '100%',
                      height: '240px',  // Slightly increased height for images
                      borderRadius: '1.5rem',// Slightly increased border radius for images
                      // objectFit: 'cover', // Ensuring image fills its container
                    }}
                  />
                  </Link>
                  ) : (
                    <Box
                      component="img"
                      src={banner.URL}
                      alt={banner.Name || `Banner ${index + 1}`}
                      sx={{
                        width: '100%',
                        height: '240px',
                        borderRadius: '1.5rem',
                      }}
                    />
                  )}
                  
                  

                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <Typography align="center" sx={{ mt: 4 }}>
            No banners available
          </Typography>
        )}
      
        
      </Grid>
    </Grid>



    
  );
};

export default LatestMovies;

