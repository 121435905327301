import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../utils/statusCodes"; 
import endpoints from "../../Endpoints/endpoints"; 

const helpUserApi = endpoints.helpUser

const initial_helpUser = {
  helpUserData: [],
  helpUserFetchStatus: statusCodes.IDLE,
  
};

export const fetchHelpUser = createAsyncThunk(
  "helpUser/post",
  async (addFavoriteJson) => {
    try {
      const helpUser = await axios.post(
        helpUserApi,
        addFavoriteJson
      );
      return helpUser.data;
    } catch (error) {
      const errorPayload = {
        message: error.response.data,
      };
      // console.log(error.response.data);
      throw errorPayload; // Throwing the error payload
    }
  }
);

const helpUserReducer = createSlice({
  name: "helpUserReducer",
  initialState: initial_helpUser,
  extraReducers: (builder) => {
    builder
      .addCase(fetchHelpUser.pending, (state, action) => {
        state.helpUserFetchStatus = statusCodes.LOADING;
       })
      .addCase(fetchHelpUser.fulfilled, (state, action) => {
        state.helpUserData = action.payload;
        state.helpUserFetchStatus = statusCodes.SUCCESS;
       })
      .addCase(fetchHelpUser.rejected, (state, action) => {
         state.helpUserFetchStatus = statusCodes.ERROR;
       });
  },
});

export default helpUserReducer.reducer;
