import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useEffect } from "react";
import LandingPage from "./Pages/LandingPage";
import Register from "./Pages/AuthPages/Register";
import Login from "./Pages/AuthPages/Login";
import ForgotPassword from "./Pages/AuthPages/ForgotPassword"; 
import { Provider } from "react-redux";
import { store } from "./Redux/store";
import Wallet from "./Pages/Wallet";
import DetailedPage from "./Pages/DetailedPage";
import NotificationPage from "./Pages/NotificationPage";
import WatchHistory from "./Pages/WatchHistory";
import VideoPlayer from "./Pages/MoviePage/VideoPlayer";
import TermsAndConditions from "./Pages/TermsAndConditions";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import SuggestedOrSearchMovies from "./components/SuggestedOrSearchMovies";
import Subscriptions from "./Pages/Subscription";
import ProtectedRoute from "./utils/ProtectedRoute";
// import Profile from "./Pages/Profile";
import AboutUs from "./Pages/aboutUs";
import Feedback from "./Pages/Feedback";
import Help from "./Pages/Help";
import UserCollections from "./Pages/UserCollections";
import MoviesByGenerAndLanguage from "./Pages/MoviesByGenerAndLanguage";
import BlogHome from "./Pages/IottBlog";
import RecentReleases from "./Pages/IottBlog/RecentReleases";
import BlockDetailsPage from "./Pages/IottBlog/BlogDetailPage";
import PageNotFound from "./components/PageNotFound";
import MySubscriptions from "./Pages/Subscription/MySubscriptions";
// import Check from "./Pages/Subscription/Check";
import Profile from "./Pages/Profile";
import Refund from "./Pages/RefundPage";
import ReferAndEarn from "./Pages/ReferAndEarn";
import ResetPassword from "./Pages/AuthPages/ResetPassword";
import RefferalCode from "./Pages/AuthPages/RefferalCode";
// import HowToEarn from "./Pages/HowToEarn";
import HowtoEarn from "./Pages/ReferAndEarn/HowtoEarn";


const UpdateDocumentTitle = () => {
  const location = useLocation();
  useEffect(() => {
    const path = location.pathname;
    let pageTitle = '';


    const lastPathSegment = path.split('/').filter(Boolean).pop();


    if (lastPathSegment) {
      pageTitle = `${lastPathSegment.charAt(0).toUpperCase() + lastPathSegment.slice(1)} - IOTT`;
    } else {
      pageTitle = 'Home - IOTT';
    }
    document.title = pageTitle;
  }, [location]);

  return null;
};

function App() {
  return (
    <div>
      <Provider store={store}>
        <BrowserRouter>
          <UpdateDocumentTitle />
          <Routes>
            <Route exact path="/" element={<LandingPage />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route exact path="/reset-password" element={<ResetPassword />} />
            <Route exact path="/refferal_code" element={<RefferalCode />} />
            <Route exact path="/refund" element={<Refund/>} />

            <Route exact path="/suggestions" element={<SuggestedOrSearchMovies />} />
            <Route exact path="/details/:movieNameParam" element={<DetailedPage />} />
            <Route exact path="/category/:gener" element={<MoviesByGenerAndLanguage />} />
            <Route exact path="/subscriptions" element={<Subscriptions />} />
            <Route element={<ProtectedRoute />}>
              <Route exact path="/wallet" element={<Wallet />} />
              <Route exact path="/profile" element={<Profile />} />
              <Route exact path="/notifications" element={<NotificationPage />} />
              <Route exact path="/my_subscriptions" element={<MySubscriptions />} />
              <Route exact path="/collections" element={<UserCollections />} />
              <Route exact path="/watch_history" element={<WatchHistory />} />
              <Route exact path="/movie-player/:id" element={<VideoPlayer />} />
              <Route exact path="/feedback" element={<Feedback />} />
            </Route>

            <Route exact path="/feedback" element={<Feedback />} />
            <Route exact path="/help" element={<Help />} />
            <Route exact path="/t&c" element={<TermsAndConditions />} />
            <Route exact path="/aboutUs" element={<AboutUs />} />
            <Route exact path="/privacy&policy" element={<PrivacyPolicy />} />
            <Route exact path="/blog/:category" element={<BlogHome />} />
            <Route exact path="/blog/recentreleases" element={<RecentReleases />} />
            <Route exact path="/blog/details" element={<BlockDetailsPage />} />
            <Route path="*" element={<PageNotFound />} />
            <Route exact path="/refer&earn" element={<ReferAndEarn />} />
            <Route exact path="/HowtoEarn" element={<HowtoEarn />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
