import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../../utils/statusCodes";
import endpoints from "../../../Endpoints/endpoints";

const initial_verifyPurchaseMovie = {
  verify_purchase_movie: null,
  verifyPurchaseMovieFetchStatus: statusCodes.IDLE,
};

const verifyPurchaseMovieReducer = createSlice({
  name: "verifyPurchaseMovieReducer",
  initialState: initial_verifyPurchaseMovie,
  extraReducers: (builder) => {
    builder
      .addCase(fetchVerifyPurchaseMovie.pending, (state, action) => {
        state.verify_purchase_movie = 0;
        state.verifyPurchaseMovieFetchStatus = statusCodes.LOADING;
        // console.log("verify purchase  movie Data");
      })
      .addCase(fetchVerifyPurchaseMovie.fulfilled, (state, action) => {
        state.verify_purchase_movie = action.payload;
        state.verifyPurchaseMovieFetchStatus = statusCodes.SUCCESS;
        // console.log(state.verify_purchase_movie);
      })
      .addCase(fetchVerifyPurchaseMovie.rejected, (state, action) => {
        state.verify_purchase_movie = 0;
        state.verifyPurchaseMovieFetchStatus = statusCodes.ERROR;
        // console.log("verify purchase  movie Data error");
      });
  },
});

export const fetchVerifyPurchaseMovie = createAsyncThunk(
  "verifyPurchaseMovie/get",
  async (verifyPurchaseMovieJSON) => {
    try {
      const verifyPurchaseMovie = await axios.get(
        `${endpoints.verifyPurchaseMovie}?userId=${verifyPurchaseMovieJSON.userId}&movieId=${verifyPurchaseMovieJSON.movieId}`
      );
      // console.log(verifyPurchaseMovie);
      return verifyPurchaseMovie.data;
    } catch (error) {
      // console.log(error);
      const errorPayload = {
        message: error.response.data.message,
      };
      throw errorPayload;
    }
  }
);

export default verifyPurchaseMovieReducer.reducer;
