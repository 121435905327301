import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../../utils/statusCodes";
import endpoints from "../../../Endpoints/endpoints";

const initial_TransactionDetails = {
  transaction_details: [],
  transactionDetailsFetchStatus: statusCodes.IDLE,
};

const transactionDetailsReducer = createSlice({
  name: "transactionDetailsReducer",
  initialState: initial_TransactionDetails,
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactionDetails.pending, (state, action) => {
        state.transactionDetailsFetchStatus = statusCodes.LOADING;
        // console.log("transaction Details Data");
      })
      .addCase(fetchTransactionDetails.fulfilled, (state, action) => {
        state.transaction_details = action.payload;
        state.transactionDetailsFetchStatus = statusCodes.SUCCESS;
        // console.log(state.transaction_details);
      })
      .addCase(fetchTransactionDetails.rejected, (state, action) => {
        state.transactionDetailsFetchStatus = statusCodes.ERROR;
        // console.log("transaction Details Data error");
      });
  },
});

export const fetchTransactionDetails = createAsyncThunk(
  "transactionDetails/get",
  async (userId) => {
    try {
      const transactionDetails = await axios.get(
        `${endpoints.getTransactionDetails}?userId=${userId}`
      );
      return transactionDetails.data;
    } catch (error) {
      const errorPayload = {
        message: error.response.data.message,
      };
      throw errorPayload;
    }
  }
);

export default transactionDetailsReducer.reducer;
