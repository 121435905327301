import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  Typography,
  Box,
  Card,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { subscribeUser } from "../../Redux/reducers/subscription/subscribeUserReducer";
import { fetchSubscriptions } from "../../Redux/reducers/mySubscriptionsReducer";
import { getAuthData } from "../../utils/auth";
import { purchaseSubscriptionPlan } from "../../Redux/reducers/subscription/purchaseSubscriptionReducer";
import getWalletAmountReducer, {
  fetchWalletAmount,
} from "../../Redux/reducers/wallet/getWalletAmountReducer";
import { updateWalletAmount } from "../../Redux/reducers/wallet/addWalletAmountReducer";
import { useTransition } from "react";
const MySubscriptions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const authData = getAuthData();

  useEffect(() => {
    if (authData?.auth?.Id) {
      dispatch(fetchSubscriptions(authData.auth.Id));
    }
  }, [authData?.auth?.Id]);

  const mySubscriptionsData = location.state;

  const SubscriptionPlans = (subscriptionMessage) => {
    navigate("/subscriptions", {
      state: { ...mySubscriptionsData, subscriptionMessage },
    });
  };

  const { subscriptions } = useSelector(
    (state) => state.rootReducer.subscriptionsReducer
  );

  const wallet_amount = useSelector(
    (state) => state.rootReducer.getWalletAmountReducer.wallet_amount
  );

  return (
    <Grid sx={{ backgroundColor: "rgba(1, 1, 19, 0.8)" }}>
      <Header subscription />
      <Grid
        container
        minHeight={"70vh"}
        justifyContent={"center"}
        alignItems={"center"}
        mt={1}
      >
        <Typography
          color={"#FFDA78"}
          sx={{ fontSize: { xs: "20px", sm: "25px" } }}
          variant="h4"
          align="center"
          gutterBottom
          fontFamily={"Quicksand"}
          fontWeight={800}
        >
          My Current Plans
        </Typography>
        <Grid ml={5}>
          <Button
            onClick={() => SubscriptionPlans()}
            sx={{ fontWeight: 700, borderRadius: 1 }}
          >
            IOTT PLANS
          </Button>
        </Grid>
        <Grid
          container
          item
          xs={11}
          justifyContent={"center"}
          alignItems={"center"}
          flexWrap={"wrap"}
          gap={2}
        >
          {subscriptions?.map((each, index) => (
            <SubscriptionCard
              key={index}
              subscriptions={each}
              SubscriptionPlans={SubscriptionPlans}
              wallet_amount={wallet_amount}
            />
          ))}
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
};

const SubscriptionCard = ({ subscriptions, wallet_amount }) => {
  const dispatch = useDispatch();
  const authData = getAuthData();
  const bouns_amount = useSelector(
    (state) => state.rootReducer.getBounsAmountReducer.bouns_amount
  );

  const [purchaseStatus, setPurchaseStatus] = useState("");
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [walletAmount, setWalletAmount] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchWalletAmount(authData?.auth?.Id));
    dispatch(fetchSubscriptions(authData?.auth?.Id));
  }, [purchaseStatus]);

  const currentDate = new Date();
  const validityDate = new Date(subscriptions.Validity);

  useEffect(() => {
    setIsDisabled(new Date(subscriptions.Validity) > new Date());
  }, [subscriptions.Validity]);

  const formattedValidityDate = `${validityDate.getFullYear()}-${(
    validityDate.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${validityDate.getDate().toString().padStart(2, "0")}`;

  const timeDiff = validityDate - currentDate;
  const daysLeft = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  const hoursLeft = Math.floor(
    (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutesLeft = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
  const secondsLeft = Math.floor((timeDiff % (1000 * 60)) / 1000);

  const getSubscriptionMessage = () => {
    if (daysLeft > 7) {
      return "YOUR PLAN IS ACTIVE";
    } else if (daysLeft > 0 && daysLeft <= 7) {
      return `YOUR PLAN ENDS IN ${daysLeft} DAYS`;
    } else if (daysLeft === 0 && hoursLeft > 0) {
      return `YOUR PLAN ENDS IN ${hoursLeft} HOURS`;
    } else if (daysLeft === 0 && hoursLeft === 0 && minutesLeft > 0) {
      return `YOUR PLAN ENDS IN ${minutesLeft} MINUTES`;
    } else if (
      daysLeft === 0 &&
      hoursLeft === 0 &&
      minutesLeft === 0 &&
      secondsLeft > 0
    ) {
      return `YOUR PLAN ENDS IN ${secondsLeft} SECONDS`;
    } else {
      return "YOUR PLAN HAS EXPIRED";
    }
  };

  const subscriptionMessage = getSubscriptionMessage();
  const isActive = subscriptionMessage === "YOUR PLAN IS ACTIVE";

  const handleToClose = () => {
    setDialogOpen(false);
  };
  function handleNavigateWallet() {
    navigate("/wallet");
  }

  const handleRepeatPlan = () => {
    setShowConfirmDialog(true);

    // setShowErrorDialog(true);
    // setDialogOpen(true);
  };

  const handleProceed = async () => {
    const { SubscriptionPlan, PurchaseAmount, UserId, SubscriptionId } =
      subscriptions;

    if (!SubscriptionPlan || typeof PurchaseAmount !== "number") {
      setPurchaseStatus("Failed to repeat subscription due to invalid data");
      return;
    }
    if (wallet_amount < PurchaseAmount) {
      setShowErrorDialog(true);
      setPurchaseStatus("Insufficient funds in wallet");
      setDialogOpen(true);
      setWalletAmount(true);
      return;
    }

    const userDetails = {
      userId: UserId,
      planId: SubscriptionId,
      plan: SubscriptionPlan,
      wallet_amount,
      bonus_amount: bouns_amount || 0,
    };
    const purchaseDetails = {
      userId: UserId,
      PurchaseAmount,
      planId: SubscriptionId,
    };

    // console.log(purchaseDetails);
    // console.log(userDetails);

    try {
      await dispatch(subscribeUser(userDetails)).unwrap();
      await dispatch(purchaseSubscriptionPlan(purchaseDetails)).unwrap();
      setPurchaseStatus("Subscription repeated successfully");
      setIsDisabled(true); // Disable after successful purchase
    } catch (error) {
      // console.error("Error repeating subscription:", error);
      setPurchaseStatus("Failed to repeat subscription");
    }
    setShowErrorDialog(false);
    setDialogOpen(false);
  };

  return (
    <>
      <Card
        sx={{
          backgroundColor: "#fff",
          color: "black",
          borderRadius: 2,
          width: { xs: "80%", sm: "40%", md: "25%" },
          height: "auto",
          pb: 2,
          // border: "5px solid rgba(1, 1, 19, 0.8)",
        }}
        className="subscription-card"
      >
        <Box
          borderRadius={1}
          bgcolor={"#FFDA78"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          margin={"auto"}
          height={40}
          flexDirection={"row"}
          mb={3}
          sx={{ width: { xs: "50%" } }}
        >
          <Typography gutterBottom fontFamily={"Quicksand"} fontWeight={700}>
            {subscriptions?.SubscriptionPlan}
          </Typography>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={2}
        >
          <Typography
            variant="body1"
            gutterBottom
            fontFamily={"Quicksand"}
            fontWeight={700}
          >
            Purchased Amount : ₹ {subscriptions?.PurchaseAmount}
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            fontFamily="Quicksand"
            fontWeight={600}
          >
            Language : {subscriptions?.Language}
          </Typography>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={2}
        >
          <Typography
            variant="subtitle2"
            sx={{
              color: isActive ? "green" : "red",
              fontWeight: isActive ? "bold" : "700",
              fontSize: isActive ? "18px" : "16px",
            }}
          >
            {subscriptionMessage}
          </Typography>
        </Box>
        <Box
          pt={1}
          fontSize={"14px"}
          color={isActive ? "#eba434" : "red"}
          textAlign={"center"}
          sx={{ fontWeight: isActive ? "bold" : "500", fontSize: "14px" }}
        >
          <Typography
            fontSize={"14px"}
            color={isActive ? "#eba434" : "red"}
            textAlign={"center"}
            sx={{ fontWeight: isActive ? "bold" : "500", fontSize: "14px" }}
          >
            Your plan Ends on {formattedValidityDate}
          </Typography>
        </Box>
        <Box
          pt={2}
          fontSize={"14px"}
          color={isActive ? "#eba434" : "red"}
          textAlign={"center"}
          sx={{ fontWeight: "500", fontSize: "14px" }}
        >
          <Button
            disabled={isDisabled}
            onClick={() => handleRepeatPlan(subscriptions)}
            sx={{ fontWeight: "600" }}
          >
            {/* <Button disabled={isDisabled} onClick={()=>handleDilogOpen()}> */}
            Repeat Plan
          </Button>
          {/* Confirmation Dialog */}
          <Dialog
            open={showConfirmDialog}
            onClose={() => setShowConfirmDialog(false)}
          >
            <DialogContent>
              <Typography fontWeight={700} variant="h6">
                Do you want to repeat this plan?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setShowConfirmDialog(false)}
                color="secondary"
                sx={{ fontWeight: "700" }}
              >
                No
              </Button>
              <Button
              
                onClick={() => {
                  setShowConfirmDialog(false);
                  setDialogOpen(true);
                  setShowErrorDialog(true);
                }}
                color="primary"
                sx={{ fontWeight: "700" }}
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>

          {showErrorDialog && (
            <Dialog
              open={dialogOpen}
              onClose={handleToClose}
              maxWidth={{ xs: "sm", sm: "md" }}
            >
              <DialogContent>
                <Box p={3}>
                  <Typography
                    fontFamily={"Quicksand"}
                    fontWeight={700}
                    fontSize={23}
                    gutterBottom
                  >
                    {wallet_amount > subscriptions.PurchaseAmount
                      ? "Click on Proceed to Purchace Plan"
                      : "Insufficient Balance in your Wallet!"}
                  </Typography>

                  <Grid
                    container
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "column",
                      backgroundColor: "#FFDA78",
                      padding: "20px 10px",
                      borderRadius: "10px",
                      // justifyContent:'center',
                      // alignItems:'center'
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Quicksand",
                        fontWeight: 600,
                        fontSize: 20,
                        textAlign: "center",
                        padding: "5px ",
                        marginBottom: "16px",
                        backgroundColor: "#ffff",
                        borderRadius: "10px",
                      }}
                    >
                      Wallet Balance: {wallet_amount || 0}
                    </Typography>

                    <Typography
                      sx={{
                        fontFamily: "Quicksand",
                        fontWeight: 600,
                        fontSize: 20,
                        textAlign: "center",
                        padding: "5px",
                        marginBottom: "16px",
                        backgroundColor: "#ffff",
                        borderRadius: "10px",
                      }}
                    >
                      Bonus Balance:{" "}
                      {bouns_amount ? bouns_amount.BonusAmount : 0}
                    </Typography>
                  </Grid>
                  <Grid mt={2}>
                    <Grid sx={{ width: "100%", mt: 0.5, mb: 0.5, display: "flex", justifyContent:"center" }} >
                      {wallet_amount >= subscriptions.PurchaseAmount ? (
                        <Button
                          onClick={handleProceed}
                          variant="contained"
                          color="primary"
                          sx={{
                            fontSize: 15,
                            borderRadius: 2,
                            fontWeight: 700,
                            fontFamily: "Quicksand",
                            width: "70%",
                          }}
                          autoFocus
                        >
                          Proceed
                        </Button>
                      ) : (
                        <Button
                          onClick={handleNavigateWallet}
                          variant="contained"
                          color="primary"
                          sx={{
                            fontSize: 13,
                            borderRadius: 2,
                            fontWeight: 700,
                            fontFamily: "Quicksand",
                            width: "70%",
                          }}
                          autoFocus
                        >
                          + Add Money
                        </Button>
                      )}
                    </Grid>
                    <Grid sx={{ width: "100%", mt: 0.5, mb: 0.5,display: "flex", justifyContent:"center", }}>
                      <Button
                        onClick={handleToClose}
                        variant="outlined"
                        color="secondary"
                        sx={{
                          
                          fontSize: 15,
                          borderRadius: 2,
                          fontWeight: 700,
                          fontFamily: "Quicksand",
                          width: "70%",
                        }}
                        autoFocus
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </DialogContent>
            </Dialog>
          )}
        </Box>
      </Card>
    </>
  );
};

export default MySubscriptions;
