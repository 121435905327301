// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
// import statusCodes from "../../utils/statusCodes";
// import endpoints from "../../Endpoints/endpoints";
// import { useDispatch } from "react-redux";
// import { useEffect } from "react";

// const getByCategoryApi = `${endpoints.getByCategory}?category=`;

// const access_token = localStorage.getItem("token");

// const initial_banners = {
//   // category_banners: [], categoryBannerFetchStatus: statusCodes.IDLE,
//   trendingBanners: [],
//   highRatedMoviesBanners: [],
//   freeMoviesBanners: [],
//   iottPicksForYouBanners: [],
//   mostlyWatchedBanners: [],
//   recommendedMoviesBanners: [],
//   recentlyReleasedBanners: [],
//   webSeriesBanners: [],
//   animeBanners: [],
//   trendingBannerFetchStatus: statusCodes.IDLE,
//   highRatedMoviesBannerFetchStatus: statusCodes.IDLE,
//   freeMoviesBannerFetchStatus: statusCodes.IDLE,
//   iottPicksForYouBannerFetchStatus: statusCodes.IDLE,
//   mostlyWatchedBannerFetchStatus: statusCodes.IDLE,
//   recommendedMoviesBannerFetchStatus: statusCodes.IDLE,
//   recentlyReleasedBannerFetchStatus: statusCodes.IDLE,
//   webSeriesBannerFetchStatus: statusCodes.IDLE,
//   AnimeBannerFetchStatus: statusCodes.IDLE,
// };

// const bannersByCategoryReducer = createSlice({
//   name: "bannersByCategoryReducer",
//   initialState: initial_banners,
//   reducers: {
//     resetState: (state) => {
//       return initial_banners;
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(fetchByCategoryDashboardBanners.pending, (state, action) => {
//         // state.categoryBannerFetchStatus = statusCodes.LOADING;
//         // console.log("dashboard banners by category data pending");
//       })
//       .addCase(fetchByCategoryDashboardBanners.fulfilled, (state, action) => {
//         // // console.log(action.payload.data);
//         // state.category_banners = action.payload.data;
//         // state.categoryBannerFetchStatus = statusCodes.SUCCESS;
//         // // console.log(state.category_banners);
//         const { category, data } = action.payload;

//         // Cache the data in localStorage
//         localStorage.setItem(`banners_${category}`, JSON.stringify(data));



//         // const { category, data } = action.payload;
//         // if (category === "Trending") {
//         //   state.trendingBanners = data;
//         //   state.trendingBannerFetchStatus = statusCodes.SUCCESS;
//         // } else if (category === "HighRatedMovies") {
//         //   state.highRatedMoviesBanners = data;
//         //   state.highRatedMoviesBannerFetchStatus = statusCodes.SUCCESS;
//         // } else if (category === "Freemovies") {
//         //   state.freeMoviesBanners = data;
//         //   state.freeMoviesBannerFetchStatus = statusCodes.SUCCESS;
//         // } else if (category === "Iottpicksforyou") {
//         //   state.iottPicksForYouBanners = data;
//         //   state.iottPicksForYouBannerFetchStatus = statusCodes.SUCCESS;
//         // } else if (category === "MostlyWatched") {
//         //   state.mostlyWatchedBanners = data;
//         //   state.mostlyWatchedBannerFetchStatus = statusCodes.SUCCESS;
//         // } else if (category === "Recommendedmovies") {
//         //   state.recommendedMoviesBanners = data;
//         //   state.recommendedMoviesBannerFetchStatus = statusCodes.SUCCESS;
//         // } else if (category === "Webseries") {
//         //   state.webSeriesBanners = data;
//         //   state.webSeriesBannerFetchStatus = statusCodes.SUCCESS;
//         // } else if (category === "Anime") {
//         //   state.animeBanners = data;
//         //   state.AnimeBannerFetchStatus = statusCodes.SUCCESS;
//         // }

//         switch (category) {
//           case "Trending":
//             state.trendingBanners = data;
//             state.trendingBannerFetchStatus = statusCodes.SUCCESS;
//             break;
//           case "HighRatedMovies":
//             state.highRatedMoviesBanners = data;
//             state.highRatedMoviesBannerFetchStatus = statusCodes.SUCCESS;
//             break;
//           case "Freemovies":
//             state.freeMoviesBanners = data;
//             state.freeMoviesBannerFetchStatus = statusCodes.SUCCESS;
//             break;
//           case "Iottpicksforyou":
//             state.iottPicksForYouBanners = data;
//             state.iottPicksForYouBannerFetchStatus = statusCodes.SUCCESS;
//             break;
//           case "MostlyWatched":
//             state.mostlyWatchedBanners = data;
//             state.mostlyWatchedBannerFetchStatus = statusCodes.SUCCESS;
//             break;
//           case "Recommendedmovies":
//             state.recommendedMoviesBanners = data;
//             state.recommendedMoviesBannerFetchStatus = statusCodes.SUCCESS;
//             break;
//           case "Webseries":
//             state.webSeriesBanners = data;
//             state.webSeriesBannerFetchStatus = statusCodes.SUCCESS;
//             break;
//           case "Anime":
//             state.animeBanners = data;
//             state.AnimeBannerFetchStatus = statusCodes.SUCCESS;
//             break;
//           default:
//             // Handle unknown category if needed
//             break;
//         }
//       })
//       .addCase(fetchByCategoryDashboardBanners.rejected, (state, action) => {
//         // state.categoryBannerFetchStatus = statusCodes.ERROR;
//         // console.log("dashboard banners by category  data error");
//       });
//   },
// });

// export const fetchByCategoryDashboardBanners = createAsyncThunk(
//   "homeDashboardCategoryBanners/get",
//   async (category, { rejectWithValue }) => {
//     // // console.log("https://api.iott.co.in/api/videos/getbycategory?category=Freemovies")
//     //  // console.log(`${getByCategoryApi}category=Freemovies`)
//     // console.log(`${getByCategoryApi}${category}`);

//  // Check if data exists in localStorage 
//     try {
//       const banners = await axios.get(`${getByCategoryApi}${category}`,{
//         headers: {
//           'Authorization': `Bearer ${access_token}`,
//           'Cache-Control': 'no-cache',
//           'Pragma': 'no-cache'
//         }
//       });
//       //
//       //   headers: {
//       //     'Authorization': `Bearer ${access_token}`
//       //   }
//       // }
//       // );

//       return { category, data: banners.data };
//     } catch (error) {
//       // const errorPayload = {
//       //   message: error.response.data.message,
//       // };
//       // throw errorPayload;
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// // Modify this part to refetch data based on current category when the component mounts
// export const useFetchBannersOnMount = (category) => {
//   const dispatch = useDispatch();
//   useEffect(() => {
//     if (category) {
//       dispatch(fetchByCategoryDashboardBanners(category));
//     }
//   }, [category, dispatch]);
// };
// export const { resetState } = bannersByCategoryReducer.actions;
// export default bannersByCategoryReducer.reducer;


////////////////updated code//////////////////
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../utils/statusCodes";
import endpoints from "../../Endpoints/endpoints";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

const getByCategoryApi = `${endpoints.getByCategory}?category=`;

const initialState = {
  trendingBanners: [],
  highRatedMoviesBanners: [],
  freeMoviesBanners: [],
  iottPicksForYouBanners: [],
  mostlyWatchedBanners: [],
  recommendedMoviesBanners: [],
  recentlyReleasedBanners: [],
  webSeriesBanners: [],
  animeBanners: [],
  fetchStatus: {},
  error: null,
};

const fetchHeaders = () => ({
  Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
});

export const fetchByCategoryDashboardBanners = createAsyncThunk(
  "banners/fetchByCategory",
  async (category, { rejectWithValue }) => {
    try {
      const cachedData = localStorage.getItem(`banners_${category}`);
      if (cachedData) {
        return { category, data: JSON.parse(cachedData), cached: true };
      }

      const response = await axios.get(`${getByCategoryApi}${category}`, {
        headers: fetchHeaders(),
      });

      // Cache data in localStorage
      localStorage.setItem(`banners_${category}`, JSON.stringify(response.data));

      return { category, data: response.data, cached: false };
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch banners"
      );
    }
  }
);

const bannersSlice = createSlice({
  name: "banners",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchByCategoryDashboardBanners.pending, (state, action) => {
        state.fetchStatus[action.meta.arg] = statusCodes.LOADING;
      })
      .addCase(fetchByCategoryDashboardBanners.fulfilled, (state, action) => {
        const { category, data, cached } = action.payload;

        switch (category) {
          case "Trending":
            state.trendingBanners = data;
            break;
          case "HighRatedMovies":
            state.highRatedMoviesBanners = data;
            break;
          case "Freemovies":
            state.freeMoviesBanners = data;
            break;
          case "Iottpicksforyou":
            state.iottPicksForYouBanners = data;
            break;
          case "MostlyWatched":
            state.mostlyWatchedBanners = data;
            break;
          case "Recommendedmovies":
            state.recommendedMoviesBanners = data;
            break;
          case "Webseries":
            state.webSeriesBanners = data;
            break;
          case "Anime":
            state.animeBanners = data;
            break;
          default:
            console.warn(`Unknown category: ${category}`);
            break;
        }

        state.fetchStatus[category] = cached
          ? statusCodes.CACHED
          : statusCodes.SUCCESS;
        state.error = null;
      })
      .addCase(fetchByCategoryDashboardBanners.rejected, (state, action) => {
        const category = action.meta.arg;
        state.fetchStatus[category] = statusCodes.ERROR;
        state.error = action.payload || "An error occurred while fetching data";
      });
  },
});

export const { resetState } = bannersSlice.actions;

export const useFetchBannersOnMount = (category) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (category) {
      dispatch(fetchByCategoryDashboardBanners(category));
    }
  }, [category, dispatch]);
};

export default bannersSlice.reducer;
