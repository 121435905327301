import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../../utils/statusCodes";
import endpoints from "../../../Endpoints/endpoints";

const clearSingleCollectionApi = endpoints.clearSingleOrAllCollection;

const initial_clearSingleCollection = {
  clearSingleCollectionData: [],
  ClearSingleCollectionFetchStatus: statusCodes.IDLE,
};

const clearSingleCollectionReducer = createSlice({
  name: "clearSingleCollectionReducer",
  initialState: initial_clearSingleCollection,
  extraReducers: (builder) => {
    builder
      .addCase(fetchClearSingleCollection.pending, (state, action) => {
        state.ClearSingleCollectionFetchStatus = statusCodes.LOADING;
        // console.log("clear single watch history data pending");
      })
      .addCase(fetchClearSingleCollection.fulfilled, (state, action) => {
        state.clearSingleCollectionData = action.payload;
        state.ClearSingleCollectionFetchStatus = statusCodes.SUCCESS;
        // console.log(state.clearSingleWatchHistoryData);
      })
      .addCase(fetchClearSingleCollection.rejected, (state, action) => {
        state.ClearSingleCollectionFetchStatus = statusCodes.ERROR;
        // console.log("clear single watch history  data error");
      });
  },
});

export const fetchClearSingleCollection = createAsyncThunk(
  "fetchClearSingleCollection/delete",
  async (clearSingleCollectionJSON) => {
    try {
      const clearSinglecollection = await axios.delete(
        `${clearSingleCollectionApi}?userId=${clearSingleCollectionJSON.userId}&movieIds=${clearSingleCollectionJSON.movieId}`
      );
      return clearSinglecollection.data;
    } catch (error) {
      const errorPayload = {
        message: error.response.data.message,
      };
      throw errorPayload;
    }
  }
);

export default clearSingleCollectionReducer.reducer;
