let HOSTNAME = '';
let PROTO = '';
let BASE = '';
let MEDIA_BASE = '';

if (typeof window !== 'undefined') {
  const host = window.location.hostname;
  if (host === 'iott.co.in') {
    HOSTNAME = 'api.iott.co.in/api';
    PROTO = 'https';
    BASE = `${PROTO}://${HOSTNAME}`;
    MEDIA_BASE = BASE;
  } else if (host === 'demo.iott.co.in') {
    HOSTNAME = 'api.iott.co.in/api';         //    // 'api.dev.iott.co.in/api'
    PROTO = 'https';
    BASE = `${PROTO}://${HOSTNAME}`;
    MEDIA_BASE = BASE;
  } else {
    //*For hitting local directly*////////////
    HOSTNAME = 'api.iott.co.in/api';
    PROTO = 'https';
    BASE = `${PROTO}://${HOSTNAME}`;
  }
}

const endpoints = {
  //   BASE,
  //   base: `${BASE}`,
    getAllVideos: `${BASE}/videos/getallvideos`,
    getWebSeriresById: `${BASE}/videos/getwebseriesbyid`,
  //   getAllImages: `${BASE}/images/getallimages`,
  getAllLanguages: `${BASE}/language/getalllanguages`,
  getAllCategory: `${BASE}/category/getallcategory`,
  getVideoByid: `${BASE}/videos/GetVideoByid`,
  getByCategory: `${BASE}/videos/getbycategory`,
  privacyPoilcy: `${BASE}/knowiott/getaboutiott`,
  termAndCondition: `${BASE}/knowIOTT/GetTermsAndConditions`,
  aboutUs: `${BASE}/knowiott/getaboutiott`,
  contactUs: `${BASE}/knowiott/getcontactus`,
  addFavorite: `${BASE}/user/addfavorite`,
  getFavoriteById: `${BASE}/user/getfavoritebymovieid`,
  getTrailerById: `${BASE}/videos/gettrailerbyid`,
  getByLanguage: `${BASE}/videos/getbylanguage`,
  //   register: `${BASE}/user/signupuser`,
  Register: `${BASE}/user/Register`,
  login: `${BASE}/user/login`,
  VerifyUser: `${BASE}/user/verifyuser`,
  forced_login: `${BASE}/user/forced_login`,
  ForgotPassword: `${BASE}/user/ForgotPassword`,
  RegisterEmailVerification:`${BASE}/user/RegisterEmailVerification`,
  PasswordSave:`${BASE}/user/PasswordSave`,
   
  clearSingleOrAllCollection: `${BASE}/user/DeleteFavMovies`,
  getByGenere: `${BASE}/videos/getbygenre`,
  getCollection: `${BASE}/user/getfavorite`,
  searchCollection: `${BASE}/videos/getMoviebyName`,
  getAllNotifications: `${BASE}/notification/getallnotifications`,
  feedBackSubmit: `${BASE}/user/feedback`,
  //  uploadProfile: `${BASE}/user/editprofile`,  
  helpUser: `${BASE}/useroperations/userhelp`,
  getBannerImages: `${BASE}/videos/getdashboardbanners`,
  getWatchHistory: `${BASE}/user/getwatchhistory`,
  clearSingleWatchHistory: `${BASE}/user/clearwatchhistory`,
  clearAllWatchHistory: `${BASE}/user/clearallwatchhistory`,
  addWatchHistory: `${BASE}/user/addwatchhistory`,


  getOrderId: `${BASE}/payment/get_orderid`,
  initiateTransaction: `${BASE}/payment/InitiateTransaction`,
  getPurchaseHistory: `${BASE}/payment/get_purchase_history`,
  getWalletAmount: `${BASE}/payment/get_wallet`,
  addWalletAmount: `${BASE}/payment/wallet`,

  subscriptionCheck: `${BASE}/videos/CheckSubscriptionStatus`,
  getTransactionDetails: `${BASE}/payment/get_transactiondetails`,
  getSuggestedMovies: `${BASE}/user/get_suggestedmovies`,
  getBonusAmount: `${BASE}/payment/Get_BonusAmnt`,
  addPurchaseMovie: `${BASE}/payment/purchase_movie`,
  verifyPurchaseMovie: `${BASE}/payment/is_purchase`,
  //   verifyUserSignUp: `${BASE}/user/VerifyUserSignUp`,
  //   verifyUserTest: `${BASE}/user/VerifyUserTest`,
  getUserById:`${BASE}/user/getuserbyid`,//https://api.iott.co.in/api/user/getuserbyid
  logout: `${BASE}/user/logout`,
  //   getTrailer: `${BASE}/videos/gettrailerbyid`,
  getWatchTime: `${BASE}/videos/Get_MovieWatchTimeByUser/`,
  addWatchTime: `${BASE}/videos/Post_MovieWatchTime`,


////////////deve verified url's

//footer     ----->     index.js
  footerGetContact: `${BASE}/KnowIOTT/getContactUs`,
 
  //Header     ----->   index.js
  payment_get_purchase_subscription_history:`${BASE}/payment/get_purchase_subscription_history`,
 
 
  //Register    ----->     index.js
  // referral_get_referralcode: `${BASE}/referral/get_referralcode`,
 
 
  //Feedback     ----->     index.js
  user_feedback:`${BASE}/user/feedback`,
 
  //Help      ----->     index.js
  useroperations_userhelp:`${BASE}/useroperations/userhelp`,
 
  //LandingPage     ----->     index.js
  user_RecordWebsiteView:`${BASE}/user/RecordWebsiteView`, //unique
 
  //NotificationPage     ----->     index.js
  notification_Delete_Notification:`${BASE}/notification/Delete_Notification`, //notification_Delete_Notification
 
  //Profile     ----->     index.js
  user_editprofile:`${BASE}/user/editprofile`,
  useroperations_getfollowingcount:`${BASE}/useroperations/getfollowingcount`, // unique
  useroperations_getfollowercount:`${BASE}/useroperations/getfollowercount`, // unique
  logout: `${BASE}/user/logout`,
 
  //EarnAndRefer     ----->     index.js
  payment_DetailsCheck:`${BASE}/payment/DetailsCheck`, //unique
  videos_CheckSubscriptionStatus:`${BASE}/videos/CheckSubscriptionStatus`, // subscriptionCheck
  Payment_GetTotalBonusAmountByReferredByUserId:`${BASE}/Payment/GetTotalBonusAmountByReferredByUserId`, // unique
  referral_get_referralcode:`${BASE}/referral/get_referralcode`,
  payment_WithdrawBonusAmount:`${BASE}/payment/WithdrawBonusAmount`, // unique
 
  //Subscription     ----->     index.js
  videos_GetSubscriptionPlans:`${BASE}/videos/GetSubscriptionPlans`, //unique
  videos_SubscribeUser:`${BASE}/videos/SubscribeUser`, //unique
  payment_Purchase_Subscription:`${BASE}/payment/Purchase_Subscription`, //unique
  //Wallet     ----->     index.js
  payment_GetUserTransactionDetails:`${BASE}/payment/GetUserTransactionDetails`, //unique
  payment_UpdateUserTransactionDetails:`${BASE}/payment/UpdateUserTransactionDetails`,//unique
  payment_AddUserTransactionDetails:`${BASE}/payment/AddUserTransactionDetails`, //unique
  payment_GetBonusWithDrawHistoryByUserId:`${BASE}/payment/GetBonusWithDrawHistoryByUserId`, //unique
  referral_Get_Rewardhistory:`${BASE}/referral/Get_Rewardhistory`, //unique
  //ReferralCode    --->   index.js
  referral_Is_Valid:`${BASE}/referral/Is_Valid`, //unique
  referral_ReferUser:`${BASE}/referral/ReferUser`, //unique
  //NotificationPage     ----->     index.js
  // notification_Delete_Notification:`${BASE}/notification/Delete_Notification` //notification_Delete_Notification
};




export default endpoints;