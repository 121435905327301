import React, { useEffect, useState } from "react";
import {
  Box,
  Avatar,
  Typography,
  Grid,
  Tabs,
  Tab,
  Card,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  CardActions,
  Button,
  Dialog,
  TextField,
  Snackbar,
  SnackbarContent,
  CircularProgress,
} from "@mui/material";
import { RiPencilFill } from "react-icons/ri";
import Header from "../../components/Header";
import "./index.css";
import axios from "axios";
import { MdDelete } from "react-icons/md";
import endpoints from "../../Endpoints/endpoints";
import appTheme from "../../appTheme";
import { getAuthData } from "../../utils/auth";
import Footer from "../../components/Footer";
import FollowersListDialog from "./FollowersListDialog";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
import  PersonIcon  from "@mui/icons-material/Person";
import { useDispatch, useSelector } from "react-redux";
 import getProfilePictureByUserIdReducer  from "../../Redux/store";
 import { fetchProfilePicture } from "../../Redux/reducers/editProfile/profilePicGetReducer";
// import CustomSnackbar from '../.././components/CustomSnakbar/CustomSnackbar'
 
const Profile = () => {
  // const authData = getAuthData();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
 
  const [isEditingUser, setIsEditingUser] = useState(false);
  const [isEditingPhone, setIsEditingPhone] = useState(false);
  const [isEditingPic, setIsEditingPic] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [addBio, setAddBio] = useState(false)
  const [authData, setAuthData] = useState(getAuthData())
 
  const [userNameInput, setUserNameInput] = useState(
    authData?.auth?.Name || ""
  );
  const [phoneNoInput, setPhoneNoInput] = useState(
    authData?.auth?.PhoneNo || ""
  );
  const [bioInput, setBioInput] = useState(
    authData?.auth?.Bio || ""
  );
  const [ProfileImg, setProfileImg] = useState(
    authData?.auth?.ProfileImg || ""
  );
  const [ProfilePic, setProfilePic] = useState(authData?.auth?.ProfilePic || "");
 
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [followersDialogStatus, setFollowersDialogStatus] = useState(false);
  const [followerData, setFollowerData] = useState("");
  const [follingData, setFollowingData] = useState('')
  const [PicBaseString, setPicBaseString] = useState(
    authData?.auth?.ProfilePic || ""
  );
  // const dispatch = useDispatch();
  // const profilePicture = useSelector((state)=>state.rootReducer.getProfilePictureByUserIdReducer)
 
 
  // useEffect(() => {
  //   console.log("Profile Picture Data:", profilePicture);
  // }, [profilePicture]);
 
 
  // useEffect(() => {
  //   console.log("Fetching Profile Picture for user:", authData?.auth?.userId);
  //   if (authData?.auth?.userId) {
  //     dispatch(fetchProfilePicture(authData.auth.userId));
  //   }
  // }, [dispatch, authData?.auth?.userId]);
 
  const profileFollowersData = async () => {
    try {
      const response = await axios.get(
        `${endpoints.useroperations_getfollowercount}?userid=${authData?.auth?.Id}`
      );
      // console.log(response.data);
      setFollowerData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
   
    const profileFollowingData = async () => {
      try {
        const response = await axios.get(
          `${endpoints.useroperations_getfollowingcount}?userid=${authData?.auth?.Id}`
        );
        // console.log(response.data);
        setFollowingData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
 
 
    useEffect(() => {
      if (authData?.auth) {
        profileFollowersData();
 
        profileFollowingData();
      }
    }, []);
   
  const [isLoading, setIsLoading] = useState(false); // Loading state
  function handleLogout() {
    setLoaderStatus(true);
    axios
      .get(`${endpoints.logout}?userid=${authData?.auth?.Id}`, {
        headers: {
          Authorization: `Bearer ${authData?.authToken}`,
        },
      })
 
      .then((response) => {
        if (response.status === 200) {
          localStorage.removeItem("loginUser");
          localStorage.removeItem("web-view-count");
          localStorage.removeItem("isSignIn");
          setLoaderStatus(false);
          navigate("/");
          // window.location.reload(false);
        }
      })
 
      .catch((error) => {
        setLoaderStatus(false);
        // alert(error)
      });
  }
  const handleClickFollowers = () => {
    setFollowersDialogStatus(!followersDialogStatus);
  };
 
  const handleUserEditClick = () => {
    setIsEditingUser(true);
  };
 
  const handlePhoneEditClick = () => {
    setIsEditingPhone(true);
  };
 
  const handleBioClick = () => {
    setAddBio(true);
  }
 
  const handleClose = () => {
    setOpen(false);
  };
 
 
  // const handleRemovePic = () => {
  //   setPicBaseString(null);
  // };
  const handleRemovePic = () => {
    // Clear the profile picture state
    setProfilePic(null);
    setPicBaseString(null);
 
    // Update local storage to reflect the removal of the profile picture
    const storedAuthData = JSON.parse(localStorage.getItem("loginUser"));
    if (storedAuthData && storedAuthData.auth) {
      const updatedAuthObject = {
        ...storedAuthData.auth,
        Pic: null, // Set the profile picture to null
      };
 
      localStorage.setItem(
        "loginUser",
        JSON.stringify({
          auth: updatedAuthObject,
          authToken: storedAuthData.authToken,
        })
      );
 
      // Update authData state to reflect the changes immediately
      setAuthData({
        auth: updatedAuthObject,
        authToken: storedAuthData.authToken,
      });
    }
  };
 
 
 
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
 
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
 
  const handleToOpenDialog = () => {
    setOpen(true);
  };
 
  const handleEditPic = (event) => {
    setIsEditingPic(true);
    const file = event.target.files[0];
    var reader = new FileReader();
    reader.onloadend = () => {
      // console.log(reader.result);
      setPicBaseString(reader.result.split(',')[1]);
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");
      setPicBaseString(base64String);
      setProfilePic(base64String);
    };
    reader.readAsDataURL(file);
  };
 
  const handleUserData = () => {
    setIsEditingUser(false);
    setIsEditingPhone(false);
    setIsEditingPic(false);
    setAddBio(false);
 
    if (
      userNameInput.trim() === "" ||
      phoneNoInput.trim() === ""
      // ||
      // bioInput.trim() === ""
    ) {
      setSnackbarSeverity("error");
      setSnackbarMessage("Please fill all input fields.");
      setSnackbarOpen(true);
      return;
    }
    if (phoneNoInput.trim().length !== 10) {
      setSnackbarSeverity("error");
      setSnackbarMessage("Please provide a valid phone number.");
      setSnackbarOpen(true);
      return;
    }
 
    setIsLoading(true);
 
    const data = {
      Id: authData?.auth.Id,
      Name: userNameInput,
      PhoneNo: phoneNoInput,
      Pic: ProfilePic || " ",
      Bio: bioInput || " ",
    };
     
 
    // console.log("Data being sent to API:", data);
 
 
    axios
      .put(endpoints.user_editprofile, data, {
        headers: {
          Authorization: `Bearer ${authData?.authToken}`,
        },
      })
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          const updatedAuthObject = {
            ...authData?.auth,
            Name: data.Name,
            PhoneNo: data.PhoneNo,
            Pic: data.Pic,
            Bio: data.Bio,
          };
 
          localStorage.setItem(
            "loginUser",
            JSON.stringify({
              auth: updatedAuthObject,
              authToken: authData?.authToken,
            })
          );
 
          // Update the authData state to reflect the changes immediately
          setAuthData({
            auth: updatedAuthObject,
            authToken: authData?.authToken,
          });
 
          setSnackbarSeverity("success");
          setSnackbarMessage("Profile updated successfully");
          setSnackbarOpen(true);
          setOpen(false);
        } else {
          setSnackbarSeverity("error");
          setSnackbarMessage("Failed to update profile");
          setSnackbarOpen(true);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setSnackbarSeverity("error");
        setSnackbarMessage("Network error. Please try again.");
        setSnackbarOpen(true);
        console.error("Error updating profile: ", error);
      });
  };
 
 
  const CustomSnackbar = ({ open, message, severity }) => {
    return (
      <Snackbar
        anchorOrigin={{ vertical: "Bottom", horizontal: "center" }}
        open={open}
        autoHideDuration={4000} // Adjust duration as needed
        onClose={() => setSnackbarOpen(false)}
      >
        <SnackbarContent
          sx={{
            backgroundColor: severity === "success" ? "#43A047" : "#D32F2F",
          }}
          message={message}
        />
      </Snackbar>
    );
  };
 
 
  useEffect(() => {
    const storedAuthData = JSON.parse(localStorage.getItem("loginUser"));
    // console.log("Stored Auth Data:", storedAuthData);
 
    if (storedAuthData && storedAuthData.auth) {
      setAuthData(storedAuthData);
      setUserNameInput(storedAuthData.auth.Name || "");
      setPhoneNoInput(storedAuthData.auth.PhoneNo || "");
      setBioInput(storedAuthData.auth.Bio || "");
      setProfilePic(storedAuthData.auth.Pic || "");
 
      profileFollowersData();
      profileFollowingData();
    }
  }, []);
 
  // console.log("ProfilePic length", ProfilePic);
 
  return (
    <>
      <Grid container bgcolor={"#000"}>
        <Header />
        <Grid
          display={"flex"}
          flexDirection={"column"}
          p={{xs:0,sm:5}}
          pt={8}
          width={"100%"}
          maxHeight={"auto"}
          justifyContent={"space-evenly"}
          mb={3}
        >
          <Grid xs={12} display={"flex"} flexDirection={"row"} pb={2}>
            <Grid
              xs={4}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
               <Avatar
                sx={{
                  width: 90,
                  height: 90,
                  backgroundColor: "#D9D9D9",
                  color: "#121212",
                }}
              >
                {/* && (ProfilePic.length > 0) */}
                {ProfilePic && ProfilePic.trim() ? (
                  <img
                    src={`data:image/png;base64,${ProfilePic}`}
                   
                    alt=""
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <PersonIcon sx={{ fontSize: 50 }} />
                )}
              </Avatar>
 
 
              <Typography
                variant="h5"
                sx={{ mt: {xs:1,sm:2} }}
                fontSize={{xs:15,sm:20}}
                // className="profile-page"
                color={'#FFDA78'}
              >
                {authData?.auth?.Name}
              </Typography>
            </Grid>
            <Grid
              xs={6}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={'space-between'}
              alignItems={"center"}
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography fontFamily={'Quicksand'} fontSize={{xs:'13px', sm:'15px'}} fontWeight={{xs:500,sm:700}} color={'#FFDA78'}>0</Typography>
                <Typography fontFamily={'Quicksand'} fontSize={{xs:'10px', sm:'15px'}} fontWeight={{xs:500,sm:700}} color={'#FFDA78'}>Posts</Typography>
              </Box>
 
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                onClick={handleClickFollowers}
                sx={{ cursor: "pointer" }}
              >
                <Typography fontFamily={'Quicksand'} fontSize={{xs:'13px', sm:'15px'}} fontWeight={{xs:500,sm:700}} color={'#FFDA78'}>{followerData || 0} </Typography>
                <Typography fontFamily={'Quicksand'} fontSize={{xs:'13px', sm:'15px'}} fontWeight={{xs:500,sm:700}} color={'#FFDA78'}>followers</Typography>
              </Box>
 
              <FollowersListDialog
                followersDialogStatus={followersDialogStatus}
                handleClickFollowers={handleClickFollowers}
              />
 
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography fontFamily={'Quicksand'} fontSize={{xs:'13px', sm:'15px'}} fontWeight={{xs:500,sm:700}} color={'#FFDA78'}>{follingData || 0} </Typography>
                <Typography fontFamily={'Quicksand'} fontSize={{xs:'13px', sm:'15px'}} fontWeight={{xs:500,sm:700}} color={'#FFDA78'}>following</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid mt={1}
              display={"flex"}
              flexDirection={"column"}
              // alignItems={"center"}
              >
            <Typography
              sx={{
               
                width: {  md: "70%" },
                "@media (max-width: 1650px)": {
                  marginLeft: "189px",
                },
                "@media (max-width: 1040px)": {
                  marginLeft: "120px",
                },
                "@media (max-width: 780px)": {
                  width:"290px",
                  marginLeft: "85px",
                  // wordWrap: "break-word",
                },
                "@media (max-width: 495px)": {
                  // // width: "120px",
                  // marginLeft:"35px"
                  width: "200px",
        marginLeft: "21px",
        // wordWrap: "break-word",
                },
              }}
              textAlign={"justify"}
              className="profile-page"
              color={'#FFDA78'}
            >
             {authData?.auth?.Bio}
            </Typography>
          </Grid>
          <Grid mt={2.4} xs={4}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleToOpenDialog}
              sx={{
                ml: { },
                fontFamily: "Quicksand",
                fontWeight: 700,
                fontSize: 15,
                borderRadius: 1,
                textTransform:"capitalize",
                width:"auto",
                "@media (max-width: 425px)": {
                  width: "112px",
                  marginLeft:"41px"
                },
              }}
            >
              Edit Profile
            </Button>
           
          </Grid>
         
        </Grid>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: {
              borderRadius: 5,
              minWidth: {xs:'300px',sm:'500px'},
              backgroundColor: "#EFEFEF", // Dialog background color
              padding: "5px",
            },
          }}  
        >
          <Grid dividers>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={2}
              p={{xs:0.5,sm:7}}
              justifyContent={"center"}
              alignItems={"center"}
              backgroundColor={"#FFDA78"}
              borderRadius={'15px'}
            >
             <Avatar
                sx={{
                  width: 90,
                  height: 90,
                  backgroundColor: "#D9D9D9",
                  color: "#121212",
                }}
              >
                {ProfilePic && ProfilePic.trim() ? (
                  <img
                    src={`data:image/png;base64,${ProfilePic}`}
                    alt={userNameInput}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <PersonIcon sx={{ fontSize: 50 }} />
                )}
              </Avatar>
 
              <Box display={"flex"} flexDirection={"row"} gap={2}>
                <input
                  type="file"
                  hidden
                  id="upload-input"
                  onChange={handleEditPic}
                />
                <label htmlFor="upload-input" style={{ cursor: "pointer" }}>
                  <Typography
                    fontFamily={"Quicksand"}
                    fontWeight={700}
                    fontSize={16}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <RiPencilFill style={{ marginRight: 4 }} />
                    Edit profile Pic
                  </Typography>
                </label>
                {/* <input name="hi" type="file"  onChange={handleEditPic} /> */}
                <Typography
                  fontFamily={"Quicksand"}
                  fontWeight={700}
                  fontSize={16}
                  sx={{ display: "flex", alignItems: "center", cursor:'pointer' }}
                  onClick={handleRemovePic}
                >
                  <MdDelete style={{ marginRight: 4 }}  />
                  Remove profile
                </Typography>
              </Box>
              <Grid
                container
                flexDirection={"column"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
              >
                <Box width={"100%"}>
                  {isEditingUser ? (
                    <Box display={"flex"} alignItems={"center"} gap={2}>
                      <Typography
                        variant={"subtitle2"}
                        fontWeight={400}
                        fontSize={16}
                        sx={{ width: "auto", mt: 2 }}
                      >
                        User Name:
                      </Typography>
 
                      <TextField
                        id="user-name-input"
                        placeholder="Enter Your Name"
                        // label="Enter user name"
                        value={userNameInput}
                        onChange={(e) => setUserNameInput(e.target.value)}
                        variant="standard"
                        sx={{
                          "& .MuiInputLabel-root": {
                            color: "#000", // Adjust label color
                          },
                          "& .MuiInputBase-root": {
                            color: "#000", // Adjust input text color
                            backgroundColor: "transparent", // Adjust input background color if needed
                          },
                          "& .MuiInput-underline:before": {
                            borderBottomColor: "#000", // Adjust underline color
                          },
                          "& .MuiInput-underline:hover:before": {
                            borderBottomColor: "#000", // Adjust underline hover color
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor: "#000", // Adjust underline after focus color
                          },
                          "& .MuiInputBase-input": {
                            caretColor: "#000", // Change caret (cursor) color here
                          },
                        }}
                      />
                    </Box>
                  ) : (
                    <Typography
                      fontFamily={"Quicksand"}
                      fontWeight={700}
                      fontSize={16}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      User Name: {authData?.auth?.Name}
                      <IconButton
                        onClick={handleUserEditClick}
                        style={{ marginLeft: 4 }}
                      >
                        <RiPencilFill />
                      </IconButton>
                    </Typography>
                  )}
                </Box>
 
                <Box width={"100%"}>
                  {isEditingPhone ? (
                    <Box display={"flex"} alignItems={"center"} gap={2}>
                      <Typography
                        variant={"subtitle2"}
                        fontWeight={400}
                        fontSize={16}
                        sx={{ width: "20%", mt: 2 }}
                      >
                        Mobile No:
                      </Typography>
 
                      <TextField
                        type="number"
                        id="user-name-input"
                        variant="standard"
                        placeholder="Enter phone number"
                        value={phoneNoInput}
                        onChange={(e) => setPhoneNoInput(e.target.value)}
                        // onBlur={handlePhoneSave}
                        autoFocus
                        sx={{
                          "& .MuiInputLabel-root": {
                            color: "#000", // Adjust label color
                          },
                          "& .MuiInputBase-root": {
                            color: "#000", // Adjust input text color
                            backgroundColor: "transparent", // Adjust input background color if needed
                          },
                          "& .MuiInput-underline:before": {
                            borderBottomColor: "#000", // Adjust underline color
                          },
                          "& .MuiInput-underline:hover:before": {
                            borderBottomColor: "#000", // Adjust underline hover color
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor: "#000", // Adjust underline after focus color
                          },
                          "& .MuiInputBase-input": {
                            caretColor: "#000", // Change caret (cursor) color here
                          },
                        }}
                      />
                    </Box>
                  ) : (
                    <Typography
                      fontFamily={"Quicksand"}
                      fontWeight={700}
                      fontSize={16}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      Mobile Number: {authData?.auth?.PhoneNo}
                      <IconButton
                        onClick={handlePhoneEditClick}
                        style={{ marginLeft: 4 }}
                      >
                        <RiPencilFill />
                      </IconButton>
                    </Typography>
                  )}
                </Box>
                <Box width={"100%"}>
                {addBio ? (
                    <Box display={"flex"} alignItems={"center"} gap={2}>
                      <Typography
                        variant={"subtitle2"}
                        fontWeight={400}
                        fontSize={16}
                        sx={{ width: "auto", mt: 2 }}
                      >
                        About:
 
                      </Typography>
 
                      <TextField
                        id="user-name-input"
                        placeholder="Enter Your Bio"
 
                        // label="Enter user name"
                        value={bioInput}
                        onChange={(e) => setBioInput(e.target.value)}
                        variant="standard"
                        // autoFocus
                        InputProps={{
                          // style: {
                          //   width: "100%", // Make input take full width
                          // },
                          inputProps: {
                            style: {
                              width: "120px", // Fix width for placeholder text
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            },
                          },
                        }}
                       
                        sx={{
                          "& .MuiInputLabel-root": {
                            color: "#000", // Adjust label color
                          },
                          "& .MuiInputBase-root": {
                            color: "#000", // Adjust input text color
                            backgroundColor: "transparent", // Adjust input background color if needed
                          },
                          "& .MuiInput-underline:before": {
                            borderBottomColor: "#000", // Adjust underline color
                          },
                          "& .MuiInput-underline:hover:before": {
                            borderBottomColor: "#000", // Adjust underline hover color
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor: "#000", // Adjust underline after focus color
                          },
                          "& .MuiInputBase-input": {
                            caretColor: "#000", // Change caret (cursor) color here
                          },
                        }}
                      />
                    </Box>
                  ) : (
                    <Typography
                      fontFamily={"Quicksand"}
                      fontWeight={700}
                      fontSize={16}
                      sx={{ display: "flex", alignItems: "center",
                     }}
                    >
                      About:
                      <Box
                      style={{
                        // width: "130px", // Make input take full width
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                      >
                      {authData?.auth?.Bio}
                      </Box>
                       {/* {authData?.auth?.Bio} */}
                     
                      <IconButton
                        onClick={handleBioClick}
                        style={{ marginLeft: 4 }}
                      >
                        <RiPencilFill />
                      </IconButton>
                    </Typography>
                  )}
                </Box>
              </Grid>
              {/* <Box>
                <Typography
                  fontSize={"14px"}
                  fontWeight={'bold'}
                  sx={{ display: "inline", alignItems: "center" }}
                >
                  About: Welcome to my gaming channel, where I dive into the
                  latest games, share epic gameplay moments, and provide tips
                  and tricks to help you level up your skills. Join me for
                  exciting live streams, reviews, and a community of passionate
                  gamers!
                </Typography>
                <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    ml: 1,
                  }}
                >
                  <RiPencilFill />
                </Box>
              </Box> */}
 
              <Box>
                {(isEditingUser || isEditingPhone || isEditingPic || addBio) && (
                  <Button
                    onClick={handleUserData}
                    variant="contained"
                    sx={{ color: "white", backgroundColor: "black" }}
                  >
                    Save
                  </Button>
                )}
              </Box>
              {isLoading && (
                <Box mt={2} display="flex" justifyContent="center">
                  <CircularProgress color="info" />
                </Box>
              )}
            </Box>
          </Grid>
        </Dialog>
        <CustomSnackbar
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />
        {loaderStatus && <Loader message="Logging out, please wait..." />}
        <Footer />
      </Grid>
    </>
  );
};
 
export default Profile;