import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../../utils/statusCodes";
import endpoints from "../../../Endpoints/endpoints";

const initial_clearAllWatchHistory = {
  clearAllWatchHistoryData: [],
  ClearAllWatchHistoryFetchStatus: statusCodes.IDLE,
};

export const fetchClearAllWatchHistory = createAsyncThunk(
  "fetchClearAllWatchHistory/delete",
  async (userid) => {
    try {
      const clearAllwatchHistory = await axios.delete(
        `${endpoints.clearAllWatchHistory}?userid=${userid}`
      );
      return clearAllwatchHistory.data;
    } catch (error) {
      const errorPayload = {
        message: error.response.data.message,
      };
      throw errorPayload;
    }
  }
);

const clearAllWatchHistoryReducer = createSlice({
  name: "clearAllWatchHistoryReducer",
  initialState: initial_clearAllWatchHistory,
  extraReducers: (builder) => {
    builder
      .addCase(fetchClearAllWatchHistory.pending, (state, action) => {
        state.ClearAllWatchHistoryFetchStatus = statusCodes.LOADING;
        // console.log("clear All watch history data pending");
      })
      .addCase(fetchClearAllWatchHistory.fulfilled, (state, action) => {
        state.clearAllWatchHistoryData = action.payload;
        state.ClearAllWatchHistoryFetchStatus = statusCodes.SUCCESS;
        // console.log(state.clearAllWatchHistoryData);
      })
      .addCase(fetchClearAllWatchHistory.rejected, (state, action) => {
        state.ClearAllWatchHistoryFetchStatus = statusCodes.ERROR;
        // console.log("clear All watch history data error");
      });
  },
});

export default clearAllWatchHistoryReducer.reducer;
