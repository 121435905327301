import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../../utils/statusCodes";
import endpoints from "../../../Endpoints/endpoints";

const getSuggestedMoviesApi = endpoints.getSuggestedMovies;

const initial_suggestedMovies = {
  suggested_movies: [],
  suggestedMoviesFetchStatus: statusCodes.IDLE,
};

const getSuggestedMoviesReducer = createSlice({
  name: "getSuggestedMoviesReducer",
  initialState: initial_suggestedMovies,
  reducers: {
    resetSuggestedMovies(state) {
      state.suggested_movies = [];
      state.suggestedMoviesFetchStatus = statusCodes.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSuggestedMovies.pending, (state, action) => {
        state.suggestedMoviesFetchStatus = statusCodes.LOADING;
        // console.log("suggested movies data pending");
      })
      .addCase(fetchSuggestedMovies.fulfilled, (state, action) => {
        state.suggested_movies = action.payload;
        state.suggestedMoviesFetchStatus = statusCodes.SUCCESS;
        // console.log(state.suggested_movies);
      })
      .addCase(fetchSuggestedMovies.rejected, (state, action) => {
        state.suggestedMoviesFetchStatus = statusCodes.ERROR;
        // console.log("suggested movies  data error");
      });
  },
});


export const fetchSuggestedMovies = createAsyncThunk(
  "suggestedMovies/get",
  async () => {
    try {
      const suggestedMovies = await axios.get(getSuggestedMoviesApi);
      // console.log(getSuggestedMoviesApi);
      return suggestedMovies.data;
    } catch (error) {
      const errorPayload = {
        message: error.response.data.message,
      };
      throw errorPayload;
    }
  }
);
export const { resetSuggestedMovies } = getSuggestedMoviesReducer.actions;
export default getSuggestedMoviesReducer.reducer;
