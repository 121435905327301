import {
  Box,
  Grid,
  Typography,
  TextField,
  Checkbox,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useState } from "react";

import { useDispatch } from "react-redux";

import { Formik } from "formik";
import * as Yup from "yup";

//   import "./index.css";
import { authForgotPswd } from "../../../Redux/reducers/authForgotPswdReducer";
import { useNavigate } from "react-router-dom";
import endpoints from "../../../Endpoints/endpoints";
import axios from "axios";
import { getAuthData } from "../../../utils/auth"; 
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";


const RefferalCode = () => {
  const navigate = useNavigate();
  const authData = getAuthData();
  const [isValid, setIsValid] = useState(""); 
  const [referralCode, setReferralCode] = useState("");
  const [openDialog, setOpenDialog] = useState(false); 
  const [buttonText, setButtonText] = useState("Verify");
  const [buttonTextColor, setButtonTextColor] = useState("");

  const [buttonColor, setButtonColor] = useState("primary"); 
  const [errorMessage, setErrorMessage] = useState(""); 
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState('');

  const [inputValue, setInputValue] = useState(""); 
  const [handleVerification, setHandleVerification]= useState(false);



  // const handleReferralCodeChange = (e) => {
  //   setReferralCode(e.target.value);
  //   // setErrorMessage("");
  //   // console.log(referralCode);

  // };
  

  const handleReferralCodeChange = (event) => {
    setInputValue(event.target.value);
    setErrorMessage("");
    setButtonText("Verify");
    setUserName("");
    setButtonTextColor("primary")
    setHandleVerification(false);
    setUserId("");
    setReferralCode(event.target.value);



  };


const handleVerify = async () => {
  if (!inputValue) {
    setErrorMessage("Enter valid referral code");
    return;
  }

  try {
    let response;
    const isPhoneNumber = /^\d{10}$/.test(inputValue);

    if (isPhoneNumber) {
      response = await axios.get(
        `${endpoints.referral_Is_Valid}?phoneNumber=${inputValue}`
      );
      // console.log("Phone Number API Response:", response.data);
    } else {
      response = await axios.get(
        `${endpoints.referral_Is_Valid}?referralCode=${inputValue}`
      );
      // console.log("Referral Code API Response:", response.data);
    }

    const result = response.data[0];
    if (!result || !result.UserId) {
      setErrorMessage(isPhoneNumber ? "Invalid phone number" : "Invalid referral code");
      setButtonText("Try Again");
      setButtonTextColor("red");
      setButtonColor("primary");
      setHandleVerification(false);
      return;
    }

    setUserId(result.UserId);
    setReferralCode(result.referralCode || inputValue); // Handle case if referralCode is not returned
    setUserName(result.UserName);
    setErrorMessage("");
    setButtonText("Verified");
    setButtonTextColor("green");
    setHandleVerification(true); // Successfully verified

  } catch (error) {
    console.error("Error verifying input:", error);
    setErrorMessage("Invalid referral code or phone number");
    setButtonText("Try Again");
    setButtonColor("error");
    setHandleVerification(false);
  }
};

const handleSubmit = async () => {
  if (!inputValue) {
    setErrorMessage("Please enter referral code");
    return;
  }

  if (!handleVerification) {
    setErrorMessage("Please verify referral code");
    return;
  }

  const reffer = authData?.auth?.Id;
  const payload = {
    ReferredByUserId: userId,
    ReferredByCode: referralCode,  // Ensure this value is set in handleVerify
    UserId: reffer,
  };

  // console.log('Payload:', payload);

  try {
    const response = await axios.post(endpoints.referral_ReferUser, payload);
    // console.log('POST API Response:', response.data);
    setOpenDialog(true); // Show the dialog on successful submission
  } catch (error) {
    console.error('Error making POST request:', error);

    if (error.response && error.response.data) {
      setErrorMessage(error.response.data.message || 'Submission failed.');
    } else {
      setErrorMessage('An unexpected error occurred.');
    }
  }
};


  const handleSkip = () => {
    setOpenDialog(true);
  };
  

  const handleDialogClose = () => {
    setOpenDialog(false);
    navigate("/login"); 
  };
  

  return (
    <Grid container className="bg-image" height={"100vh"} width={"100vw"}>
      <Grid
        item
        xs={12}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box
          sx={{ width: { xs: "70%", sm: "30%", lg: "25%" } }}
          bgcolor={"#010113"}
          border={"5px solid #FFDA78"}
          borderRadius={6}
        >
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            pt={2}
            pb={4}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={"https://bucketblob.iott.co.in/Icons/logo.png"}
                

                alt="logo"
                className="logo"
                onClick={() => navigate("/")}
              />

              <Typography
                mb={1}
                fontFamily={"Quicksand"}
                fontWeight={600}
                fontSize={20}
                color={"#FFDA78"}
              >
                Have Referral Code?
              </Typography>
            </Box>
            <Box mt={0} >
          
            <TextField
              sx={{  mt: 4, width: "230px",
                '& .MuiInputBase-input::placeholder': {
                  color: '#FFDA78', 
                  opacity: 1, 
                },
                color: '#FFDA78',  }}
              placeholder="Referral Code"
              variant="standard"
              value={inputValue}
              // onChange={handleReferralCodeChange}
              onChange={handleReferralCodeChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      onClick={handleVerify}
                      variant="contained"
                      sx={{ height: 25, px: 2, mb: 1, fontWeight: 600 , color: buttonTextColor,}}
                    >
                      {buttonText}
                    </Button>
                  </InputAdornment>
                ),
              }}
            /> 
            {errorMessage && (
             <Typography color={'red'} marginTop={"5px"}>{errorMessage}</Typography>
              )}
            <Typography color={'green'} fontFamily={'Quicksand'} fontSize={20} fontWeight={600}>{userName}</Typography>
            </Box>
            {/* <Box mt={2}>
            <Typography mr={21} mb={1} color={'#FFDA78'} fontWeight={500} fontSize={'10px'} >DOB</Typography>
            </Box> */}
            {/* <Box sx={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
              {["MM", "DD", "YYYY"].map((placeholder, index) => (
                <TextField
                  key={index}
                  variant="outlined"
                  placeholder={placeholder}
                  size="small"
                  sx={{
                    width: "60px",
                    textAlign: "center",
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#121212", // Background color of the input
                      borderRadius: "5px", // Rounded corners
                      "& fieldset": {
                        borderColor: "#D4AF37", // Default border color
                      },
                      "&:hover fieldset": {
                        borderColor: "#D4AF37", // Border color on hover
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#D4AF37", // Border color when focused
                      },
                    },
                    input: {
                      color: "#D4AF37", // Text color
                      textAlign: "center", // Center-align text
                      padding: "10px 0", // Padding inside the input
                      fontWeight: "bold", // Bold font style
                      fontSize: "14px", // Font size
                    },
                  }}
                />
              ))}
            </Box> */}

           <Box alignSelf={"center"} width={"75%"} mt={5} mb={3} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', 
  }}>
              <Button disabled={!handleVerification} type="submit"  onClick={handleSubmit}  sx={{ mb: 2, fontWeight: "900", width: '150px', }}>
                Submit
              </Button>
              <Button
                type="submit"
                onClick={handleSkip}
                
                sx={{ fontWeight:"900", width: '150px', }}
              >
                Skip
              </Button>
            </Box>
            
          </Box>
        </Box>
      </Grid>
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle
          sx={{
            fontFamily: "Merriweather",
            fontSize: { xs: "12px", sm: "18px" },
            fontWeight: { xs: 600, sm: 700 },
          }}
        >
          Registration Successful
        </DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              fontFamily: "Quicksand",
              fontSize: { xs: "10px", sm: "15px" },
              fontWeight: { xs: 500, sm: 600 },
            }}
          >
            You have registered successfully. Please go login page
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary" sx={{fontWeight:"900",}}  >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default RefferalCode;
