import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../../utils/statusCodes";
import endpoints from "../../../Endpoints/endpoints";

// Initial state
const initial_walletAmount = {
  wallet_amount: 0, // Default value
  walletAmountFetchStatus: statusCodes.IDLE,
};

// Async thunk to fetch wallet amount
export const fetchWalletAmount = createAsyncThunk(
  "walletAmount/get",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${endpoints.getWalletAmount}?userId=${userId}`
      );
      return response.data;
    } catch (error) {
      // Reject with meaningful error
      return rejectWithValue(error.response?.data || { message: "Error fetching wallet amount" });
    }
  }
);

// Slice
const getWalletAmountReducer = createSlice({
  name: "getWalletAmountReducer",
  initialState: initial_walletAmount,
  extraReducers: (builder) => {
    builder
      .addCase(fetchWalletAmount.pending, (state) => {
        state.walletAmountFetchStatus = statusCodes.LOADING;
      })
      .addCase(fetchWalletAmount.fulfilled, (state, action) => {
        state.wallet_amount = action.payload.Amount;
        state.walletAmountFetchStatus = statusCodes.SUCCESS;
      })
      .addCase(fetchWalletAmount.rejected, (state, action) => {
        state.walletAmountFetchStatus = statusCodes.ERROR;
        console.error("Error fetching wallet amount:", action.payload?.message);
      });
  },
});

// Export reducer
export default getWalletAmountReducer.reducer;
