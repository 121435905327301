import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchProfilePicture = createAsyncThunk(
  'profilePicture/fetchProfilePicture',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `https://api.iott.co.in/api/user/GetProfilePicAndNameByUserId?id=${userId}`
      );
      return response.data;  
    } catch (error) {
      // Handle errors and return them to be handled in the reducer
      console.error(error);
      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);

const getProfilePictureByUserIdReducer = createSlice({
  name: 'profilePicture', 
  initialState: {
    profiledata: null, 
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfilePicture.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProfilePicture.fulfilled, (state, action) => {
        state.loading = false;
        // console.log("Action Payload:", action.payload); 
        state.profiledata = action.payload;  
      })
      .addCase(fetchProfilePicture.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch profile picture data';
      });
  },
});

export default getProfilePictureByUserIdReducer.reducer;
