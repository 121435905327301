import {
  Box,
  Grid,
  Typography,
  TextField,
  Checkbox,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useState } from "react";

import { useDispatch } from "react-redux";

import { Formik } from "formik";
import * as Yup from "yup";

import "./index.css";
import { authForgotPswd } from "../../../Redux/reducers/authForgotPswdReducer";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [resetPassword, setResetPassword] = useState(false);

  const dispatch = useDispatch();

  return (
    <Grid container className="bg-image" height={"100vh"} width={"100vw"}>
      <Grid
        item
        xs={12}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box
          sx={{ width: { xs: "70%", sm: "30%", lg: "25%" } }}
          bgcolor={"#010113"}
          border={"5px solid #FFDA78"}
          borderRadius={6}
        >
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            pt={2}
            pb={4}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={"https://bucketblob.iott.co.in/Icons/logo.png"}
                alt="logo"
                className="logo"
                onClick={() => navigate("/")}
              />

              <Typography
                mb={1}
                fontFamily={"Merriweather"}
                fontWeight={600}
                fontSize={20}
                color={"#FFDA78"}
              >
                FORGOT PASSWORD
              </Typography>
            </Box>

            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .required("Enter a Email")
                  .matches(
                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    "Invalid email address"
                  ),
              })}
              onSubmit={(values) => {
                // console.log("Form ForgotPswd Values:", values);

                dispatch(authForgotPswd(values)).then((response) => {
                  if (response.meta.requestStatus === "fulfilled") {
                    setResetPassword(
                      "Reset Password Link sent To your Registered Email"
                    );
                  } else if (response.meta.requestStatus === "rejected") {
                    if (
                      response.error.message ===
                      "Request failed with status code 400"
                    ) {
                      setResetPassword("User not exist");
                    } else if (response.error.name === "TypeError") {
                      setResetPassword("Check Your Internet Connectivity");
                    } else {
                      setResetPassword(
                        "Something Went Wrong, please try again later"
                      );
                    }
                  }
                });
              }}
            >
              {(formik) => {
                return (
                  <form
                    onSubmit={formik.handleSubmit}
                    className="forgotPassword-form"
                  >
                    <TextField
                      sx={{ mb: 3, mt: 4,
                        "& .MuiInputBase-input::placeholder": {
                                  color: "#FFDA78",
                                  opacity: 1,
                                },
                        "& input:-webkit-autofill": {
                                  WebkitBoxShadow: "0 0 0 1000px black inset",
                                  WebkitTextFillColor: "#FFDA78", // Text color for autofill
                                },
                       }}
                      fullWidth
                      placeholder="Enter Registered Email"
                      variant="standard"
                      {...formik.getFieldProps("email")}
                      helperText={formik.touched.email && formik.errors.email}
                    />

                    <Box alignSelf={"center"} width={"75%"} mt={5} mb={3}>
                      <Button type="submit" fullWidth sx={{fontWeight: "800"}} >
                        Reset Password
                      </Button>
                    </Box>
                  </form>
                );
              }}
            </Formik>

            {resetPassword ? (
              <Typography width={"70%"} color={"#13FF13"} textAlign={"center"}  >
                {resetPassword}
              </Typography>
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
