import React, { useState, useEffect, useRef, useMemo } from "react";
import Slider from "react-slick";
import Grid from "@mui/material/Grid";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./index.css";

import { useDispatch, useSelector } from "react-redux";
import { fetchHomeDashboardBanners } from "../../Redux/reducers/dashboardBannersReducer";
import { Button, Dialog, IconButton, Tooltip, Typography } from "@mui/material";
import { FaPlay } from "react-icons/fa";
import { Link } from "react-router-dom";

import debounce from "lodash.debounce";
import {
  fetchVideoById,
  setMovieState,
} from "../../Redux/reducers/videos/getVideoByIdReducer";

import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOff from "@mui/icons-material/VolumeOff";
import {
  fetchByGenereDashboardBanners,
  generBannerCache,
} from "../../Redux/reducers/bannersByGenereReducer";
import { useLocation, useNavigate } from "react-router-dom";
import statusCodes from "../../utils/statusCodes";
import { setHoveredState } from "../../Redux/reducers/movieHoverReducer";

const HomeGenerSlider = (props) => {
  const location = useLocation();
  const { gener, title } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [modalPosition, setModalPosition] = useState({
    x: 0,
    y: 0,
    height: null,
    width: null,
  });

  const hoveredMovieId = useSelector(
    (state) => state.rootReducer.movieHoverReducer
  );
  // console.log('hovered g is here: ', hoveredMovieId)

  //const videoRef = useRef(null);

  //const [videoRimg, setVideoRimg] = useState(true);
  //const [autoplayAllowed, setAutoplayAllowed] = useState(false);
  const [videoMuted, setVideoMuted] = useState(false);
  const [currentDetails, setCurrentDetails] = useState([]);

  // const handlePlayButtonClick = () => {
  //   // console.log("clicking");
  //   setAutoplayAllowed(true);
  //   setVideoMuted(!videoMuted);
  //   // setTooltipOpen(false);
  // };

  // const handleDelayedAutoplay = () => {
  //   setVideoRimg(false); // Hide the image
  //   setTimeout(() => {
  //     setAutoplayAllowed(true); // Allow autoplay after a delay
  //   }, 500); // Adjust the delay as needed
  // };

  // const handleItemHoverRelatedPage = debounce((event, index, eachItem) => {
  //   // dispatch(fetchVideoById(eachItem.Id));

  //   const fetchVideoByIdJSON = {
  //     fetchedMovieId: eachItem?.Id,
  //     categoryValue: eachItem?.Category,
  //   };
  //   dispatch(fetchVideoById(fetchVideoByIdJSON));
  //   setHoveredMovieId(eachItem.Id);
  //   // // console.log(event.target);
  //   const slickItem = event.target.getBoundingClientRect();
  //   // // console.log(slickItem);
  //   setModalPosition({
  //     x: slickItem.left,
  //     y: slickItem.top,
  //     height: slickItem.height * 2.48,
  //     width: slickItem.width,
  //   });
  //   setSelectedItemIndex(index);
  //   setVideoRimg(false);
  //   setAutoplayAllowed(true);
  //   handleDelayedAutoplay();
  // }, 500); // 200 milliseconds debounce delay

  const handleItemHover = debounce((event, index, eachItem) => {
    // dispatch(fetchVideoById(eachItem.Id));

    // dispatch(setMovieState(eachItem));
    setCurrentDetails(eachItem);
    // console.log('handle garu detials event:', event, index, eachItem)
    dispatch(setHoveredState(eachItem.Id));
    const slickItem = event.target.getBoundingClientRect();
    setModalPosition({
      x: slickItem.left + window.scrollX,
      y: slickItem.top + window.scrollY - 500,
      height: slickItem.height * 2.48,
      width: slickItem.width,
    });
    setSelectedItemIndex(index);
    setIsModalOpen(true);
    //setVideoRimg(false);
    //setAutoplayAllowed(true);
    //handleDelayedAutoplay();
  }, 0); // 200 milliseconds debounce delay

  // const playFromSpecificDuration = () => {
  //   if (videoRef.current) {
  //     const startTime = 22 * 60; // 22 minutes
  //     const endTime = 24 * 60; // 30 minutes
  //     videoRef.current.currentTime = startTime;
  //     videoRef.current.play();
  //     videoRef.current.addEventListener("timeupdate", function () {
  //       if (this.currentTime >= endTime) {
  //         this.pause();
  //       }
  //     });
  //   }
  // };

  // useEffect(() => {
  //   if (autoplayAllowed) {
  //     playFromSpecificDuration();
  //   }
  // }, [autoplayAllowed]);

  // const handleCloseModal = () => {
  //   setIsModalOpen(false);
  //   setSelectedItemIndex(null);
  // };

  const handleModalMouseLeave = () => {
    // Close the modal only when the cursor leaves the modal container
    setIsModalOpen(false);
    setSelectedItemIndex(null);
    // setIsCursorInsideModal(false);
    //setVideoMuted(!videoMuted);
  };

  const handleModalMouseEnter = () => {
    // Prevent the modal from closing when the cursor enters the modal container
    setIsModalOpen(true);
    // setIsCursorInsideModal(true);
    //setVideoMuted(!videoMuted);
  };

  // const leftCardHandler = () => {
  //   if (!inTheModal.current) {
  //     setIsModalOpen(false);
  //     setSelectedItemIndex(null);
  //     setVideoMuted(!videoMuted);
  //   }
  // }

  // useEffect(() => {
  //     const clearMv = setTimeout(() => {
  //         setVideoRimg(false);
  //         // console.log("Clearing");
  //         setAutoplayAllowed(true);
  //     }, 1000);

  //     return () => clearTimeout(clearMv);
  // }, []);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const modal = document.getElementById("modal");
  //     // if (modal && !isCursorInsideModal) {
  //     if (modal) {
  //       const rect = modal.getBoundingClientRect();
  //       setModalPosition({
  //         x: rect.left,
  //         y: rect.top + window.scrollY,
  //         height: rect.height,
  //         width: rect.width,
  //       });
  //     }
  //   };

  //   if (isModalOpen) {
  //     window.addEventListener("scroll", handleScroll);
  //   }

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [isModalOpen]);
  // }, [isModalOpen, isCursorInsideModal]);

  const customModalStyle = {
    width: modalPosition.width,
    minHeight: modalPosition.height,
    position: "absolute",
    top: modalPosition.y + 499,
    left: modalPosition.x,
    backgroundColor: "#D3B463",
    // padding: '20px',
    border: "2px solid #ffda78",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
    borderRadius: "7px",

    zIndex: 9999, // Ensure modal appears on top of everything
  };

  // const videoStyle = {
  //     height: modalPosition.height/2
  // }

  // if(generBannerCache.has(gener)){
  //     // console.log(`cached Data ${gener}`, generBannerCache.get(gener))
  // }

  const genere_banners = useSelector(
    (state) => state.rootReducer.bannersByGenereReducer
  )[gener.toLowerCase() + "Banners"];

  let { videoById, videoByIdFetchStatus } = useSelector(
    (state) => state.rootReducer.getVideoByIdReducer
  );

  // Use `useSelector` for each genre
  const comedyBanners = useSelector(
    (state) => state.rootReducer.bannersByGenereReducer.comedyBanners
  );
  const romanceBanners = useSelector(
    (state) => state.rootReducer.bannersByGenereReducer.romanceBanners
  );
  const actionBanners = useSelector(
    (state) => state.rootReducer.bannersByGenereReducer.actionBanners
  );
  const dramaBanners = useSelector(
    (state) => state.rootReducer.bannersByGenereReducer.dramaBanners
  );
  const devotionalBanners = useSelector(
    (state) => state.rootReducer.bannersByGenereReducer.devotionalBanners
  );
  const sciFiBanners = useSelector(
    (state) => state.rootReducer.bannersByGenereReducer.sciFiBanners
  );
  const adventureBanners = useSelector(
    (state) => state.rootReducer.bannersByGenereReducer.adventureBanners
  );
  const horrorBanners = useSelector(
    (state) => state.rootReducer.bannersByGenereReducer.horrorBanners
  );
  const thrillerBanners = useSelector(
    (state) => state.rootReducer.bannersByGenereReducer.thrillerBanners
  );
  const sportsBanners = useSelector(
    (state) => state.rootReducer.bannersByGenereReducer.sportsBanners
  );
  const familyBanners = useSelector(
    (state) => state.rootReducer.bannersByGenereReducer.familyBanners
  );

  // Organize all genres into an object
  const bannersByGenre = {
    Comedy: comedyBanners,
    Romance: romanceBanners,
    Action: actionBanners,
    Drama: dramaBanners,
    Devotional: devotionalBanners,
    "Sci-Fi": sciFiBanners,
    Adventure: adventureBanners,
    Horror: horrorBanners,
    Thriller: thrillerBanners,
    Sports: sportsBanners,
    Family: familyBanners,
  };
  //  console.log("bannersByGenre",bannersByGenre);

  // Store unique movies in state
  const [moviesData, setMoviesData] = useState({});

  useEffect(() => {
    const uniqueMoviesByGenre = {};
    for (const [genre, banners] of Object.entries(bannersByGenre)) {
      if (Array.isArray(banners)) {
        const uniqueMovies = banners.filter(
          (movie, index, self) =>
            index === self.findIndex((m) => m.MovieName === movie.MovieName)
        );
        uniqueMoviesByGenre[genre] = uniqueMovies;
      } else {
        console.error(`${genre} banners are not an array or are undefined.`);
      }
    }
    //setMoviesData(uniqueMoviesByGenre);
  }, [bannersByGenre]);

  // Fetch initial dashboard banners
  useEffect(() => {
    dispatch(fetchByGenereDashboardBanners(gener));
  }, [dispatch, gener]);

  // Select current genre's banners based on "gener"
  const allBanners = bannersByGenre[gener] || [];
  const banners = allBanners.filter(
    (banner, index, self) =>
      index === self.findIndex((b) => b.MovieName === banner.MovieName)
  );
  // console.log('banners',banners);

  const nextOrPrevArrow = () => {
    setIsModalOpen(false);
  };

  let slidesToShow = 5;

  function SampleNextArrow(props) {
    const { className, style, onClick, currentSlide, slideCount } = props;
    return (
      <>
        {currentSlide !== slideCount - slidesToShow && (
          <div
            className={className}
            style={{
              ...style,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#151829",
              height: "84%",
              width: "20px",
              fontSize: "12px",
              color: "#fff",
              borderRadius: "3px",
              marginRight: "20px !important",
            }}
            onClick={() => {
              onClick();
              nextOrPrevArrow();
            }}
          >
            <ArrowForwardIosIcon sx={{ fontSize: { xs: "8px", sm: "14px" } }} />
          </div>
        )}
      </>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick, currentSlide } = props;
    return (
      <>
        {currentSlide !== 0 && (
          <div
            className={className}
            style={{
              ...style,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#151829",
              height: "84%",
              width: "20px",
              fontSize: "12px",
              color: "#fff",
              borderRadius: "4px",
            }}
            onClick={() => {
              onClick();
              nextOrPrevArrow();
            }}
          >
            <ArrowBackIosNewIcon sx={{ fontSize: { xs: "8px", sm: "14px" } }} />
          </div>
        )}
      </>
    );
  }

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow:
      banners?.length < slidesToShow ? banners?.length : slidesToShow,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const movieDuration = currentDetails && currentDetails.Duration;

  const year = currentDetails && currentDetails.Year;

  function formatTime(timeStr) {
    const [hours, minutes] = timeStr.split(":").map(Number);

    let formattedTime = "";
    if (hours > 0) {
      formattedTime += `${hours} hrs  `;
    }
    formattedTime += ` ${minutes} min`;

    return formattedTime;
  }

  function formatYear(relesedYear) {
    const date = new Date(relesedYear);
    const year = date.getFullYear();

    return year;
  }
  const onClickMvWatchBtn = (each) => {
    dispatch(setMovieState(each));
  };

  //below is for modal watch button (Each indicates whole movie object)
  const onClickMvWatchBtnInDailog = (each) => {
    const formattedMovieName = each?.MovieName.replace(/\s+/g, "_");
    // console.log(each);
    const movieJson = {
      Category: each?.Category,
      Id: each?.Id,
      MovieName: each?.MovieName,
      MoviePoster: each?.MoviePoster,
    };
    dispatch(setMovieState(movieJson));
    navigate(`/details/${formattedMovieName}`);
  };

  return (
    <>
      {banners?.length > 0 && (
        <Grid item width={{ xs: "92%", sm: "98%" }} pl={3}>
          <Typography
            fontFamily={"Quicksand"}
            color={"#FFDA78"}
            fontWeight={700}
            fontSize={{ xs: 10, sm: 20 }}
          >
            {title}
          </Typography>
          <Grid item width={"100%"}>
            <Slider {...settings}>
              {banners?.map((each, index) => {
                "/";
                return (
                  <div key={index} className="image-container">
                    {location.pathname === "/" ? (
                      <div
                        className="movie-contianer"
                        onClick={() => onClickMvWatchBtn(each)}
                      >
                        <Link
                          to={`/details/${each?.MovieName.replace(
                            /\s+/g,
                            "_"
                          )}`}
                          onMouseEnter={(e) => handleItemHover(e, index, each)}
                        >
                          <img
                            src={each.MoviePoster}
                            className="slide-images"
                            alt={each.MovieName}
                          />
                        </Link>
                      </div>
                    ) : (
                      <div
                        className="movie-contianer"
                        // onMouseEnter={(e) =>
                        //   handleItemHoverRelatedPage(e, index, each)
                        // }
                        onClick={() => onClickMvWatchBtn(each)}
                      >
                        <Link
                          to={`/details/${each?.MovieName.replace(
                            /\s+/g,
                            "_"
                          )}`}
                        >
                          <img
                            src={each.MoviePoster}
                            className="slide-images"
                            alt={index}
                          />
                        </Link>
                      </div>
                    )}
                  </div>
                );
              })}
            </Slider>
            {/* {console.log('modal garu', hoveredMovieId, currentDetails.Id)} */}
            {isModalOpen &&
              currentDetails.Id === hoveredMovieId &&
              selectedItemIndex !== null && (
                <div
                  id="modal"
                  style={customModalStyle}
                  onMouseEnter={handleModalMouseEnter}
                  onMouseLeave={handleModalMouseLeave}
                  className="movie-layer-contianer card-hover"
                >
                  <div>
                    <div>
                      <Link
                        to={`/details/${currentDetails.MovieName.replace(
                          /\s+/g,
                          "_"
                        )}`}
                        onClick={() => onClickMvWatchBtn(currentDetails)}
                      >
                        <img
                          src={currentDetails.MoviePoster}
                          alt="hovered-item"
                          className="slide-images"
                        />
                      </Link>
                      {/* <video
                          ref={videoRef}
                          autoPlay={autoplayAllowed}
                          muted={videoMuted}
                          width="100%"
                          height="100%"
                        >
                          <source
                            src={currentDetails.Quality720}
                            type="video/mp4"
                          />
                        </video> */}
                    </div>
                    <div className="content-container">
                      <h1 className="card-movie-name">
                        {currentDetails.MovieName}
                        <span className="movie-rating">
                          IMDB {currentDetails.Imdb}
                        </span>
                      </h1>

                      <h4 className="card-movie-duration">
                        {formatYear(year)} |{" "}
                        <span>{formatTime(movieDuration)}</span>
                      </h4>

                      <p className="card-movie-desc">
                        {currentDetails.Description}
                      </p>

                      <Button
                        variant="contained"
                        className="card-button"
                        startIcon={<FaPlay className="card-btn-icon" />}
                        onClick={() =>
                          onClickMvWatchBtnInDailog(currentDetails)
                        }
                        // onClick={() => {
                        //   navigate(`/details/${currentDetails.MovieName}`);
                        // }}
                      >
                        Watch
                      </Button>

                      {/* <Tooltip
                        title={videoMuted ? "Volume Off" : "Volume On"}
                        placement="left"
                      >
                        <IconButton
                          aria-label="volumeUp"
                          onClick={handlePlayButtonClick}
                        >
                          {videoMuted ? (
                            <VolumeOff
                              style={{ fontSize: "30px", color: "#151829" }}
                            />
                          ) : (
                            <VolumeUpIcon
                              style={{ fontSize: "30px", color: "#151829" }}
                            />
                          )}
                        </IconButton>
                      </Tooltip> */}
                    </div>
                  </div>
                </div>
              )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default HomeGenerSlider;
