import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import Grid from "@mui/material/Grid";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

// import lagan from '../HomeBannerSlider/sample4.png';

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./index.css";

import { useDispatch, useSelector } from "react-redux";
import { fetchHomeDashboardBanners } from "../../Redux/reducers/dashboardBannersReducer";
import { Button, Dialog, Typography } from "@mui/material";
import { FaPlay } from "react-icons/fa";

import debounce from "lodash.debounce";
import { setMovieState } from "../../Redux/reducers/videos/getVideoByIdReducer";

import { fetchByCategoryDashboardBanners } from "../../Redux/reducers/bannersByCategoryReducer";
import { useNavigate } from "react-router-dom";

import { setHoveredState } from "../../Redux/reducers/movieHoverReducer";

const HomeCategorySlider = (props) => {
  const { category, title } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [modalPosition, setModalPosition] = useState({
    x: 0,
    y: 0,
    height: null,
    width: null,
  });

  const [currentDetails, setCurrentDetails] = useState([]);

  const hoveredMovieId = useSelector(
    (state) => state.rootReducer.movieHoverReducer
  );

  // const videoRef = useRef(null);

  // const [videoRimg, setVideoRimg] = useState(true);
  // const [autoplayAllowed, setAutoplayAllowed] = useState(false);
  // const [videoMuted, setVideoMuted] = useState(false);

  // if(category === "Recommendedmovies"){
  //     setTitle("Recommended Movies")
  // }

  // const handlePlayButtonClick = () => {
  //   // console.log("clicking");
  //   setAutoplayAllowed(true);
  //   setVideoMuted(!videoMuted);
  //   // setTooltipOpen(false);
  // };

  // const handleDelayedAutoplay = () => {
  //   setVideoRimg(false); // Hide the image
  //   setTimeout(() => {
  //     setAutoplayAllowed(true); // Allow autoplay after a delay
  //   }, 500); // Adjust the delay as needed
  // };

  const handleItemHover = debounce((event, index, eachItem) => {
    // dispatch(fetchVideoById(eachItem.Id));

    // dispatch(setMovieState(eachItem));
    setCurrentDetails(eachItem);
    // console.log('handle garu detials event:', event, index, eachItem)
    dispatch(setHoveredState(eachItem.Id));
    const slickItem = event.target.getBoundingClientRect();
    setModalPosition({
      x: slickItem.left + window.scrollX,
      y: slickItem.top + window.scrollY - 500,
      height: slickItem.height * 2.48,
      width: slickItem.width,
    });
    setSelectedItemIndex(index);
    setIsModalOpen(true);
    //setVideoRimg(false);
    //setAutoplayAllowed(true);
    //handleDelayedAutoplay();
  }, 0); // 200 milliseconds debounce delay

  // const playFromSpecificDuration = () => {
  //   if (videoRef.current) {
  //     const startTime = 22 * 60; // 22 minutes
  //     const endTime = 24 * 60; // 30 minutes
  //     videoRef.current.currentTime = startTime;
  //     videoRef.current.play();
  //     videoRef.current.addEventListener("timeupdate", function () {
  //       if (this.currentTime >= endTime) {
  //         this.pause();
  //       }
  //     });
  //   }
  // };

  // useEffect(() => {
  //   if (autoplayAllowed) {
  //     playFromSpecificDuration();
  //   }
  // }, [autoplayAllowed]);

  // const handleCloseModal = () => {
  //   setIsModalOpen(false);
  //   setSelectedItemIndex(null);
  // };

  const handleModalMouseLeave = () => {
    // Close the modal only when the cursor leaves the modal container
    setIsModalOpen(false);
    setSelectedItemIndex(null);
  };

  const handleModalMouseEnter = () => {
    // Prevent the modal from closing when the cursor enters the modal container
    setIsModalOpen(true);
    // setIsCursorInsideModal(true);
  };

  // useEffect(() => {
  //   const clearMv = setTimeout(() => {
  //     setVideoRimg(false);
  //     // console.log("Clearing");
  //     setAutoplayAllowed(true);
  //   }, 1000);

  //   return () => clearTimeout(clearMv);
  // }, []);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const modal = document.getElementById("modal");
  //     // if (modal && !isCursorInsideModal) {
  //     if (modal) {
  //       const rect = modal.getBoundingClientRect();
  //       setModalPosition({
  //         x: rect.left,
  //         y: rect.top + window.scrollY,
  //         height: rect.height,
  //       });
  //     }
  //   };

  //   if (isModalOpen) {
  //     window.addEventListener("scroll", handleScroll);
  //   }

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [isModalOpen]);
  // }, [isModalOpen, isCursorInsideModal]);

  const customModalStyle = {
    width: modalPosition.width,
    minHeight: modalPosition.height,
    position: "absolute",
    top: modalPosition.y + 499,
    left: modalPosition.x,
    backgroundColor: "#D3B463",
    // padding: '20px',
    border: "2px solid #ffda78",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
    borderRadius: "7px",

    zIndex: 9999, // Ensure modal appears on top of everything
  };

  // const videoStyle = {
  //     height: modalPosition.height/2
  // }

  // const { category_banners, categoryBannerFetchStatus } = useSelector((state) => state.rootReducer.bannersByCategoryReducer);

  // // console.log(category_banners)

  // let { videoById, videoByIdFetchStatus } = useSelector(
  //   (state) => state.rootReducer.getVideoByIdReducer
  // );
  // console.log(videoById);

  useEffect(() => {
    // console.log("calling USeEffect");
    dispatch(fetchByCategoryDashboardBanners(category));
  }, []);

  const allBanners = useSelector((state) => {
    if (category === "Trending") {
      return state.rootReducer.bannersByCategoryReducer.trendingBanners;
    } else if (category === "HighRatedMovies") {
      return state.rootReducer.bannersByCategoryReducer.highRatedMoviesBanners;
    } else if (category === "Freemovies") {
      return state.rootReducer.bannersByCategoryReducer.freeMoviesBanners;
    } else if (category === "Iottpicksforyou") {
      return state.rootReducer.bannersByCategoryReducer.iottPicksForYouBanners;
    } else if (category === "MostlyWatched") {
      return state.rootReducer.bannersByCategoryReducer.mostlyWatchedBanners;
    } else if (category === "Recommendedmovies") {
      return state.rootReducer.bannersByCategoryReducer
        .recommendedMoviesBanners;
    } else if (category === "Webseries") {
      return state.rootReducer.bannersByCategoryReducer.webSeriesBanners;
    } else if (category === "Anime") {
      return state.rootReducer.bannersByCategoryReducer.animeBanners;
    }
  });

  const banners = allBanners.filter(
    (banner, index, self) =>
      index === self.findIndex((b) => b.MovieName === banner.MovieName)
  );

  const nextOrPrevArrow = () => {
    setIsModalOpen(false);
  };

  let slidesToShow = 5;

  function SampleNextArrow(props) {
    const { className, style, onClick, currentSlide, slideCount } = props;
    return (
      <>
        {currentSlide !== slideCount - slidesToShow && (
          <div
            className={className}
            style={{
              ...style,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#151829",
              height: "84%",
              width: "20px",
              fontSize: "12px",
              color: "#fff",
              borderRadius: "3px",
              marginRight: "20px !important",
            }}
            onClick={() => {
              onClick();
              nextOrPrevArrow();
            }}
          >
            <ArrowForwardIosIcon sx={{ fontSize: { xs: "8px", sm: "14px" } }} />
          </div>
        )}
      </>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick, currentSlide } = props;
    return (
      <>
        {currentSlide !== 0 && (
          <div
            className={className}
            style={{
              ...style,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#151829",
              height: "84%",
              width: "20px",
              fontSize: "12px",
              color: "#fff",
              borderRadius: "4px",
            }}
            onClick={() => {
              onClick();
              nextOrPrevArrow();
            }}
          >
            <ArrowBackIosNewIcon sx={{ fontSize: { xs: "8px", sm: "14px" } }} />
          </div>
        )}
      </>
    );
  }

  // var settings = {
  //   dots: false,
  //   speed: 500,
  //   slidesToShow: slidesToShow,
  //   slidesToScroll: 1,
  //   autoplay: false,
  //   nextArrow: <SampleNextArrow />,
  //   prevArrow: <SamplePrevArrow />,
  //   infinite: false,
  // };

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow:
      banners?.length < slidesToShow ? banners?.length : slidesToShow,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const movieDuration = currentDetails && currentDetails.Duration;

  const year = currentDetails && currentDetails.Year;

  function formatTime(timeStr) {
    const [hours, minutes] = timeStr.split(":").map(Number);

    let formattedTime = "";
    if (hours > 0) {
      formattedTime += `${hours} hrs  `;
    }
    formattedTime += ` ${minutes} min`;

    return formattedTime;
  }

  function formatYear(relesedYear) {
    const date = new Date(relesedYear);
    const year = date.getFullYear();

    return year;
  }

  const onClickMvWatchBtn = (each) => {
    dispatch(setMovieState(each));
  };

  //below is for modal watch button (Each indicates whole movie object)
  const onClickMvWatchBtnInDailog = (each) => {
    const formattedMovieName = each?.MovieName.replace(/\s+/g, "_");
    // console.log(each);
    const movieJson = {
      Category: each?.Category,
      Id: each?.Id,
      MovieName: each?.MovieName,
      MoviePoster: each?.MoviePoster,
    };
    dispatch(setMovieState(movieJson));
    navigate(`/details/${formattedMovieName}`);
  };
  return (
    <Grid item width={{ xs: "92%", sm: "98%" }} pl={3}>
      <Typography
        fontFamily={"Quicksand"}
        color={"#FFDA78"}
        fontWeight={700}
        fontSize={{ xs: 10, sm: 20 }}
      >
        {title}
      </Typography>
      <Grid item width={"100%"}>
        <Slider {...settings}>
          {banners?.map((each, index) => {
            return (
              <div key={index} className="image-container">
                <div
                  className="movie-contianer"
                  onMouseEnter={(e) => handleItemHover(e, index, each)}
                  onClick={() => onClickMvWatchBtn(each)}
                >
                  <Link
                    to={`/details/${each?.MovieName.replace(/\s+/g, "_")}`}
                    onMouseEnter={(e) => handleItemHover(e, index, each)}
                    onClick={() => onClickMvWatchBtn(each)}
                  >
                    <img
                      src={each.MoviePoster}
                      className="slide-images"
                      alt={each.MovieName}
                    />
                  </Link>
                </div>
              </div>
            );
          })}
        </Slider>
        {isModalOpen &&
          currentDetails.Id === hoveredMovieId &&
          selectedItemIndex !== null && (
            <div
              id="modal"
              style={customModalStyle}
              onMouseEnter={handleModalMouseEnter}
              onMouseLeave={handleModalMouseLeave}
              className="movie-layer-contianer card-hover"
            >
              <div>
                <div>
                  <Link
                    to={`/details/${currentDetails.MovieName.replace(
                      /\s+/g,
                      "_"
                    )}`}
                    onClick={() => onClickMvWatchBtn(currentDetails)}
                  >
                    <img
                      src={currentDetails.MoviePoster}
                      alt="hovered-item"
                      className="slide-images"
                    />
                    {/* <video
                    ref={videoRef}
                    autoPlay={autoplayAllowed}
                    muted={videoMuted}
                    width="100%"
                    height="100%"
                  >
                    <source src={currentDetails.Quality720} type="video/mp4" />
                  </video> */}
                  </Link>
                </div>
                <div className="content-container">
                  <h1 className="card-movie-name">
                    {currentDetails.MovieName}
                    <span className="movie-rating">
                      IMDB {currentDetails.Imdb}
                    </span>
                  </h1>

                  <h4 className="card-movie-duration">
                    {formatYear(year)} |{" "}
                    <span>{formatTime(movieDuration)}</span>
                  </h4>

                  <p className="card-movie-desc">
                    {currentDetails.Description}
                  </p>

                  <Button
                    variant="contained"
                    className="card-button"
                    startIcon={<FaPlay className="card-btn-icon" />}
                    onClick={() => onClickMvWatchBtnInDailog(currentDetails)}
                    // onClick={() => {
                    //   navigate(`/details/${currentDetails.MovieName}`);
                    // }}
                  >
                    Watch
                  </Button>

                  {/* <Tooltip
                    title={videoMuted ? "Volume Off" : "Volume On"}
                    placement="left"
                  >
                    <IconButton
                      aria-label="volumeUp"
                      onClick={handlePlayButtonClick}
                    >
                      {videoMuted ? (
                        <VolumeOff
                          style={{ fontSize: "30px", color: "#151829" }}
                        />
                      ) : (
                        <VolumeUpIcon
                          style={{ fontSize: "30px", color: "#151829" }}
                        />
                      )}
                    </IconButton>
                  </Tooltip> */}
                </div>
              </div>
            </div>
          )}
      </Grid>
    </Grid>
  );
};

export default HomeCategorySlider;
