import React, { useState, useEffect } from "react";
import { Box, CircularProgress, Grid, styled, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAuthData } from "../../utils/auth";
import { fetchAllFavorites } from "../../Redux/reducers/favorite/getAllFavoritesReducer";
import { fetchClearSingleCollection } from "../../Redux/reducers/favorite/clearSingleCollectionReducer";
import { fetchClearAllCollection } from "../../Redux/reducers/favorite/clearAllCollectionReducer";
import statusCodes from "../../utils/statusCodes";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { setMovieState } from "../../Redux/reducers/videos/getVideoByIdReducer";

const UserCollections = () => {
  const authData = getAuthData();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Container = styled(Box)(({ theme }) => ({
    backgroundColor: "#F4C86F",
    borderRadius: "15px",
    padding: theme.spacing(3), 
    margin: "auto",
    border: "2px solid #fff", 
    height: '70vh',
    overflow: 'auto',
    "&::-webkit-scrollbar": {
    width: "7px", // Set your custom scrollbar width here
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#1c1c30",
    borderRadius: "2px",
  },
    [theme.breakpoints.up('xs')]: {
      width: "70vw", // or any specific value for xs
    },
    [theme.breakpoints.up('sm')]: {
      width: "500px", // change this value as needed
    },
    [theme.breakpoints.up('md')]: {
      width: "60vw", // change this value as needed
    },
    // You can add more breakpoints like 'lg' or 'xl' if needed
  }));

  // Assuming Redux state setup correctly
  const { get_all_favorites, getAllFavoritesFetchStatus } = useSelector(
    (state) => state.rootReducer.getAllFavoritesReducer
  );
  const [clear, setClear] = useState(false)
//  console.log(get_all_favorites)
  useEffect(() => {
    if (authData?.auth?.Id) {
      dispatch(fetchAllFavorites(authData?.auth?.Id));
    }
  }, [clear, dispatch, authData?.auth?.Id]);

  const handleCollectionMovie = (item) => {
    const formattedMovieName = item.MovieName.replace(/\s+/g, "_");
    // console.log(item);
    dispatch(setMovieState(item));
    navigate(`/details/${formattedMovieName}`);
  };

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');  // Split time into hours and minutes
    return `${hours}h${minutes}min`;  // Return formatted time
  };

  function extractYear(dateString) {
    const date = new Date(dateString);  // Convert the string to a Date object
    return date.getFullYear();          // Extract the year
  }

  const handleClick = (event, id) => { event.stopPropagation(); // Prevent event bubbling 
   const clearSingleCollectionJSON = { movieId: id, userId: authData?.auth?.Id, }; 
   dispatch(fetchClearSingleCollection(clearSingleCollectionJSON)) 
   .then((response) => { if (response.meta.requestStatus === "fulfilled") 
    { setClear((prevClear) => !prevClear);

     } }); 
  };

  const deleteAllByClick = () => {
    const clearSingleCollectionJSON = {userId: authData?.auth?.Id, }; 
    dispatch(fetchClearAllCollection(clearSingleCollectionJSON)).then((response) => {
      if (response.meta.requestStatus === "fulfilled") {
        setClear(!clear);
      }
    });
  };

  if (getAllFavoritesFetchStatus === statusCodes.LOADING) {
    return (
      <>
        <Header />
        <Grid
          container
          bgcolor={"#1c1c30"}
          display={"flex"}
          flexDirection={"column"}
          gap={1}
          pt={2}
          pb={2}
          minHeight={"80vh"}
        >
          <Container>
            <Grid p={2} textAlign="center">
              <Typography
                variant={{ xs: "body2", md: "h6" }}
                color={"black"}
                fontWeight={"bold"}
                fontSize={{xs: '25px', sm: '30px', md: '32px'}}
              >
                My Collections
              </Typography>
            </Grid>
          
            <Grid container justifyContent={"center"} alignItems={"center"}>
              <CircularProgress color="warning" />
            </Grid>
          </Container>
          <Footer />
        </Grid>
      </>
    );
  }

  if (getAllFavoritesFetchStatus === statusCodes.SUCCESS) {
    
    return (
      <>
        <Header />
        <Grid
          container
          bgcolor={"#1c1c30"}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={1}
          sx={{py:2, minHeight:'80vh'}}
        >
          <Container>
            <Grid p={2} textAlign="center">
              <Typography
                variant={{ xs: "body2", md: "h6" }}
                color={"black"}
                fontWeight={"bold"}
                fontSize={{xs: '25px', sm: '30px', md: '32px'}}
              >
                My Collections
              </Typography>
            </Grid>
            <Typography
            sx={{
              textDecoration: "underline",
              cursor: "pointer",
              textAlign: "center",
              width:"fit-content",
              marginLeft:"auto",
            }}
            mt={1}
            fontFamily={"Quicksand"}
            fontWeight={700}
            pb={2}
            fontSize={{ xs: '15px', sm: '18px', md: 24 }}
            onClick={event => deleteAllByClick(event)}
          >
            Remove All
          </Typography>
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"} 
            >
              <Grid
                container
                xs={11}
                spacing={{ xs: 4, sm:2 }}
              >
                {/* {console.log(get_all_favorites)} */}
                {get_all_favorites.length > 0 &&
                get_all_favorites[0]?.message === undefined ? (
                  get_all_favorites.map((item) => (
                    <Grid item xs={12} sm={6} md={4} xl={3} key={item.Id}>
                      <Box
                        sx={{ height: {sm: '250px', md: '270px', lg: '300px', xl: '350px'}, borderStyle: 'solid', borderColor: '#5C4033', cursor: "pointer", display: "flex", flexDirection: 'column', alignItems: 'center', bgcolor:'white', borderRadius: '7px 10px 10px 7px', '&:hover': { boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.8)', transform: 'scale(1.05)', }}}
                        onClick={() => handleCollectionMovie(item)}
                      >
                        <Box
                          component="img"
                          src={item.MoviePoster}
                          alt={item.MovieName}
                          loading="lazy"
                          sx={{
                            maxWidth: { xs: '100%' },
                            borderRadius: '8px',
                          }}
                        />
                        <Box sx={{flexGrow: 1,textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '90%' }}>
                          <Typography
                            variant="subtitle1"
                            sx={{ lineHeight: {md: '1.2'}, mt: {md: 1, xs: 0}, color: "black", fontSize: {xs: '15px', xl:'20px'}, fontWeight: '600' }}
                          >
                            {item.MovieName}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            color="black"
                            sx={{ color: "black", fontSize: {xs: '12px', lg: '14px', xl: '16px'}}}
                          >
                            {item.Language}
                          </Typography>
                          <Typography variant="body2" sx={{ mb: 0, color: 'black', display: 'flex', alignItems: 'center', gap: 1, marginTop: '4px', fontSize: {xs: '12px', lg: '14px', xl: '16px'} }}>
                            {item.IMDbRating} | {formatTime(item.Duration)} | {item.Certificate} | {extractYear(item.ReleasedYear)}
                          </Typography>
                          <Typography
                            sx={{
                              textDecoration: "underline",
                              cursor: "pointer",
                              fontSize: { xs: '13px', sm: '15px' },
                              my: {xs: 1}                           
                            }}
                            fontFamily={"Quicksand"}
                            fontWeight={700}
                            onClick={event => handleClick(event, item.Id)}
                            color={"#ba344d"}
                          >
                            Remove
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ))
                ) : (
                  <Grid item xs={12} p={2} display="flex" justifyContent="center" alignItems="center" style={{ height: "50vh", textAlign: "center" }}>
                    <Typography
                      fontFamily={"Quicksand"}
                      sx={{fontSize:{xs: '20px', sm: '25px'}}}
                      fontWeight={600}
                      color={"black"}
                    >
                      No movie collections found.
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Footer />
      </>
    );
  }

  if (getAllFavoritesFetchStatus === statusCodes.ERROR) {
    return (
      <>
        <Header />
        <Grid
          container
          bgcolor={"#1c1c30"}
          display={"flex"}
          flexDirection={"column"}
          gap={1}
          minHeight={500}
          justifyContent={"center"}
          alignItems={"center"}
          pt={2}
          pb={2}
        >
          <Grid item p={2} style={{ textAlign: "center", color: "black" }}>
            <Container>
              <Typography
                variant={{ xs: "body2", md: "h6" }}
                color={"black"}
                fontWeight={"bold"}
                fontSize={{xs: '25px', sm: '30px', md: '32px'}}              
              >
                My Collections
              </Typography>
              
              <Typography
                fontFamily={"Quicksand"}
                fontSize={{xs: '15px', sm: '20px', md: '25px'}}
                pt={1}
                fontWeight={600}
                color={"black"}
                sx={{mt: '25vh'}}
              >
                Failed to load collections. Please try again later.
              </Typography>
            </Container>
          </Grid>
          <Footer />
        </Grid>
      </>
    );
  }

  // Default case if none of the above conditions are met (should ideally not happen)
  return null;
};

export default UserCollections;

