import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import statusCodes from "../../../utils/statusCodes";
import endpoints from "../../../Endpoints/endpoints";
import axios from "axios";

const initialValues = {
    addWatchTimeMsg: '',
    addWatchTimeFetchStatus: statusCodes.IDLE
}

const addWatchTimeApi = endpoints.addWatchTime

const addWatchTimeReducer = createSlice({
    name: "addWatchTimeReducer",
    initialState: initialValues,
    extraReducers: builder => {
        builder
            .addCase(fetchAddWatchTime.pending, (state, action) => {
                state.addWatchTimeFetchStatus = statusCodes.LOADING
            })
            .addCase(fetchAddWatchTime.fulfilled, (state, action) => {
                state.addWatchTimeFetchStatus = statusCodes.SUCCESS
                state.addWatchTimeMsg = action.payload
                // console.log('dispatch succes', action.payload)
            } )
            .addCase(fetchAddWatchTime.rejected, (state, action) => {
                state.addWatchTimeFetchStatus = statusCodes.ERROR
            })
    }
})

export const fetchAddWatchTime = createAsyncThunk(
    "addWatchTime/post",
    async (addWatchTimeJSON) => {
        try {
            const addWatchTimeResult = await axios.post(addWatchTimeApi, addWatchTimeJSON)
            return addWatchTimeResult;
        }
        catch(error) {
            const errorPayload = {message: error.response.data}            
            throw errorPayload
        }
    }
)

export default addWatchTimeReducer.reducer