 
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../../utils/statusCodes";
import endpoints from "../../../Endpoints/endpoints";
 
const initialFavoriteById = {
  getFavoritesById: JSON.parse(localStorage.getItem('favoritesById')) || null,
  getFavoritesByMovieIdFetchStatus: statusCodes.IDLE,
};
 
const getFavoriteByMovieIdReducer = createSlice({
  name: "getFavoriteByMovieIdReducer",
  initialState: initialFavoriteById,
  reducers: {
    setFavorites(state, action) {
      state.getFavoritesById = action.payload;
      localStorage.setItem('favoritesById', JSON.stringify(action.payload));
    }},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFavoritesByMovieId.pending, (state, action) => {
        state.getFavoritesByMovieIdFetchStatus = statusCodes.LOADING;
        // console.log("get favorites by movie id data pending");
      })
      .addCase(fetchFavoritesByMovieId.fulfilled, (state, action) => {
        state.getFavoritesById = action.payload;
        state.getFavoritesByMovieIdFetchStatus = statusCodes.SUCCESS;
        localStorage.setItem('favoritesById', JSON.stringify(state.getFavoritesById));
        // console.log(state.getFavoritesById);
      })
      .addCase(fetchFavoritesByMovieId.rejected, (state, action) => {
        state.getFavoritesByMovieIdFetchStatus = statusCodes.ERROR;
        // console.log("get favorites by movie id  data error");
      });
  },
});
 
export const fetchFavoritesByMovieId = createAsyncThunk(
  "favoritesByMovieId/get",
  async (verifyPurchaseMovieJSON) => {
    try {
      const favoritesByMovieId = await axios.get(
        `${endpoints.getFavoriteById}?userid=${verifyPurchaseMovieJSON.userId}&movieId=${verifyPurchaseMovieJSON.movieId}`
      );
      return favoritesByMovieId.data;
    } catch (error) {
      const errorPayload = {
        message: error.response.data.message,
      };
      throw errorPayload;
    }
  }
);
 
export const { setFavorites } = getFavoriteByMovieIdReducer.actions;
export default getFavoriteByMovieIdReducer.reducer;
 
 