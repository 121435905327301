import {
  Box,
  Grid,
  Typography,
  TextField,
  Checkbox,
  Button,
  InputAdornment,
  IconButton,
  Modal,
  Fade,
  Backdrop,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useState, useEffect } from "react";

import "./index.css";
import { Link } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import OtpInput from "react-otp-input";
import { getAuthData } from "../../../utils/auth";

import { IoArrowForwardCircleOutline } from "react-icons/io5";
import { authLogin } from "../../../Redux/reducers/authLoginReducer";
import { authVerifyLogin } from "../../../Redux/reducers/authVerifyLoginReducer";
import { authForcedLogin } from "../../../Redux/reducers/authForcedLoginReducer";
import { fetchGetUserId } from "../../../Redux/reducers/getuserbyidReducer";
import statusCodes from "../../../utils/statusCodes";
// import Loader from "../../../components/Loader";
import { getIp } from "../../../utils/getIp";
import Profile from "../../Profile";
import { unwrapResult } from "@reduxjs/toolkit";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [showPassword, setShowPassword] = useState(false);
  const authData = getAuthData();

  const [loginWithOtp, setLoginWithOtp] = useState("");

  const [verifyOtp, setVerifyOtp] = useState("");

  const [showVerifyOtp, setShowVerifyOtp] = useState(false);

  const [otpSent, setOtpSent] = useState(false);

  const [showOtpFields, setShowOtpFields] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");

  const [sendOtp, setSendOtp] = useState("");

  const [otp, setOtp] = useState("");

  const [loginError, setLoginError] = useState("");

  const [showModal, setShowModal] = useState(false);

  const handleModalClose = () => setShowModal(false);
  const [timer, setTimer] = useState(60); // 60 seconds timer
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [showResendButton, setShowResendButton] = useState(true);
  const [sendOtpvisible, setSendOtpvisible] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [pendingLoginValues, setPendingLoginValues] = useState(null);
  const [ipAddress, setIpAddress] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // const ipAddress = localStorage.getItem("ipAddress");
  // const ipAddress = "122.175.18.116";
  // console.log("Location state:", location.state?.from); // Check if `from` exists
  // console.log("authData", authData);

  const { authVerifyDetails, authVerifyLoginStatus } = useSelector(
    (state) => state.rootReducer.authVerifyLoginReducer
  );
  // console.log("Login Verification Data:", {
  //   authVerifyDetails: authVerifyDetails || "No data",
  //   authVerifyLoginStatus: authVerifyLoginStatus || "Status not available"
  // });
  const { authDetails, authLoginStatus, userExists } = useSelector(
    (state) => state.rootReducer.authLoginReducer
  );

  // console.log("Login Data:", {authDetails, authLoginStatus, userExists});
  // console.log(pendingLoginValues);

  useEffect(() => {
    const fetchIp = async () => {
      try {
        const ip = await getIp();
        setIpAddress(ip);
        // console.log("Fetched IP Address:", ip);
      } catch (error) {
        console.error("Error:", error.message);
      }
    };

    fetchIp();
  }, []);

  const handleLoginSuccess = () => {
    setShowModal(true);

    setTimeout(() => {
      setShowModal(false);

      const previousPage = location.state?.from || "/";
      navigate(previousPage);
    }, 1000);
  };

  const sendOtpTextColor =
    sendOtp === "Otp is sent your Mobile Number" ? "#13FF13" : "red";

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const mobileRegex = /^[0-9]{10}$/;
  // const mobileRegex = /^\+\d{12}$/;

  // const handleChangePassword=
  //   (event) => {
  //     formik.handleChange(event);

  //     console.log(event.target.value);
  //   }
  // };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (ipAddress === "" || ipAddress === null || ipAddress === undefined) {
      getIp();
    }
  }, []);

  const validateSchema = Yup.object({
    email: Yup.string()
      .required("Enter a Email")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address"
      ),
    password: Yup.string().required("Enter Password").min(2),
    // .matches(
    //   /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_])/,
    //   "Password invalid"
    // ),
  });

  const onMobileNumberBlur = () => {
    if (mobileRegex.test(mobileNumber)) {
      setMobileNumberError("");
    } else if (!mobileNumber) {
      setMobileNumberError("Phone number is required");
    } else {
      setMobileNumberError("Phone number must be exactly 10 digits");
    }
  };

  const handleLoginOtp = (e) => {
    e.preventDefault();

    setSendOtp("");
    setShowVerifyOtp(true);
    setSendOtpvisible(true);
    if (!mobileNumber) {
      setVerifyOtp("Enter mobile number ");
      return;
    }
    if (!otpSent) {
      setVerifyOtp("Click on Send OTP");
      return;
    }
    if (otp.length === 0) {
      setVerifyOtp("Enter OTP");
    } else if (otp.length < 4) {
      setVerifyOtp("OTP must have 4 numbers");
    } else {
      const data = { PhoneNo: mobileNumber, OTP: otp, DeviceName: ipAddress };
      dispatch(authVerifyLogin(data))
        .then((response) => {
          if (response.meta.requestStatus === "fulfilled") {
            // console.log("OTP set Successfully");
            handleLoginSuccess();
            setSendOtpvisible(false);
            setIsButtonDisabled(true);
            localStorage.setItem(
              "loginUser",
              JSON.stringify(response.payload[0])
            );

            // console.log(response.payload[0].Id);

            const userId = response.payload[0].Id;

            dispatch(
              authForcedLogin({
                UserId: userId,
                DeviceName: ipAddress,
              })
            ).then((response) => {
              // console.log("Forced Login:", response);
              if (response.meta.requestStatus === "fulfilled") {
                handleLoginSuccess();
                setVerifyOtp(false);
                // navigate("/",);
                // navigate(from, { replace: true });
                // navigate(window.history.length > 1 ? -1 : "/");
              } else if (response.meta.requestStatus === "rejected") {
                if (
                  response.error.message ===
                  "Request failed with status code 400"
                ) {
                  setVerifyOtp("Invalid OTP");
                } else if (response.error.name === "TypeError") {
                  setVerifyOtp("Check Your Internet Connectivity");
                } else {
                  setVerifyOtp("Something Went Wrong, please try again later");
                }
              }
            });
          } else if (response.meta.requestStatus === "rejected") {
            if (
              response.error.message === "Request failed with status code 400"
            ) {
              setVerifyOtp("Invalid OTP");
            } else if (response.error.name === "TypeError") {
              setVerifyOtp("Check Your Internet Connectivity");
            } else {
              setVerifyOtp("Something Went Wrong, please try again later");
            }
          }
        })
        .catch((error) => {
          console.error("login error", error);
          // console.log("Login Error:", error);
        });
    }
  };

  const handleChangeNumber = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, "").slice(0, 10);
    setMobileNumber(value);
    setMobileNumberError("");
    setSendOtp("");
    setVerifyOtp("");

    // console.log(mobileNumber);
  };

  const onclickSendOtp = () => {
    if (mobileNumber.length === 10) {
      setShowVerifyOtp(false);
      setShowResendButton(false);

      setOtpSent(true); // Set otpSent to true when the button is clicked
      dispatch(authLogin({ PhoneNo: mobileNumber, DeviceName: ipAddress }))
        .then((response) => {
          if (response.meta.requestStatus === "fulfilled") {
            setShowOtpFields(true);
            setSendOtp("Otp is sent your Mobile Number");
            setIsTimerActive(true);
            setShowResendButton(false);
            setIsButtonDisabled(true);
            setTimer(60);
          } else if (response.meta.requestStatus === "rejected") {
            setIsButtonDisabled(false);
            if (
              response.error.message === "Request failed with status code 400"
            ) {
              setSendOtp("User not exist");
              setIsTimerActive(false);
            } else if (response.error.name === "TypeError") {
              setSendOtp("Check Your Internet Connectivity");
              setIsTimerActive(false);
            } else {
              setSendOtp("Something Went Wrong, please try again later");
              setIsTimerActive(false);
              setIsButtonDisabled(true);
            }
          }
        })
        .catch((error) => {
          console.error("Login Error:", error);
          setIsButtonDisabled(false);
        });
    } else if (mobileNumber.length === 0) {
      setMobileNumberError("Phone number is Required");
    } else {
      setMobileNumberError("Enter a valid mobile number");
    }
  };
  const handleOkClick = () => {
    setShowModal(false);
    // navigate("/"); // Redirect to the home page
    navigate("/", { replace: true });
  };
  useEffect(() => {
    let timerInterval = null;
    if (isTimerActive && timer > 0) {
      timerInterval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000); // Update timer every second
    } else if (timer === 0) {
      setIsTimerActive(false);
      setIsButtonDisabled(true);

      setShowResendButton(true); // Show the resend button after the timer ends
    }
    return () => clearInterval(timerInterval); // Clear interval on cleanup
  }, [isTimerActive, timer]);

  const [loginValues, setLoginValues] = useState({
    email: "",
    password: "",
    deviceName: "",
  });

  // const handleForceLogout = () => {
  //   console.log("forced logout",document.referrer);

  //   const loginDetails = {
  //     email: loginValues.email,
  //     password: loginValues.password,
  //     deviceName: loginValues.deviceName,
  //   };
  //   // console.log("forcedlogin data", loginDetails);
  //   // console.log(pendingLoginValues.Id) ;

  //   const forcedLoginUserDetails ={
  //   Id:40017,
  //   Name:"sm30",
  //   PhoneNo:2727272882,
  //   ProfilePic:null,
  //   DeviceName:null,
  //   Bio:null
  //   }

  //   // Dispatch forced login action

  //   dispatch(
  //     authForcedLogin({
  //       UserId: pendingLoginValues.Id,
  //       DeviceName: ipAddress,
  //     })
  //   ).then((response) => {
  //     if (response.meta.requestStatus === "fulfilled") {
  //       console.log("forcedlogout responce", response.payload.UserDetails[0].UserId);
  //       localStorage.setItem(
  //         "loginUser",
  //         JSON.stringify(response)
  //       );

  //       handleLoginSuccess()
  //       setShowLogoutModal(false);

  //       // navigate(`-1 || /`);
  //       // navigate("/");
  //     } else {
  //       setLoginError("Failed to force logout. Try again.");
  //     }
  //   });

  //   // test user login

  //   // dispatch(authLogin(loginValues)).then((response) => {
  //   //   if (response.meta.requestStatus === "fulfilled") {
  //   //     console.log("login responce", response);
  //   //     localStorage.setItem("loginUser", JSON.stringify(response.payload[0]));
  //   //     setShowLogoutModal(false);
  //   //     handleLoginSuccess();
  //   //     // console.log(response.payl
  //   //     const userId = response.payload[0].Id;
  //   //   } else {
  //   //     setLoginError("Something Went Wrong");
  //   //   }
  //   // });

  // };
  const handleForceLogout = () => {
    // console.log("forced logout", document.referrer);

    const loginDetails = {
      email: loginValues.email,
      password: loginValues.password,
      deviceName: loginValues.deviceName,
    };

    // const forcedLoginUserDetails = {
    //   Id: 40017,
    //   Name: "sm30",
    //   PhoneNo: 2727272882,
    //   ProfilePic: null,
    //   DeviceName: null,
    //   Bio: null,
    // };

    // Dispatch forced login action
    dispatch(
      authForcedLogin({
        UserId: pendingLoginValues.Id,
        DeviceName: ipAddress,
      })
    ).then((response) => {
      if (response.meta.requestStatus === "fulfilled") {
        // console.log("forcedlogout responce", response.payload.UserDetails[0]);

        localStorage.setItem(
          "loginUser",
          JSON.stringify(response.payload.UserDetails[0])
        );

        handleLoginSuccess();
        setShowLogoutModal(false);

        // navigate(`-1 || /`);
        // navigate("/");
      } else {
        setLoginError("Failed to force logout. Try again.");
      }
    });

    // Test user login (commented out but left for future use)
    // dispatch(authLogin(loginValues)).then((response) => {
    //   if (response.meta.requestStatus === "fulfilled") {
    //     console.log("login response", response);
    //     localStorage.setItem("loginUser", JSON.stringify(response.payload[0]));
    //     setShowLogoutModal(false);
    //     handleLoginSuccess();
    //     const userId = response.payload[0].Id;
    //   } else {
    //     setLoginError("Something Went Wrong");
    //   }
    // });
  };

  return (
    <>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 120000 }} // 2 minutes timeout
      >
        <Fade in={showModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: 250, sm: 400 },
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              outline: "none",
            }}
          >
            <Typography fontFamily={"Merriweather"} fontSize={18}>
              Login Successful!
            </Typography>
            <Typography
              sx={{ mt: 2 }}
              fontFamily={"Merriweather"}
              fontSize={14}
            >
              You have logged in successfully. You will be redirected shortly.
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
            ></Box>
          </Box>
        </Fade>
      </Modal>

      <Grid container className="bg-image" height={"100vh"} width={"100vw"}>
        <Box mt={1.5} ml={5}>
          <Typography
            fontFamily={"Nunito"}
            fontWeight={700}
            fontSize={"15px"}
            color={"#fff"}
            sx={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() =>
              //  navigate("/")
              navigate("/", { replace: true })
            }
          >
            Back to Home
          </Typography>
        </Box>
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            sx={{ width: { xs: "70%", sm: "30%", lg: "25%" } }}
            bgcolor={"#010113"}
            border={"5px solid #FFDA78"}
            borderRadius={6}
            p={2}
          >
            <Box
              width={"100%"}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              pt={2}
              pb={4}
            >
              <Box
                sx={{
                  display: { xs: "flex" },
                  flexDirection: {
                    xs: "row",
                    sm: "column",
                    alignItems: "center",
                  },
                }}
              >
                <img
                  src={"https://bucketblob.iott.co.in/Icons/logo.png"}
                  alt="logo"
                  className="logo"
                  onClick={() =>
                    //  navigate("/")
                    navigate("/", { replace: true })
                  }
                />
                <Typography
                  mb={1}
                  fontFamily={"Merriweather"}
                  fontWeight={900}
                  fontSize={30}
                  color={"#FFDA78"}
                >
                  LOGIN
                </Typography>
              </Box>

              {loginWithOtp ? (
                <>
                  {/* login with otp */}

                  <form onSubmit={handleLoginOtp} className="signIn-form">
                    <TextField
                      sx={{
                        mb: 1,
                        mt: 4,
                        "& .MuiInputBase-input::placeholder": {
                          color: "#FFDA78",
                          opacity: 1,
                        },
                        color: "#FFDA78",
                        "& input:-webkit-autofill": {
                          WebkitBoxShadow: "0 0 0 1000px black inset",
                          WebkitTextFillColor: "#FFDA78", // Text color for autofill
                        },
                      }}
                      // sx={{
                      //   mb: 1,
                      //   mt: 4,
                      //   "& .MuiInputBase-input::placeholder": {
                      //     color: "#FFDA78",
                      //     opacity: 1,
                      //   },
                      //   color: "#FFDA78",
                      // }}
                      placeholder="Mobile Number"
                      variant="standard"
                      onChange={handleChangeNumber}
                      value={mobileNumber}
                      onBlur={onMobileNumberBlur}
                      helperText={mobileNumberError && mobileNumberError}
                      inputProps={{ maxLength: 10 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            {!isButtonDisabled && (
                              <button
                                type="button"
                                className="send-otp"
                                onClick={onclickSendOtp}
                                sx={{ cursor: "pointer" }}
                                style={{ fontFamily: "Quicksand" }}
                              >
                                Send OTP
                              </button>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />

                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      {otpSent && !showResendButton && isTimerActive && (
                        <Typography
                          fontFamily={"Merriweather"}
                          color={"#FF0000"}
                          fontSize={13}
                        >
                          {`${timer}s`}
                        </Typography>
                      )}

                      {showResendButton && otpSent && !isTimerActive && (
                        <Typography
                          fontFamily={"Merriweather"}
                          color={"#FF0000"}
                          fontSize={13}
                          sx={{ cursor: "pointer" }}
                          onClick={onclickSendOtp}
                        >
                          Resend OTP ?
                        </Typography>
                      )}
                    </Box>

                    {showOtpFields ? (
                      <Box
                        width={"50%"}
                        mt={2}
                        mb={1}
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Typography color={"#FFDA78"} marginRight={2}>
                          OTP
                        </Typography>

                        <OtpInput
                          value={otp}
                          onChange={setOtp}
                          numInputs={4}
                          renderSeparator={<span>-</span>}
                          renderInput={(props) => <input {...props} />}
                          inputStyle={{
                            backgroundColor: "transparent",
                            border: "1px solid #FFDA78",
                            width: 24,
                            height: 24,
                            borderRadius: 4,
                            color: "#ffda78",
                          }}
                        />
                      </Box>
                    ) : (
                      ""
                    )}

                    <Box width={"50%"} mt={2} mb={3}>
                      <Button
                        fullWidth
                        type="submit"
                        sx={{
                          fontFamily: "Quicksand",
                          fontSize: "17px",
                          fontWeight: 600,
                        }}
                      >
                        Login
                      </Button>
                    </Box>
                  </form>
                </>
              ) : (
                <>
                  {/* login with email address */}
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                    }}
                    validationSchema={validateSchema}
                    onSubmit={async (values) => {
                      setIsSubmitting(true);

                      // console.log("clicked", ipAddress);

                      const loginValues = {
                        email: values.email.trim(),
                        password: values.password,
                        deviceName: ipAddress,
                      };

                      setLoginValues(loginValues);
                      // console.log("args data", values);
                      // console.log("loginValues", loginValues);

                      try {
                        // Step 1: Verify login
                        const verifyResponse = await dispatch(
                          authVerifyLogin(loginValues)
                        );
                        const verifiedData = unwrapResult(verifyResponse);
                        // console.log("Verification Response:", verifiedData);

                        // Save verification data in localStorage
                        // localStorage.setItem("verificationData", JSON.stringify(verifiedData));

                        // Check for message indicating another device login
                        const message = verifiedData[0]?.message;

                        // Proceed with login if no message
                        if (!message) {
                          // console.log("auth login",document.referrer);
                          const loginResponse = dispatch(
                            authLogin(loginValues)
                          );
                          const loginResult = unwrapResult(loginResponse);
                          // console.log("Login Response:", loginResult);

                          // Save login user data in localStorage
                          localStorage.setItem(
                            "loginUser",
                            JSON.stringify(loginResult[0])
                          );

                          // Optional: Save login flag
                          localStorage.setItem("isSignedIn", true);
                          // const loginResult = unwrapResult(loginResponse);
                          // console.log("Login Response:", loginResult);

                          // Save login user data in localStorage
                          // localStorage.setItem("loginUser", JSON.stringify(loginResult[0]));

                          // Call login success handler
                          handleLoginSuccess();
                        }
                        // Check for message indicating another device login

                        if (
                          message ===
                          "Another Device Logged in, Do you want to Logout from other Devices?"
                        ) {
                          setPendingLoginValues(verifiedData[0]);
                          setShowLogoutModal(true);
                          return;
                        }
                      } catch (error) {
                        console.error("Login Error:", error);

                        // Handle error conditions and optionally save them to localStorage
                        localStorage.setItem(
                          "loginError",
                          JSON.stringify({
                            message: error.message,
                            name: error.name,
                          })
                        );

                        if (
                          error.message ===
                          "Request failed with status code 400"
                        ) {
                          setLoginError("Invalid Credentials");
                        } else if (error.name === "TypeError") {
                          setLoginError("Check Your Internet Connectivity");
                        } else {
                          setLoginError(
                            "Something Went Wrong, please try again later"
                          );
                        }
                      } finally {
                        setIsSubmitting(false); // Re-enable the button
                      }
                    }}
                  >
                    {(formik) => {
                      return (
                        <form
                          onSubmit={formik.handleSubmit}
                          className="signIn-form"
                        >
                          <Box
                            width={"70%"}
                            mt={0.5}
                            mb={0.5}
                            display={"flex"}
                            flexDirection={"column"}
                          >
                            <TextField
                              sx={{
                                mt: 0.5,
                                width: "230px",
                                "& .MuiInputBase-input::placeholder": {
                                  color: "#FFDA78",
                                  opacity: 1,
                                },
                                color: "#FFDA78",
                                "& input:-webkit-autofill": {
                                  WebkitBoxShadow: "0 0 0 1000px black inset",
                                  WebkitTextFillColor: "#FFDA78", // Text color for autofill
                                },
                              }}
                              placeholder="Email"
                              variant="standard"
                              {...formik.getFieldProps("email")}
                              helperText={
                                formik.touched.email && formik.errors.email
                              }
                            />
                          </Box>

                          <Box
                            width={"70%"}
                            display={"flex"}
                            flexDirection={"column"}
                          >
                            <TextField
                              sx={{
                                mt: 0.5,
                                width: "230px",
                                "& .MuiInputBase-input::placeholder": {
                                  color: "#FFDA78",
                                  opacity: 1,
                                },
                                color: "#FFDA78",
                                "& input:-webkit-autofill": {
                                  WebkitBoxShadow: "0 0 0 1000px black inset",
                                  WebkitTextFillColor: "#FFDA78", // Text color for autofill
                                },
                              }}
                              placeholder="Password"
                              variant="standard"
                              {...formik.getFieldProps("password")}
                              helperText={
                                formik.touched.password &&
                                formik.errors.password
                              }
                              type={showPassword ? "text" : "password"}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {showPassword ? (
                                        <VisibilityOff
                                          style={{
                                            color: "#FFDA78",
                                            fontSize: "1.2rem",
                                          }}
                                        />
                                      ) : (
                                        <Visibility
                                          style={{
                                            color: "#FFDA78",
                                            fontSize: "1.2rem",
                                          }}
                                        />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(event) => {
                                formik.handleChange(event);
                                setLoginError("");
                              }}
                            />
                          </Box>

                          <Box
                            width={"70%"}
                            textAlign={"right"}
                            mt={0}
                            pt={0}
                            mb={2}
                          >
                            <Link
                              to="/forgot-password"
                              style={{
                                color: "#fff",
                                textDecoration: "none",
                                fontFamily: "Merriweather",
                                fontSize: "10px",
                              }}
                            >
                              {" "}
                              Forgot Password?{" "}
                            </Link>
                          </Box>

                          <Box width={"50%"} mt={1} mb={0.5}>
                            <Button
                              fullWidth
                              disabled={isSubmitting}
                              type="submit"
                              sx={{
                                fontFamily: "Quicksand",
                                fontSize: "15px",
                                fontWeight: "900",
                              }}
                            >
                              {isSubmitting ? "Loading..." : "Login"}
                            </Button>
                          </Box>

                          {loginError && (
                            <Typography
                              color="red"
                              fontFamily={"Quicksand"}
                              fontSize={14}
                              mt={1}
                              mb={2}
                            >
                              {loginError}
                            </Typography>
                          )}
                        </form>
                      );
                    }}
                  </Formik>
                </>
              )}
              {showLogoutModal && (
                <Dialog
                  open={showLogoutModal}
                  onClose={() => setShowLogoutModal(false)}
                >
                  <DialogTitle>Another Device Logged In</DialogTitle>
                  <DialogContent>
                    <Typography>
                      Another device is currently logged in. Do you want to
                      logout from other devices?
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setShowLogoutModal(false)}>
                      Cancel
                    </Button>
                    <Button onClick={handleForceLogout} color="primary">
                      Logout from Other Devices
                    </Button>
                  </DialogActions>
                </Dialog>
              )}

              {sendOtp ? (
                <Typography
                  fontFamily={"Quicksand"}
                  fontSize={14}
                  color={sendOtpTextColor}
                >
                  {sendOtp}
                </Typography>
              ) : (
                ""
              )}

              {showVerifyOtp ? (
                <Typography color={"red"} fontSize={14}>
                  {verifyOtp}
                </Typography>
              ) : (
                ""
              )}

              {loginWithOtp ? (
                ""
              ) : (
                // <Box
                //   width={"70%"}
                //   display={"flex"}
                //   justifyContent={"center"}
                //   alignItems={"center"}
                //   sx={{ cursor: "pointer" }}
                //   onClick={() => setLoginWithOtp(!loginWithOtp)}
                // >
                //   <Typography
                //     fontFamily={"Merriweather"}
                //     color={"#FFDA78"}
                //     fontSize={10}
                //     mr={0.5}
                //   >
                //     Login With Otp{" "}
                //   </Typography>
                //   <IoArrowForwardCircleOutline
                //     className="arrow-icon"
                //     color={"#FFDA78"}
                //     onClick={() => setLoginWithOtp(!loginWithOtp)}
                //   />
                // </Box>

                <Box width={"50%"} mt={1} mb={0.5}>
                  <Button
                    fullWidth
                    type="submit"
                    sx={{
                      fontFamily: "Quicksand",
                      fontSize: "15px",
                      cursor: "pointer",
                      fontWeight: "900",
                    }}
                    onClick={() => setLoginWithOtp(!loginWithOtp)}
                  >
                    Login With Otp{" "}
                  </Button>
                </Box>
              )}

              <Typography
                width={"70%"}
                textAlign={"center"}
                mt={1}
                className="info"
                fontFamily={"Quicksand"}
                fontSize={"10px"}
              >
                Not a Member ?
                <span>
                  <Link
                    to="/register"
                    style={{
                      color: "#FFDA78",
                      textDecoration: "underline",
                      fontFamily: "Merriweather",
                      fontSize: "12px",
                      marginLeft: "4px",
                      marginRight: "4px",
                      fontWeight: "900",
                    }}
                  >
                    Sign Up
                  </Link>
                </span>
                Now
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* )} */}
    </>
  );
};

export default Login;
