import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Divider } from "@mui/material";
import { styled } from "@mui/system";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { fetchWatchHistory } from "../../Redux/reducers/watchHistory/getWatchHistoryReducer";
import { useDispatch, useSelector } from "react-redux";
import { fetchClearSingleWatchHistory } from "../../Redux/reducers/watchHistory/clearSingleWatchHistoryReducer";
import { fetchClearAllWatchHistory } from "../../Redux/reducers/watchHistory/clearAllWatchHistoryReducer";
import { getAuthData } from "../../utils/auth";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
 
const useStyles = makeStyles((theme) => ({
  img: {
    borderRadius: "8px",
    cursor: "pointer",
    width: "80%", // Adjusted default width
    height: "inherit",
    [theme.breakpoints.up("sm")]: {
      width: "40%", // Adjusted width for sm screens and up
      height: "auto",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100px", // Adjusted width for xs screens and up
      height: "auto",
    },
    [theme.breakpoints.up("md")]: {
      width: "30%", // Adjusted width for xs screens and up
      height: "auto",
    }
  },
}));
 
 
// Styled components
const Container = styled(Box)(({ theme }) => ({
  backgroundColor: "#F4C86F",
  borderRadius: "15px",
  padding: theme.spacing(3), 
  height: '80vh',
  overflow: 'auto',
  "&::-webkit-scrollbar": {
    width: "7px", // Set your custom scrollbar width here
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#1c1c30",
    borderRadius: "2px",
  },
  margin: "auto",
  border: "2px solid #fff", 
  [theme.breakpoints.up('xs')]: {
    maxWidth: "70%", // or any specific value for xs
  },
  [theme.breakpoints.up('sm')]: {
    maxWidth: "500px", // change this value as needed
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: "60%", // change this value as needed
  },
  // You can add more breakpoints like 'lg' or 'xl' if needed
}));
 
 
const SectionHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(1),
  paddingBottom: theme.spacing(0.5),
  [theme.breakpoints.down('sm')]: { marginTop: theme.spacing(1), },
}));
 
const VideoItem = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(2),
}));
 
const VideoDetails = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));
 
const WatchHistory = () => {
  const authData = getAuthData();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
 
  const { categorizedWatchHistory, watchHistoryFetchStatus } = useSelector(
    (state) => state.rootReducer.getWatchHistoryReducer
  );
 
  const [clear, setClear] = useState(false);
 
  const handleClick = (event, id) => {
    event.stopPropagation()
    const clearWatchHistoryJSON = {
      movieId: id,
      userId: authData?.auth?.Id,
    };
    dispatch(fetchClearSingleWatchHistory(clearWatchHistoryJSON))
      .then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          setClear(!clear);
        }
      });
  };
 
  const deleteClick = () => {
    dispatch(fetchClearAllWatchHistory(authData?.auth?.Id)).then((response) => {
      if (response.meta.requestStatus === "fulfilled") {
        setClear(!clear);
      }
    });
  };
 
  useEffect(() => {
    dispatch(fetchWatchHistory(authData?.auth?.Id));
  }, [clear, dispatch, authData?.auth?.Id]);
 
  const handleWatchHistoryMovie = (item) => {
    const formattedMovieName = item.MovieName.replace(/\s+/g, "_");
    navigate(`/details/${formattedMovieName}`, { state: item.Id });
  };
 
  return (
    <>
      <Header />
      <Grid py={5} bgcolor={"#010113"} minHeight="80vh">
        <Container>
          <Box textAlign={"center"} pb={1}>
            <Typography
              fontFamily={"Quicksand"}
              fontWeight={700}
              fontSize={{xs: '25px', sm: '30px', md: '32px'}}
            >
              Watch History
            </Typography>
          </Box>
       <Typography
            sx={{
              textDecoration: "underline",
              cursor: "pointer",
              textAlign: "center",
              width:"fit-content",
              marginLeft:"auto",
           
            }}
            mt={1}
            fontFamily={"Quicksand"}
            fontWeight={700}
            fontSize={{ xs: '15px', sm: '18px', md: 24 }}
            onClick={deleteClick}
          >
            Remove All
          </Typography>
          {watchHistoryFetchStatus === "loading" && (
            <Typography mt="40vh" textAlign="center" fontFamily={"Quicksand"} fontWeight={600} fontSize={{xs:'13px',sm:'17px'}} color={"#000"}>
              Loading ....
            </Typography>
          )}
          {watchHistoryFetchStatus === "success" && Object.keys(categorizedWatchHistory).length > 0 ? (
            Object.keys(categorizedWatchHistory).map((category, index) => (
              <React.Fragment key={index}>
                <SectionHeader>
                  <Typography
                    fontFamily={"Quicksand"}
                    fontWeight={700}
                    fontSize={{ xs: '12px', sm: '15px', md: 21 }}
                  >
                    {category}
                  </Typography>
                </SectionHeader>
                <Divider  sx={{ border: "1px solid #808080", mb: 2}}/>
                {/* {console.log('watch history')}
                {console.log(categorizedWatchHistory)} */}
                
                {categorizedWatchHistory[category].length > 0 ? (
                  <Grid
                  container
                  spacing={{ xs: 4, sm:2 }}
                  sx = {{marginTop:5}}
                  alignItems= "center"
                  justifyContent="flex-start"
                >
                  {categorizedWatchHistory[category].map((video, index) => (               
                      <Grid item xs={12} sm={6} xl={4} key={index}>
                        <Box
                          sx={{ borderStyle: 'solid', borderColor: '#5C4033', height: {sm: '220px', md: '300px', lg: '150px'}, cursor: "pointer", display: "flex", flexDirection: {xs: 'column', lg: 'row'}, bgcolor:'white', borderRadius: '7px 10px 10px 7px', '&:hover': { boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.8)', transform: 'scale(1.05)', }}}
                          onClick={() => handleWatchHistoryMovie(video)}
                        >
                          <Box
                            component="img"
                            src={video.MoviePoster}
                            alt={video.MovieName}
                            loading="lazy"
                            sx={{
                              maxWidth: { xs: '100%', lg: '50%' },
                              borderRadius: '8px',
                            }}
                          />
                          <Box sx={{width: '90%', flexGrow: 1, textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: {xs:'100%', lg:'50%' }}}>
                            <Typography
                              variant="subtitle1"
                              sx={{ px: {xs:'5px', lg: 1}, lineHeight: {md: '1.2'}, mt: {md: 1, xs: 0}, color: "black", fontSize: {xs: '15px', xl:'18px'}, fontWeight: '600' }}
                            >
                              {video.MovieName}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              color="black"
                              sx={{ color: "black", fontSize: {xs: '12px', lg: '14px', xl: '16px'}}}
                            >
                              {video.Language}
                            </Typography>
                            <Typography
                            sx={{
                              textDecoration: "underline",
                              cursor: "pointer",
                              fontSize: { xs: '13px', sm: '15px' },
                              pb: 1,                         
                            }}
                            fontFamily={"Quicksand"}
                            fontWeight={700}
                            onClick={event => handleClick(event, video.Id)}
                            color={"#ba344d"}
                          >
                            Remove
                          </Typography>
                          </Box>
                        </Box>
                      </Grid>      
                              
                 ))}
                 </Grid>
                ) : (
                  <Typography
                    textAlign={"center"}
                    fontFamily={"Quicksand"}
                    fontWeight={600}
                    fontSize={25}
                    color={"#000"}
                  >
                    Currently You Have No Watch History
                  </Typography>
                )}
              </React.Fragment>
            ))
          ) : (
            watchHistoryFetchStatus === "success" && (
              <Grid item xs={12} p={2} display="flex" justifyContent="center" alignItems="center" style={{ height: "50vh", textAlign: "center" }}>
                <Typography
                  textAlign={"center"}
                  fontFamily={"Quicksand"}
                  fontWeight={600}
                  fontSize={25}
                  color={"#000"}
                >
                  Currently You Have No Watch History
                </Typography>
              </Grid>
            )
          )}
 
          {watchHistoryFetchStatus === "error" && (
            <Typography
              textAlign={"center"}
              fontFamily={"Quicksand"}
              fontWeight={600}
              fontSize={25}
              color={"#000"}
              marginTop="20vh"
              sx={{fontSize:{xs: '20px', sm: '25px'}}}
            >
              Something Went wrong..! Try again.
            </Typography>
          )}
         
          {/* <Divider /> */}
 
        </Container>
      </Grid>
      <Footer />
    </>
  );
};
 
export default WatchHistory;
 
 