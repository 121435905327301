import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../../utils/statusCodes";
import endpoints from "../../../Endpoints/endpoints"; 

const getPurchaseHistoryApi = `${endpoints.getPurchaseHistory}?userId=1068`

const initial_purchaseHistory = { purchase_history: [], purchaseHistoryFetchStatus: statusCodes.IDLE };

const purchaseHistoryReducer = createSlice({
  name: "purchaseHistoryReducer",
  initialState: initial_purchaseHistory,
  extraReducers: (builder) => {
    builder
      .addCase(fetchPurchaseHistory.pending, (state, action) => {
        state.purchaseHistoryFetchStatus = statusCodes.LOADING;
        // console.log("purchase History Data");
      })
      .addCase(fetchPurchaseHistory.fulfilled, (state, action) => {
        state.purchase_history = action.payload;
        state.purchaseHistoryFetchStatus = statusCodes.SUCCESS;
        // console.log(state.purchase_history);
      })
      .addCase(fetchPurchaseHistory.rejected, (state, action) => {
        state.purchaseHistoryFetchStatus = statusCodes.ERROR;
        // console.log("purchase History Data error");
      });
  },
});


export const fetchPurchaseHistory = createAsyncThunk("purchaseHistory/get", async () => {

 
  try {
    const purchaseHistory = await axios.get(getPurchaseHistoryApi);
    return purchaseHistory.data;
  } catch (error) {
    const errorPayload = {
      message: error.response.data.message,
    };
    throw errorPayload;
  }


});

export default purchaseHistoryReducer.reducer;
