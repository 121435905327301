import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../utils/statusCodes";
import endpoints from "../../Endpoints/endpoints";

const getAllCategoryApi = endpoints.getAllCategory;

const initial_banners = {
  allCategory_banners: [],
  allCategoryBannerFetchStatus: statusCodes.IDLE,
};

const bannersByAllCategoryReducer = createSlice({
  name: "bannersByAllCategoryReducer",
  initialState: initial_banners,
  extraReducers: (builder) => {
    builder
      .addCase(fetchByAllCategoryDashboardBanners.pending, (state, action) => {
        state.allCategoryBannerFetchStatus = statusCodes.LOADING;
        // console.log("dashboard banners by all category data pending");
      })
      .addCase(
        fetchByAllCategoryDashboardBanners.fulfilled,
        (state, action) => {
          // console.log(action.payload.data);
          state.allCategory_banners = action.payload;
          state.allCategoryBannerFetchStatus = statusCodes.SUCCESS;
          // console.log(state.allCategory_banners);
        }
      )
      .addCase(fetchByAllCategoryDashboardBanners.rejected, (state, action) => {
        state.allCategoryBannerFetchStatus = statusCodes.ERROR;
        // console.log("dashboard banners by all category  data error");
      });
  },
});

export const fetchByAllCategoryDashboardBanners = createAsyncThunk(
  "homeDashboardAllCategoryBanners/get",
  async () => {
    try {
      const banners = await axios.get(getAllCategoryApi);
      return banners.data;
    } catch (error) {
      const errorPayload = {
        message: error.response.data.message,
      };
      throw errorPayload;
    }
  }
);

export default bannersByAllCategoryReducer.reducer;
