import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import endpoints from "../../../Endpoints/endpoints";

const subscriptionCheckApi = `${endpoints.subscriptionCheck}?userId=`;

const initialState = {
  subscribeddata: [],  
  checkIsSubscriptionStatus: "",
  errorMessage: null,
  loading: false,
};

export const subscriptionCheckFunction = createAsyncThunk(
  "subscriptionCheck/subscriptionCheckFunction",
  async (userId) => {
    const watchHistory = await axios.get(`${subscriptionCheckApi}${userId}`);
    return watchHistory.data;
  }
);

const checkSubscriptionReducer = createSlice({
  name: "checkSubscriptionReducer",
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(subscriptionCheckFunction.pending, (state) => {
        state.loading = true;
      })
      .addCase(subscriptionCheckFunction.fulfilled, (state, action) => {
        state.loading = false;
        const responseData = action.payload;
        // console.log("API Response Data:", responseData); 

        if (Array.isArray(responseData)) {
          state.subscribeddata = responseData;  
          state.checkIsSubscriptionStatus = "User is subscribed";  
        } else {
          state.subscribeddata = []; 
          state.checkIsSubscriptionStatus = "No subscription data found.";
        }
      })
      .addCase(subscriptionCheckFunction.rejected, (state, action) => {
        state.loading = false;
        state.checkIsSubscriptionStatus = "failed";
        state.errorMessage = action.payload || "An error occurred";
      });
  },
});

export default checkSubscriptionReducer.reducer;
