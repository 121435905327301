import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useState, useEffect } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import { auth } from "../../../utils/firebaseConfig";
import { confirmPasswordReset } from "firebase/auth";
import Loader from "../../../components/Loader";
import endpoints from "../../../Endpoints/endpoints";
import "./index.css";

function useQuery() {
  const location = useLocation();
  return new URLSearchParams(location.search);
}

const ResetPassword = () => {
  const query = useQuery();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [passwordReset, setPasswordReset] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => event.preventDefault();

  // Send verification email on component mount
  useEffect(() => {
    let userEmail = localStorage.getItem("userEmail");
    if (userEmail) {
      const object = { Email: userEmail };
      axios.post(endpoints.RegisterEmailVerification, object).catch((e) => {
        console.error("Email verification error:", e.message);
      });
    }
  }, []);

  // Validation Schema
  const validateSchema = Yup.object({
    email: Yup.string()
      .required("Enter an Email")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address"
      ),
    password: Yup.string()
      .required("Enter Password")
      .min(8, "Password must be at least 8 characters long")
      .matches(
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_])/,
        "Password must contain uppercase, lowercase, number, and special character."
      ),
    confirmPassword: Yup.string()
      .required("Confirm your Password")
      .oneOf([Yup.ref("password")], "Passwords must match"),
  });

  return (
    <Grid container className="bg-image" height={"100vh"} width={"100vw"}>
      <Grid
        item
        xs={12}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box
          sx={{ width: { xs: "70%", sm: "30%", lg: "25%" } }}
          minHeight={"50vh"}
          bgcolor={"#010113"}
          border={"5px solid #FFDA78"}
          borderRadius={6}
        >
          <Box
            width={"100%"}
            height={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            pt={2}
            pb={4}
          >
            <img
              src={"https://bucketblob.iott.co.in/Icons/logo.png"}
              alt="logo"
              className="logo"
              onClick={() => navigate("/")}
            />

            <Typography
              mb={2}
              mt={2}
              fontWeight={600}
              fontSize={20}
              color={"#FFDA78"}
              fontFamily={"Merriweather"}
            >
              RESET PASSWORD
            </Typography>

            <Formik
              initialValues={{ email: "", password: "", confirmPassword: "" }}
              validationSchema={validateSchema}
              onSubmit={async (values) => {
                setLoading(true);
                setPasswordReset(false);

                try {
                  // Reset Password using Firebase
                  const oobCode = query.get("oobCode");
                  if (!oobCode)
                    throw new Error("Invalid or missing reset code.");

                  await confirmPasswordReset(oobCode, values.password);

                  // Save password in backend
                  const object = {
                    Email: values.email,
                    Password: values.password,
                  };
                  await axios.post(endpoints.PasswordSave, object);

                  setPasswordReset("Password Reset Successful");
                } catch (e) {
                  console.error(e.message);
                  setPasswordReset(e.message || "Something went wrong.");
                } finally {
                  setLoading(false);
                }
              }}
            >
              {(formik) => (
                <form
                  onSubmit={formik.handleSubmit}
                  className="resetPassword-form"
                >
                  {/* Email Field */}
                  <TextField
                    sx={{
                      mb: 2,
                    
                      "& .MuiInputBase-input::placeholder": {
                        color: "#FFDA78",
                        opacity: 1,
                      },
                      "& input:-webkit-autofill": {
                        WebkitBoxShadow: "0 0 0 1000px black inset",
                        WebkitTextFillColor: "#FFDA78", // Text color for autofill
                      },
                        "& .MuiInputBase-root": {
      borderBottom: "1px solid #fff", // Bottom border color
    },
                    }}
                    fullWidth
                    placeholder="Enter Email"
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    {...formik.getFieldProps("email")}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />

                  {/* Password Field */}
                  <TextField
                    sx={{
                      mb: 2,
                      "& .MuiInputBase-input::placeholder": {
                        color: "#FFDA78",
                        opacity: 1,
                      },
                      "& input:-webkit-autofill": {
                        WebkitBoxShadow: "0 0 0 1000px black inset",
                        WebkitTextFillColor: "#FFDA78", // Text color for autofill
                      },
                      "& .MuiInputBase-root": {
                        borderBottom: "1px solid #fff", // Bottom border color
                      },
                    }}
                    fullWidth
                    placeholder="Enter New Password"
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? (
                              <VisibilityOff
                                style={{
                                  color: "#FFDA78",
                                  fontSize: "1.2rem",
                                }}
                              />
                            ) : (
                              <Visibility
                                style={{
                                  color: "#FFDA78",
                                  fontSize: "1.2rem",
                                }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    {...formik.getFieldProps("password")}
                    type={showPassword ? "text" : "password"}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />

                  {/* Confirm Password Field */}
                  <TextField
                    sx={{
                      mb: 2,
                      "& .MuiInputBase-input::placeholder": {
                        color: "#FFDA78",
                        opacity: 1,
                      },
                      "& input:-webkit-autofill": {
                        WebkitBoxShadow: "0 0 0 1000px black inset",
                        WebkitTextFillColor: "#FFDA78", // Text color for autofill
                      },
                      "& .MuiInputBase-root": {
                        borderBottom: "1px solid #fff", // Bottom border color
                      },
                    }}
                    fullWidth
                    placeholder="Confirm New Password"
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff
                                style={{
                                  color: "#FFDA78",
                                  fontSize: "1.2rem",
                                }}
                              />
                            ) : (
                              <Visibility
                                style={{
                                  color: "#FFDA78",
                                  fontSize: "1.2rem",
                                }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    {...formik.getFieldProps("confirmPassword")}
                    type={showConfirmPassword ? "text" : "password"}
                    error={
                      formik.touched.confirmPassword &&
                      Boolean(formik.errors.confirmPassword)
                    }
                    helperText={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                  />

                  <Box alignSelf={"center"} width={"75%"} mt={5} mb={3}>
                    <Button
                      fullWidth
                      type="submit"
                      disabled={loading}
                      variant="contained"
                      sx={{
                        fontWeight: 900,
                        backgroundColor: "#FFDA78",
                        color: "#010113",
                        "&:hover": { backgroundColor: "#FFC947" },
                      }}
                    >
                      {loading ? "Submitting..." : "Submit"}
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>

            {/* Status Message */}
            {passwordReset && (
              <>
                <Typography
                  color={
                    passwordReset.includes("Successful") ? "#13FF13" : "red"
                  }
                >
                  {passwordReset}
                </Typography>
                {passwordReset.includes("Successful") && (
                  <Link
                    to="/login"
                    style={{
                      color: "#FFDA78",
                      textDecoration: "underline",
                      fontFamily: "Quicksand",
                      fontSize: "12px",
                    }}
                  >
                    Login
                  </Link>
                )}
              </>
            )}

            {/* Loader */}
            {loading && <Loader message={"Wait a moment..."} />}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
