import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../../utils/statusCodes"; 
import endpoints from "../../../Endpoints/endpoints"; 

const addPurchaseMovieApi = endpoints.addPurchaseMovie

const initial_purchaseMovie = {
  purchase_movie: {},
  purchaseMovieFetchStatus: statusCodes.IDLE,
  
};

export const fetchPurchaseMovie = createAsyncThunk(
  "purchaseMovie/post",
  async (purchaseMovieJSON) => {
    try {
      const purchaseMovie = await axios.post(
        addPurchaseMovieApi,
        purchaseMovieJSON
      );
      return purchaseMovie.data;
    } catch (error) {
      const errorPayload = {
        message: error.response.data,
      };
      // console.log(error.response.data);
      throw errorPayload; // Throwing the error payload
    }
  }
);

const purchaseMovieReducer = createSlice({
  name: "purchaseMovieReducer",
  initialState: initial_purchaseMovie,
  extraReducers: (builder) => {
    builder
      .addCase(fetchPurchaseMovie.pending, (state, action) => {
        state.purchaseMovieFetchStatus = statusCodes.LOADING;
       })
      .addCase(fetchPurchaseMovie.fulfilled, (state, action) => {
        state.purchase_movie = action.payload;
        state.purchaseMovieFetchStatus = statusCodes.SUCCESS;
       })
      .addCase(fetchPurchaseMovie.rejected, (state, action) => {
         state.purchaseMovieFetchStatus = statusCodes.ERROR;
       });
  },
});

export default purchaseMovieReducer.reducer;


 