import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  MenuItem,
  Select,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import "./index.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getAuthData } from "../../utils/auth";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { getAllLanguageFunction } from "../../Redux/reducers/subscription/getAllLanguageReducer";
import { subscriptionCheckFunction } from "../../Redux/reducers/subscription/checkSubscriptionReducer";
import getWalletAmountReducer, {
  fetchWalletAmount,
} from "../../Redux/reducers/wallet/getWalletAmountReducer";
import { fetchSubscriptions } from "../../Redux/reducers/mySubscriptionsReducer";
import { subscribeUser } from "../../Redux/reducers/subscription/subscribeUserReducer";
import { purchaseSubscriptionPlan } from "../../Redux/reducers/subscription/purchaseSubscriptionReducer";
import { PaletteRounded } from "@material-ui/icons";
import endpoints from "../../Endpoints/endpoints";
// import { red } from "@mui/material/colors";

export const SubscriptionCard = ({
  plan,
  checkWalletAmount,
  purchaseStatus,
  bouns_amount,
  handleNavigateWallet,
  planCost
}) => {
  const authData = getAuthData();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [dialogOpen, setDialogOpen] = useState(false);


  const handleClickToOpen = () => {

    let user = authData?.auth ? authData?.auth?.Id : null;
    if (user === null || user === undefined) {
      sessionStorage.setItem('redirectAfterLogin', window.location.href);

      localStorage.setItem('isSignIn', false);

      localStorage.setItem('lastPath', window.location.pathname);
      navigate("/Login");
    } else{
      setDialogOpen(true);

    }

  };

  const handleToClose = () => {
    setDialogOpen(false);
  };
  // const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const planId = plan?.Id;

  const { wallet_amount } = useSelector(
    (state) => state.rootReducer.getWalletAmountReducer
  );

  useEffect(() => {
    dispatch(subscriptionCheckFunction(authData?.auth?.Id));
  }, [plan, purchaseStatus, authData?.auth?.Id]);

  const checkSubscriptionStatus = (plan) => {
    let user = authData?.auth ? authData?.auth?.Id : null;
    handleToClose();
    if (user === null || user === undefined) {
      sessionStorage.setItem('redirectAfterLogin', window.location.href);

      localStorage.setItem('isSignIn', false);

      localStorage.setItem('lastPath', window.location.pathname);
      navigate("/Login");
    } else {
      checkWalletAmount(plan);
    }
  };
  

  return (
    <Card
      sx={{
        backgroundColor: "#fff",
        color: "black",
        borderRadius: 2,
        maxHeight: 420,
        width: { xs: "100%" },
      }}
      className="subscription-card"
    >
      <Box
        borderRadius={1}
        bgcolor={"#FFDA78"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        margin={"auto"}
        height={40}
        flexDirection={"row"}
        mb={3}
        sx={{ width: { xs: "50%" } }}
      >
        <Typography gutterBottom fontFamily={"Quicksand"} fontWeight={700}>
          {plan?.SubscriptionPlan}
        </Typography>
      </Box>
      <CardContent>
        <Box display={"flex"} justifyContent={"center"}>
          <Typography
            variant="h4"
            gutterBottom
            fontFamily={"Quicksand"}
            fontWeight={700}
          >
            ₹ {plan?.Cost}{" "}
            <span
              style={{
                textDecoration: "line-through",
                fontFamily: "Quicksand",
                fontSize: "0.45em",
                fontWeight: 500,
              }}
            >
              ₹ {plan?.ActualCost}
            </span>
          </Typography>
        </Box>
        <Typography
          variant="body2"
          gutterBottom
          fontFamily="Quicksand"
          fontWeight={600}
          textAlign={"center"}
          width={{ xs: "100%", sm: "auto" }}
          boxShadow={"content-box"}
        >
          {/* <li style={{ textAlign: "center", padding: 1 }}> */}
          {plan?.Description}
          {/* </li> */}
        </Typography>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
        >
          <Button
            variant="contained"
            onClick={ handleClickToOpen
              // () => checkSubscriptionStatus(plan) //setShowConfirmDialog(true)
            }
            sx={{
              mt: 2,
              fontSize: { xs: 13, sm: 13 },
              fontFamily: "Quicksand",
              fontWeight: 800,
              bgcolor: "#FFDA78",
              color: "#000000",
              "&:hover": {
                backgroundColor: "red",
              },
              borderRadius: 1,
            }}
          >
            Get Subscription
          </Button>
        </Box>
      </CardContent>
      <Dialog
          open={dialogOpen}
          onClose={handleToClose}
          maxWidth={{ xs: "sm", sm: "md" }}
        >
          <DialogContent>
            <Box p={3}>
              <Typography
                fontFamily={"Quicksand"}
                fontWeight={700}
                fontSize={23}
                gutterBottom
              >
                {wallet_amount >= plan?.Cost?"Click on Proceed to Purchace Plan":"Insufficient Balance in your Wallet!"}
              </Typography>
                 
              <Grid
                container
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "column",
                  backgroundColor: "#FFDA78",
                  padding: "20px 10px",
                  borderRadius: "10px",
                  // justifyContent:'center',
                  // alignItems:'center'
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Quicksand",
                    fontWeight: 600,
                    fontSize: 20,
                    textAlign: "center",
                    padding: "5px ",
                    marginBottom: "16px",
                    backgroundColor: "#ffff",
                    borderRadius: "10px",
                  }}
                >
                  Wallet Balance: {wallet_amount ? wallet_amount : 0}
                </Typography>

                <Typography
                  sx={{
                    fontFamily: "Quicksand",
                    fontWeight: 600,
                    fontSize: 20,
                    textAlign: "center",
                    padding: "5px",
                    marginBottom: "16px",
                    backgroundColor: "#ffff",
                    borderRadius: "10px",
                  }}
                >
                  Bonus Balance: {bouns_amount ? bouns_amount.BonusAmount : 0}
                </Typography>
              </Grid>
              <Grid container justifyContent="space-around" mt={2}>
                <Grid item>
                  {wallet_amount >= plan?.Cost? (<Button
                    onClick={()=>checkSubscriptionStatus(plan)}
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: 13,
                      borderRadius: 2,
                      fontWeight: 600,
                      fontFamily: "Quicksand",
                    }}
                    autoFocus
                  >
                    Proceed
                  </Button>):(<Button
                    onClick={handleNavigateWallet}
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: 13,
                      borderRadius: 2,
                      fontWeight: 600,
                      fontFamily: "Quicksand",
                    }}
                    autoFocus
                  >
                    + Add Money
                  </Button>)}
                </Grid>
                <Grid item>
                  <Button
                    onClick={handleToClose}
                    variant="outlined"
                    color="secondary"
                    sx={{
                      fontSize: 13,
                      borderRadius: 2,
                      fontWeight: 600,
                      fontFamily: "Quicksand",
                    }}
                    autoFocus
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </Dialog>

    </Card>
  );
};

//const ccav = require("../Wallet/ccavutil");

const Subscription = () => {
  // window.scrollTo(0, 0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authData = getAuthData();
  // const location = useLocation();

  localStorage.setItem('lastPath', window.location.pathname);

  const [language, setLanguage] = useState("Telugu");
  const [loading, setLoading] = useState(true);

  // const [OrderId, setOrderId] = useState("");
  const [singleLanguagePlans, setsingleLanguagePlans] = useState([]);
  const [allLanguagePlans, setallLanguagePlans] = useState([]);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [purchaseStatus, setPurchaseStatus] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [planCost, setPlanCost]=useState(0);

  // this is for useEffect only
  const [clicking, updateClicking] = useState(false);

  const { allLanguage } = useSelector(
    (state) => state.rootReducer.getAllLanguageReducer
  );

  const { wallet_amount } = useSelector(
    (state) => state.rootReducer.getWalletAmountReducer
  );

  const { bouns_amount } = useSelector(
    (state) => state.rootReducer.getBounsAmountReducer
  );

  // useEffect(() => {
  //   fetchPlans();

  //   if (allLanguage.length < 1) {
  //     dispatch(getAllLanguageFunction());
  //   }
  // }, [language]);

  const { subscriptions } = useSelector(
    (state) => state.rootReducer.subscriptionsReducer
  );

  // console.log(subscriptions);

  const { status } = useSelector(
    (state) => state.rootReducer.subscribeUserReducer
  );
  // // console.log(status);

  const { subscribeddata, checkIsSubscriptionStatus } = useSelector(
    (state) => state.rootReducer.subscriptionCheckFunction
  );

  const { presentpurchaseStatus } = useSelector(
    (state) => state.rootReducer.purchaseSubscriptionReducer
  );

  useEffect(() => {
    dispatch(subscriptionCheckFunction(authData?.auth?.Id));
  }, [purchaseStatus, authData?.auth?.Id]);

  function handleNavigateWallet() {
    navigate("/wallet");
  }
  // console.log(subscribeddata);

  useEffect(() => {
    fetchPlans();
    if (allLanguage.length < 1) {
      dispatch(getAllLanguageFunction());
    }
  }, [language]);

  useEffect(() => {
    dispatch(fetchWalletAmount(authData?.auth?.Id));
    dispatch(fetchSubscriptions(authData?.auth?.Id));
  }, [purchaseStatus]);

  const handleChange = (event) => {
    setLanguage(event.target.value);
  };

  const handleClickToOpen = () => {
    setDialogOpen(true);
  };

  const handleToClose = () => {
    setDialogOpen(false);
  };

  
  const handlePurchasePlan = async (plan) => {
    const userDetails = {
      userId: authData?.auth?.Id,
      planId: plan.Id,
      Plan: plan.SubscriptionPlan,
      WalletAmount: wallet_amount,
      BonusAmount: 0,
    };

    const purchaseDetails = {
      userId: authData?.auth?.Id,
      PurchaseAmount: parseInt(plan.Cost),
      SubscriptionId: plan.Id,
    };

    // console.log(purchaseDetails);
    // console.log(userDetails);
    // console.log(plan.SubscriptionPlan);
    // console.log(presentpurchaseStatus);


    try {
      // console.log("Attempting to dispatch purchaseSubscriptionPlan with data:", {
      //   userId: authData?.auth?.Id,
      //   PurchaseAmount: parseInt(plan.Cost),
      //   SubscriptionId: plan.Id,
      // });
      await dispatch(subscribeUser(userDetails)).unwrap();
      await dispatch(purchaseSubscriptionPlan(purchaseDetails)).unwrap();
      // dispatch(fetchWalletAmount(authData?.auth?.Id));

      // Fetch updated subscriptions after successful purchase
      // dispatch(fetchSubscriptions(userDetails.userId));

      // Update the purchase status message
      // console.log("Subscription purchased successfully");
      setPurchaseStatus("Subscription Purchased successfully");
      // navigate('my_subscriptions', { state: { checkWalletAmount } });

      // navigate('my_subscriptions')
    } catch (error) {
      console.error("Error purchasing subscription:", error);
      setPurchaseStatus("Failed to purchase subscription");
    }
  };

  const checkWalletAmount = (plan, subscriptions) => {
    // console.log(Number(plan.Cost), wallet_amount);
    setPlanCost(Number(plan.Cost));
    const totalAmount = wallet_amount;


    // console.log(plan);
    // console.log("Subscribed Data:", subscribeddata);
    // console.log("Subscription Status:", checkIsSubscriptionStatus);
    // console.log("Subscription ID:", subscriptions?.SubscriptionId);

    let isSubscribedValid = false;

    // Check if user is already subscribed to the current plan
    if (checkIsSubscriptionStatus !== "User is not subscribed.") {
      const isSubscribedLanguage = Array.isArray(subscribeddata) && subscribeddata.some((subscription) => {
        // console.log(subscription.Language, subscription.SubscriptionId);
        const isLanguageMatch =
          subscription.Language === plan.Language &&
          subscription.SubscriptionId === plan.Id;
        const isActive = subscription.IsSubscribed;
        return isLanguageMatch && isActive;
      });

      isSubscribedValid = isSubscribedLanguage;
    }

    // If already subscribed, show error dialog and stop further actions
    if (isSubscribedValid) {
      // console.log(isSubscribedValid);
      setOpenErrorDialog(true);
      setPurchaseStatus("Subscribed Already");
      return; // Stop further execution
    }
      // if (!isSubscribedValid){
      //   handleClickToOpen();
      // }
    // If wallet amount is sufficient, proceed with subscription
    if (totalAmount >= plan.Cost) {
      setOpenErrorDialog(true);
      setPurchaseStatus("Subscribing to Plan... Wait a moment !");
      handlePurchasePlan(plan);
    } else {
      handleClickToOpen();
      setOpenErrorDialog(false);
    }
  };

//   const handleProceed=(plan)=>{

//     setOpenErrorDialog(true);
//     setPurchaseStatus("Subscribing to Plan... Wait a moment !");
//     handlePurchasePlan(plan);
//     handleToClose()
// }


  const fetchPlans = async () => {
    try {
      const responseSingle = await axios.get(
        `${endpoints.videos_GetSubscriptionPlans}?language=${language}`
      );
      const dataSingle = responseSingle.data;
      setsingleLanguagePlans(dataSingle);
      // console.log(dataSingle);
      setLoading(false);
    } catch (error) {
      // console.error("Error fetching single language plans:", error);
      setsingleLanguagePlans([]);
      setLoading(false);
    }

    try {
      const responseAll = await axios.get(
        `${endpoints.videos_GetSubscriptionPlans}?language=alllanguages`
      );
      const dataAll = responseAll.data;
      setallLanguagePlans(dataAll);
      setLoading(false);
    } catch (error) {
      // console.error("Error fetching all language plans:", error);
      setallLanguagePlans([]);
      setLoading(false);
    }
  };

  return (
    <Box bgcolor={"rgba(1, 1, 19, 0.8)"}>
      <Header />
      <Box
        sx={{
          color: "white",
          p: 2,
        }}
      >
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          color={"#FFDA78"}
          fontFamily={"Quicksand"}
          fontWeight={800}
        >
          Subscription Plans
        </Typography>

        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            display={"flex"}
            flexDirection={"column"}
            gap={5}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid item xs={10} md={11} lg={8} pt={6}>
              <Grid
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                sx={{
                  justifyContent: { xs: "none", sm: "space-between" },
                  flexDirection: { xs: "column", sm: "row " },
                  gap: { xs: 2, sm: 10 },
                }}
              >
                <Box sx={{ display: { xs: "none", sm: "block" } }}>
                  <Typography
                    sx={{ fontSize: { xs: "15px", sm: "20px", md: "25px" } }}
                    gutterBottom
                    textAlign={"left"}
                    color={"#FFDA78"}
                    fontFamily={"Quicksand"}
                    fontWeight={700}
                    pb={3}
                  >
                    Single Language Plans
                  </Typography>
                </Box>

                {allLanguage.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: { xs: "center", sm: "flex-end" },
                      mb: { xs: 0, sm: 4 },
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Typography
                      fontFamily={"Quicksand"}
                      fontWeight={600}
                      sx={{ fontSize: { xs: "13px", sm: "18px", md: "20px" } }}
                    >
                      Select Language :
                    </Typography>

                    <FormControl  sx={{ width: { xs: "25vw", sm: "15vw" } }}>
                      <Select 
                        value={language}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "Without label" }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              maxHeight: {
                                xs: "200px", // Default for smaller screens
                                md: "250px", // Medium screens
                                lg: "300px", // Larger screens
                              },
                              "@media (min-width: 1440px)": {
                                maxHeight: "265px", // Specifically for screens >= 1440px
                              },
                            },
                          },
                        }}
                        sx={{
                          bgcolor: "#FFDA78",
                          borderRadius: 2,
                          height: {
                            xs: "5vh",
                            md: "5vh",
                            lg: "6vh",
                            fontWeight:"700",
                          },
 
                          width: { xs: "35vw", sm: "15vw" },
                        }}
                      >
                        {allLanguage.map((each, index) => (
                          <MenuItem
                            key={index}
                            value={each.Language}
                            
                            sx={{
                              "&:hover": {
                                backgroundColor: "#FFDA78",
                                color: "#000",
                              },
                            }}
                          >
                            {each.Language}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                )}

                <Box sx={{ display: { xs: "block", sm: "none" } }}>
                  <Typography
                    // variant="h5"
                    gutterBottom
                    textAlign={"left"}
                    color={"#FFDA78"}
                    fontFamily={"Quicksand"}
                    fontWeight={700}
                    pb={3}
                    pl={2}
                  >
                    Single Language Plans
                  </Typography>
                </Box>
              </Grid>

              {loading ? (
                <Grid
                  item
                  minHeight={"40vh"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <CircularProgress color="warning" />
                </Grid>
              ) : (
                <Grid container spacing={6}>
                  {singleLanguagePlans?.length > 0 ? (
                    singleLanguagePlans?.map((plan, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={4}
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"center"}
                        sx={{ ms: { xs: 5, sm: 0 } }}
                        key={index}
                      >
                        {/* {console.log()} */}
                        <SubscriptionCard
                          plan={plan}
                          handleClickToOpen={handleClickToOpen}
                          checkWalletAmount={checkWalletAmount}
                          purchaseStatus={purchaseStatus}
                          dialogOpen={dialogOpen}
                          handleToClose={handleToClose}
                          bouns_amount={bouns_amount}
                          planCost={planCost}
                          handleNavigateWallet={handleNavigateWallet}
                          
                          
                          // clickingFunction={handleUseEfect}
                        />
                      </Grid>
                    ))
                  ) : (
                    <Grid
                      container
                      xs={10}
                      minHeight={"20vh"}
                      minWidth={"100%"}
                      display={"flex"}
                      justifyContent={"center"}
                      alignContent={"center"}
                    >
                      <Typography
                        pt={6}
                        fontFamily={"Quicksand"}
                        fontWeight={700}
                        fontSize={{ xs: "12px", sm: "18px" }}
                        color={"red"}
                      >
                        Error fetching single language plans.
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} sm={10} md={11} lg={8} pt={2}>
              <Grid>
                <Typography
                  gutterBottom
                  sx={{
                    fontSize: { xs: "15px", sm: "20px", md: "25px" },
                    pl: { xs: 4, sm: 0 },
                  }}
                  // sx={{
                  //   fontSize: { xs: "13px", sm: "18px", md: "2px" },

                  // }}
                  color={"#FFDA78"}
                  fontFamily={"Quicksand"}
                  fontWeight={700}
                  pb={3}
                >
                  All Languages Plans
                </Typography>
              </Grid>

              {loading ? (
                <Grid
                  container
                  minHeight={"40vh"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <CircularProgress color="warning" />
                </Grid>
              ) : (
                <Grid container spacing={6}>
                  {allLanguagePlans.length > 0 ? (
                    allLanguagePlans.map((plans, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={4}
                        sx={{ ml: { xs: 6, sm: 0 }, mr: { xs: 3, sm: 0 } }}
                        key={index}
                      >
                        <SubscriptionCard
                          plan={plans}
                          checkWalletAmount={checkWalletAmount}
                          // clickingFunction={handleUseEfect}
                          purchaseStatus={purchaseStatus}
                          dialogOpen={dialogOpen}
                          handleToClose={handleToClose}
                          bouns_amount={bouns_amount}
                          planCost={planCost}
                          handleNavigateWallet={handleNavigateWallet}
                        />
                      </Grid>
                    ))
                  ) : (
                    <Grid minHeight={"20vh"} alignContent={"center"}>
                      <Typography
                        pt={6}
                        fontFamily={"Quicksand"}
                        fontWeight={700}
                        fontSize={{ xs: "12px", sm: "18px" }}
                        color={"red"}
                      >
                        Error fetching all languages plans.
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Dialog
          open={openErrorDialog}
          onClose={() => setOpenErrorDialog(false)}
          aria-labelledby="error-dialog-title"
          aria-describedby="error-dialog-description"
        >
          <DialogTitle
            id="error-dialog-title"
            sx={{
              fontFamily: "Quicksand",
              fontWeight: 400,
              fontSize: 24,
              color: "#000000",
              textAlign: "center",
            }}
          >
            Subscription Status
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="error-dialog-description"
              sx={{
                fontFamily: "Quicksand",
                fontWeight: 700,
                fontSize: 20,
                textAlign: "center",
                color: "green",
              }}
            >
              {purchaseStatus}
              {/* {console.log(purchaseStatus)} */}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenErrorDialog(false)}
              sx={{ borderRadius: 1, fontWeight: 500 }}
            >
              Close
            </Button>
            {/* {purchaseStatus == "Subscription Purchased succesfully" && ( */}
            <Button
              // onClick={() => {
              //   setOpenErrorDialog(false);
              //   navigate("/my_subscriptions",state: {repeatPlan},
              //     });
              // }}

              onClick={() => {
                navigate("/my_subscriptions");
              }}
              sx={{ borderRadius: 1, fontWeight: 500 }}
            >
              myplans
            </Button>
            {/* )} */}
          </DialogActions>
        </Dialog>

        {/* // Dialog added here for Payment // */}

        {/* <Dialog
          open={dialogOpen}
          onClose={handleToClose}
          maxWidth={{ xs: "sm", sm: "md" }}
        >
          <DialogContent>
            <Box p={3}>
              <Typography
                fontFamily={"Quicksand"}
                fontWeight={700}
                fontSize={23}
                gutterBottom
              >
                Insufficient Balance in your Wallet!
              </Typography>
                 
              <Grid
                container
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "column",
                  backgroundColor: "#FFDA78",
                  padding: "20px 10px",
                  borderRadius: "10px",
                  // justifyContent:'center',
                  // alignItems:'center'
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Quicksand",
                    fontWeight: 600,
                    fontSize: 20,
                    textAlign: "center",
                    padding: "5px ",
                    marginBottom: "16px",
                    backgroundColor: "#ffff",
                    borderRadius: "10px",
                  }}
                >
                  Wallet Balance: {wallet_amount ? wallet_amount : 0}
                </Typography>

                <Typography
                  sx={{
                    fontFamily: "Quicksand",
                    fontWeight: 600,
                    fontSize: 20,
                    textAlign: "center",
                    padding: "5px",
                    marginBottom: "16px",
                    backgroundColor: "#ffff",
                    borderRadius: "10px",
                  }}
                >
                  Bonus Balance: {bouns_amount ? bouns_amount.BonusAmount : 0}
                </Typography>
              </Grid>
              <Grid container justifyContent="space-around" mt={2}>
                <Grid item>
                  {wallet_amount >= planCost? (<Button
                    // onClick={handleProceed}
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: 13,
                      borderRadius: 2,
                      fontWeight: 600,
                      fontFamily: "Quicksand",
                    }}
                    autoFocus
                  >
                    Proceed
                  </Button>):(<Button
                    onClick={handleNavigateWallet}
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: 13,
                      borderRadius: 2,
                      fontWeight: 600,
                      fontFamily: "Quicksand",
                    }}
                    autoFocus
                  >
                    + Add Money
                  </Button>)}
                </Grid>
                <Grid item>
                  <Button
                    onClick={handleToClose}
                    variant="outlined"
                    color="secondary"
                    sx={{
                      fontSize: 13,
                      borderRadius: 2,
                      fontWeight: 600,
                      fontFamily: "Quicksand",
                    }}
                    autoFocus
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </Dialog> */}
      </Box>
      <Footer />
    </Box>
  );
};

export default Subscription;
