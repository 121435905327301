import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../../utils/statusCodes";
import endpoints from "../../../Endpoints/endpoints";


const initial_bounsAmount = {
  bouns_amount: "",
  bounsAmountFetchStatus: statusCodes.IDLE,
};

const getBounsAmountReducer = createSlice({
  name: "getBounsAmountReducer",
  initialState: initial_bounsAmount,
  extraReducers: (builder) => {
    builder
      .addCase(fetchBounsAmount.pending, (state, action) => {
        state.bounsAmountFetchStatus = statusCodes.LOADING;
        // console.log("get bouns amount Details Data");
      })
      .addCase(fetchBounsAmount.fulfilled, (state, action) => {
        state.bouns_amount = action.payload;
        state.bounsAmountFetchStatus = statusCodes.SUCCESS;
        // console.log(state.bouns_amount);
      })
      .addCase(fetchBounsAmount.rejected, (state, action) => {
        state.bounsAmountFetchStatus = statusCodes.ERROR;
        // console.log("get bouns amount Details Data error");
      });
  },
});

export const fetchBounsAmount = createAsyncThunk(
  "bounsAmount/get",
  async (userId) => {
    try {
      const bounsAmount = await axios.get(`${endpoints.getBonusAmount}?userId=${userId}`);
      return bounsAmount.data;
    } catch (error) {
      const errorPayload = {
        message: error.response.data.message,
      };
      throw errorPayload;
    }
  }
);

export default getBounsAmountReducer.reducer;
