import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../utils/statusCodes";
import endpoints from "../../Endpoints/endpoints";

import { useNavigate, useLocation } from "react-router-dom";


// 400 invalid credentilas

// 404 api error

const AuthLoginState = {
  authDetails: {},
  authLoginStatus: statusCodes.IDLE,
  authLoginLoader: false,
};

const access_token = localStorage.getItem("token")



export const authLogin = createAsyncThunk(
  "authLogin/post",
  async (userLoginJSON) => {

    let userLoginObj;
    const userLoginJSONlength = Object.keys(userLoginJSON).length
    // console.log(userLoginJSONlength)

    if (userLoginJSONlength === 5) {
      userLoginObj = {
        Email: userLoginJSON.Email,
        Password: userLoginJSON.Password,
        // PhoneNo: userLoginJSON.PhoneNo,
        DeviceName: userLoginJSON.DeviceName,
      }
    }else{
      userLoginObj = userLoginJSON
    }

 
    // console.log("Login Data:", userLoginObj)
    try {
      const userData = await axios.post(
        `${endpoints.login}`,
        userLoginObj, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Cache-Control': 'no-cache, no-store, must-revalidate', // Disable caching
          Pragma: 'no-cache', // Disable caching
          Expires: '0', // Disable caching
        },
      }
      );
      return userData.data;
    } catch (error) {
      const errorPayload = {
        message: error.response.data[0],
      };
      // console.log(error);
      // console.log(error.message);
       throw error; // Throwing the error payload
    }
  }
);

const authLoginReducer = createSlice({
  name: "authLogin_redux",
  initialState: AuthLoginState,

  extraReducers: (builder) => {
    builder
      .addCase(authLogin.pending, (state, action) => {
        state.authLoginStatus = statusCodes.LOADING;
        state.authLoginLoader = true;
      })

      .addCase(authLogin.fulfilled, (state, action) => {
        // console.log("success Login")
        // console.log(action.payload);
        state.authDetails = action.payload[0];
        state.authLoginStatus = statusCodes.SUCCESS;
        state.authLoginLoader = false;

        
          localStorage.setItem("isSignIn", true);
        
      })
      .addCase(authLogin.rejected, (state, action) => {
        // console.log("Login Failure")
        // console.log(action.error)
        state.authDetails =   action.error;
        state.authLoginStatus = statusCodes.ERROR;
        state.authLoginLoader = false;
      });
  },
});

 

export default authLoginReducer.reducer;


