
import { Box, Typography } from "@mui/material";
import { IoIosArrowDown } from "react-icons/io";
 
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllLanguageFunction } from "../../../Redux/reducers/subscription/getAllLanguageReducer";
import { useEffect, useState } from "react";
import {
  clearAllGeners,
  fetchByGenereDashboardBanners,
} from "../../../Redux/reducers/bannersByGenereReducer";
import { useNavigate, useLocation } from "react-router-dom";
import {
  clearAllLanguages,
  fetchVideoByLanguage,
} from "../../../Redux/reducers/videos/getVideoByLanguageReducer";
import { fetchByCategoryDashboardBanners, resetState } from "../../../Redux/reducers/bannersByCategoryReducer";
import { fetchallVideos } from "../../../Redux/reducers/allVideosReducer";
 
const Menubar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
 
 
  const { allLanguage } = useSelector(
    (state) => state.rootReducer.getAllLanguageReducer
  );

  useEffect(() => {
    if (location.state === "generes") {
      dispatch(fetchByGenereDashboardBanners(location.pathname.split("/")[2]));
    } else if (location.state === "languages") {
      dispatch(fetchVideoByLanguage(location.pathname.split("/")[2]));
    } else if (location.state === "Movies" || location.state === "Webseries" || location.state === "Anime") {
      if (location.state === "Movies") {
        dispatch(fetchallVideos());
      }
      dispatch(resetState());
      dispatch(fetchByCategoryDashboardBanners(location.state));
    }
  }, [location.state]);



 
  const handleGenerMovies = (gener) => {
    dispatch(clearAllGeners());
    dispatch(fetchByGenereDashboardBanners(gener));
    navigate(`/category/${gener}`, { state: "generes" });
    setIsDropdownOpen(false); // Hide dropdown after click
    setIsCategoryDropdownOpen(false); // Hide category dropdown after click
  };
 
  const handleLanguage = (Language) => {
    dispatch(clearAllLanguages());
    dispatch(fetchVideoByLanguage(Language));
    navigate(`/category/${Language}`, { state: "languages" });
  };
 
  const handleMoviesOrWebseries = (movieOrWeb) => {
    if (movieOrWeb === "Movies") {
      dispatch(fetchallVideos());
    }
    dispatch(resetState());
    dispatch(fetchByCategoryDashboardBanners(movieOrWeb));
    navigate(`/category/${movieOrWeb}`, { state: movieOrWeb });
    setIsDropdownOpen(false); // Hide dropdown after click
    setIsCategoryDropdownOpen(false); // Hide category dropdown after click
  };
 
  const maxRowsPerColumn = 6;
 
  const numColumns = Math.ceil(allLanguage.length / maxRowsPerColumn);
 
  // Create an array to hold columns of languages
  const languageColumns = [];
  for (let i = 0; i < numColumns; i++) {
    languageColumns.push(
      allLanguage.slice(i * maxRowsPerColumn, (i + 1) * maxRowsPerColumn)
    );
  }
 
  // useEffect(() => {
  //   if (allLanguage.length < 1) {
  //     dispatch(getAllLanguageFunction());
  //   }
  // }, []);
  const handleMouseEnter = () => {
    setIsDropdownOpen(true);
  };
 
  const handleMouseLeave = () => {
    setIsDropdownOpen(false);
  };
 
  const handleCategoryMouseEnter = () => {
    setIsCategoryDropdownOpen(true);
  };
 
  const handleCategoryMouseLeave = () => {
    setIsCategoryDropdownOpen(false);
  };

 
  return (
    <>
      <Box
        width={"20vw"}
        display={"flex"}
        justifyContent={"space-evenly"}
        alignItems={"center"}
        alignSelf={"flex-end"}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          width={"30%"}
          className="dropdown-main-continer menu-item"
          color={"#FFDA78"}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <Typography
            fontFamily={"Quicksand"}
            width={"60%"}
            fontSize={16}
            marginRight={0.5}
            className="menu-item"
            sx={{
              cursor: "pointer",
              transition: "all 0.3s ease",
              "&:hover": {
                fontSize: 18,
                fontFamily: "Quicksand",
                textTransform: "uppercase",
              },
            }}
          >
            Home
          </Typography>
          <IoIosArrowDown
            color={"#FFDA78"}
            fontSize={22}
            className="down-arrow"
          />
          {isDropdownOpen && (
          <Box className="dropdown" p={3}>
            <Typography
              className="nav-item"
              borderRadius={3}
              fontFamily={"Quicksand"}
              textAlign={"left"}
              width={"90%"}
              p={1}
              onClick={() => navigate("/")}
            >
              All
            </Typography>
            <Typography
              className="nav-item"
              borderRadius={3}
              fontFamily={"Quicksand"}
              textAlign={"left"}
              width={"90%"}
              p={1}
              onClick={() => handleMoviesOrWebseries("Movies")}
            >
              Movies
            </Typography>
            <Typography
              className="nav-item"
              borderRadius={3}
              fontFamily={"Quicksand"}
              textAlign={"left"}
              width={"90%"}
              p={1}
              onClick={() => handleMoviesOrWebseries("Webseries")}
            >
              Web series
            </Typography>
            <Typography
              className="nav-item"
              borderRadius={3}
              fontFamily={"Quicksand"}
              textAlign={"left"}
              width={"90%"}
              p={1}
              onClick={() => handleMoviesOrWebseries("Anime")}
            >
              Anime
            </Typography>
          </Box>
          )}
        </Box>
 
        <Box
          display={"flex"}
          alignItems={"center"}
          width={"40%"}
          color={"#FFDA78"}
          className="dropdown-main-continer-cat menu-item"
          onMouseEnter={handleCategoryMouseEnter}
          onMouseLeave={handleCategoryMouseLeave}
          onClick={() => setIsCategoryDropdownOpen(!isCategoryDropdownOpen)}
        >
          <Typography
            fontFamily={"Quicksand"}
            fontSize={16}
            marginRight={0.5}
            sx={{
              cursor: "pointer",
              transition: "all 0.3s ease",
              "&:hover": {
                fontSize: 18,
                fontFamily: "Quicksand",
                textTransform: "uppercase",
              },
            }}
          >
            Categories
          </Typography>
          <IoIosArrowDown color={"#FFDA78"} fontSize={22} />
          {isCategoryDropdownOpen && (
          <Box className="dropdown-cat">
            <Box display={"flex"} justifyContent={"space-around"} p={3}>
              <Box display={"flex"} flexDirection={"column"} width={"40%"}>
                <Typography
                  textAlign={"left"}
                  fontFamily={"Quicksand"}
                  color={"#FFDA78"}
                  fontWeight={700}
                  mb={1}
                  className="underline-text"
                >
                  GENERS
                </Typography>
                <Box
                  mt={2}
                  display={"flex"}
                  justifyContent={"space-around"}
                  width={"80%"}
                >
                  <Box width={"40%"}>
                    <Typography
                      className="nav-item"
                      borderRadius={3}
                      fontFamily={"Quicksand"}
                      textAlign={"left"}
                      width={"100%"}
                      p={1}
                      onClick={() => handleGenerMovies("Action")}
                    >
                      Action
                    </Typography>
                    <Typography
                      className="nav-item"
                      borderRadius={3}
                      fontFamily={"Quicksand"}
                      textAlign={"left"}
                      width={"100%"}
                      p={1}
                      onClick={() => handleGenerMovies("Devotional")}
                    >
                      Devotional
                    </Typography>
                    <Typography
                      className="nav-item"
                      borderRadius={3}
                      fontFamily={"Quicksand"}
                      textAlign={"left"}
                      width={"100%"}
                      p={1}
                      onClick={() => handleGenerMovies("Drama")}
                    >
                      Drama
                    </Typography>
                    <Typography
                      className="nav-item"
                      borderRadius={3}
                      fontFamily={"Quicksand"}
                      textAlign={"left"}
                      width={"100%"}
                      p={1}
                      onClick={() => handleGenerMovies("Sci-Fi")}
                    >
                      Sci-Fi
                    </Typography>
 
                    <Typography
                      className="nav-item"
                      borderRadius={3}
                      fontFamily={"Quicksand"}
                      textAlign={"left"}
                      width={"100%"}
                      p={1}
                      onClick={() => handleGenerMovies("Adventure")}
                    >
                      Adventure
                    </Typography>
 
                    <Typography
                      className="nav-item"
                      borderRadius={3}
                      fontFamily={"Quicksand"}
                      textAlign={"left"}
                      width={"100%"}
                      p={1}
                      onClick={() => handleGenerMovies("Romance")}
                    >
                      Romance
                    </Typography>
                  </Box>
 
                  <Box width={"40%"}>
                    <Typography
                      className="nav-item"
                      borderRadius={3}
                      fontFamily={"Quicksand"}
                      textAlign={"left"}
                      width={"100%"}
                      p={1}
                      onClick={() => handleGenerMovies("Horror")}
                    >
                      Horror
                    </Typography>
                    <Typography
                      className="nav-item"
                      borderRadius={3}
                      fontFamily={"Quicksand"}
                      textAlign={"left"}
                      width={"100%"}
                      p={1}
                      onClick={() => handleGenerMovies("Comedy")}
                    >
                      Comedy
                    </Typography>
                    <Typography
                      className="nav-item"
                      borderRadius={3}
                      fontFamily={"Quicksand"}
                      textAlign={"left"}
                      width={"100%"}
                      p={1}
                      onClick={() => handleGenerMovies("Thriller")}
                    >
                      Thriller
                    </Typography>
                    <Typography
                      className="nav-item"
                      borderRadius={3}
                      fontFamily={"Quicksand"}
                      textAlign={"left"}
                      width={"100%"}
                      p={1}
                      onClick={() => handleGenerMovies("Sports")}
                    >
                      Sports
                    </Typography>
 
                    <Typography
                      className="nav-item"
                      borderRadius={3}
                      fontFamily={"Quicksand"}
                      textAlign={"left"}
                      width={"100%"}
                      p={1}
                      onClick={() => handleGenerMovies("Family")}
                    >
                      Family
                    </Typography>
                  </Box>
                </Box>
              </Box>
 
              <Box display={"flex"} flexDirection={"column"} width={"60%"}>
                <Typography
                  textAlign={"left"}
                  fontFamily={"Quicksand"}
                  color={"#FFDA78"}
                  fontWeight={700}
                  mb={1}
                  className="underline-text"
                >
                  LANGUAGES
                </Typography>
                <Box
                  mt={2}
                  display={"flex"}
                  justifyContent={"space-around"}
                  width={"80%"}
                >
                  {languageColumns.map((column, columnIndex) => (
                    <Box width={"40%"} key={columnIndex}>
                      {column.map((each, index) => (
                        <Typography
                          key={index}
                          className="nav-item"
                          borderRadius={3}
                          fontFamily={"Quicksand"}
                          textAlign={"left"}
                          width={"80%"}
                          p={1}
                          onClick={() => handleLanguage(each.Language)}
                        >
                          {each.Language}
                        </Typography>
                      ))}
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
          )}
        </Box>
 
        {/* <Box display={"flex"} alignItems={"center"} width={"20%"}>
          <Typography
            fontFamily={"Quicksand"}
            fontSize={16}
            className="menu-item"
            color={"#FFDA78"}
            sx={{
              cursor: "pointer",
              transition: "all 0.3s ease",
              "&:hover": {
                fontSize: 16,
                fontFamily: "Quicksand",
                textTransform: "uppercase",
              },
            }}
          >
            Shorts
          </Typography>
        </Box> */}
      </Box>
    </>
  );
};
 
export default Menubar;
 
 