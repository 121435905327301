// export const getIp = async () => {
//   const response = await fetch("https://ipapi.co/json/");
//   const data = await response.json();
//   localStorage.setItem("ipAddress", data.ip);
//   // console.log(data.ip);
//   return data.ip;
// };
import FingerprintJS from "@fingerprintjs/fingerprintjs";

// export const getIp = async () => {
//   // Check if IP is already stored in localStorage
//   const existingIp = localStorage.getItem("ipAddress");
//   if (existingIp) {
//     return existingIp; // Return the stored IP
//   }

//   // Fetch the IP if not available in localStorage
//   try {
//     const response = await fetch("https://ipapi.co/json/");
//     const data = await response.json();

//     // Store the IP in localStorage
//     localStorage.setItem("ipAddress", data.ip);
//     return data.ip;
//   } catch (error) {
//     console.error("Error fetching IP address:", error);
//     throw new Error("Unable to fetch IP address");
//   }
// };


export const getIp = async () => {
  const fp = await FingerprintJS.load();
    const result = await fp.get();
    // console.log("Unique ID:", result.visitorId);
    localStorage.setItem("ipAddress", result.visitorId);

    return result.visitorId;
};