import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../../utils/statusCodes";
import endpoints from "../../../Endpoints/endpoints"; 



const getTrailerByIdApi = `${endpoints.getTrailerById}?name=Checkpost 1995`

const initial_trailer_by_id = { trailerById: [], trailerByIdFetchStatus: statusCodes.IDLE };

const getTrailerByIdReducer = createSlice({
  name: "getTrailerByIdReducer",
  initialState: initial_trailer_by_id,
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrailerById.pending, (state, action) => {
        state.trailerByIdFetchStatus = statusCodes.LOADING;
        // console.log("getTrailer by id data pending");
      })
      .addCase(fetchTrailerById.fulfilled, (state, action) => {
        state.trailerById = action.payload;
        state.trailerByIdFetchStatus = statusCodes.SUCCESS;
        // console.log(state.trailerById);
      })
      .addCase(fetchTrailerById.rejected, (state, action) => {
        state.trailerByIdFetchStatus = statusCodes.ERROR;
        // console.log("getTrailer by id data error");
      });
  },
});


export const fetchTrailerById = createAsyncThunk("getTrailerById/get", async () => {

 
  try {
    const trailerById = await axios.get(getTrailerByIdApi);
    return trailerById.data;
  } catch (error) {
    const errorPayload = {
      message: error.response.data.message,
    };
    throw errorPayload;
  }


});

export default getTrailerByIdReducer.reducer;
