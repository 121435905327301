import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../utils/statusCodes";

const initial_getUserId = { 
  getUserId: {}, 
  getUserIdFetchStatus: statusCodes.IDLE,
};

const getuserbyidSlice = createSlice({
  name: "getuserbyid",
  initialState: initial_getUserId,
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetUserId.pending, (state) => {
        state.getUserIdFetchStatus = statusCodes.LOADING; // Fixed property name
      })
      .addCase(fetchGetUserId.fulfilled, (state, action) => {
        state.getUserId = action.payload; // Fixed property name
        state.getUserIdFetchStatus = statusCodes.SUCCESS; // Fixed property name
      })
      .addCase(fetchGetUserId.rejected, (state, action) => {
        state.getUserIdFetchStatus = statusCodes.ERROR; // Fixed property name
        console.error("Error fetching data: ", action.error);
      });
  },
});

export const fetchGetUserId = createAsyncThunk(
  "getuserbyid/get",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`https://api.iott.co.in/api/user/getuserbyid?Userid=${userId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue({
        message: error.response?.data?.message || "Something went wrong",
      });
    }
  }
);

export default getuserbyidSlice.reducer;
