import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../../utils/statusCodes";
import endpoints from "../../../Endpoints/endpoints";

const clearAllCollectionApi = endpoints.clearSingleOrAllCollection;

const initial_clearAllCollection = {
  clearAllCollectionData: [],
  ClearAllCollectionFetchStatus: statusCodes.IDLE,
};

const clearAllCollectionReducer = createSlice({
  name: "clearAllCollectionReducer",
  initialState: initial_clearAllCollection,
  extraReducers: (builder) => {
    builder
      .addCase(fetchClearAllCollection.pending, (state, action) => {
        state.ClearAllCollectionFetchStatus = statusCodes.LOADING;
        // console.log("clear single watch history data pending");
      })
      .addCase(fetchClearAllCollection.fulfilled, (state, action) => {
        state.clearAllCollectionData = action.payload;
        state.ClearAllCollectionFetchStatus = statusCodes.SUCCESS;
        // console.log(state.clearSingleWatchHistoryData);
      })
      .addCase(fetchClearAllCollection.rejected, (state, action) => {
        state.ClearAllCollectionFetchStatus = statusCodes.ERROR;
        // console.log("clear single watch history  data error");
      });
  },
});

export const fetchClearAllCollection = createAsyncThunk(
  "fetchClearAllCollection/delete",
  async (clearAllCollectionJSON) => {
    // console.log(`clear all collection:`)
    // console.log(clearAllCollectionJSON)
    try {
      const clearAllCollection = await axios.delete(
        `${clearAllCollectionApi}?userId=${clearAllCollectionJSON.userId}&movieIds=[]`
      );
      return clearAllCollection.data;
    } catch (error) {
      const errorPayload = {
        message: error.response.data.message,
      };
      throw errorPayload;
    }
  }
);

export default clearAllCollectionReducer.reducer;
