import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../utils/statusCodes";




const AuthVerifyLoginState = {
  authVerifyDetails: [],
  authVerifyLoginStatus: statusCodes.IDLE,
  authVerifyLoginLoader: false,
};

const access_token = localStorage.getItem("token")



export const authVerifyLogin = createAsyncThunk(
  "authVerifyLogin/post",
  async (userLoginJSON) => {

    // let userLoginObj;
    // const userLoginJSONlength = Object.keys(userLoginJSON).length
    // // console.log(userLoginJSONlength)

    // if (userLoginJSONlength === 5) {
    //   userLoginObj = {
    //     Email: userLoginJSON.Email,
    //     Password: userLoginJSON.Password,
    //     PhoneNo: userLoginJSON.PhoneNo,
    //     DeviceName: userLoginJSON.DeviceName,
    //   }
    // }else{
    //   userLoginObj = userLoginJSON
    // }

    // console.log("authVerifyLogins:", userLoginJSON);
    // console.log("Login Data:", userLoginJSON)
    try {
      const userData = await axios.post(
        `https://api.iott.co.in/api/user/VerifyUserTestEmail`,
        userLoginJSON,
         {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
      );

      // console.log("Login Details:", userData.data );
      return userData.data;
    } catch (error) {
      
      console.error(error.response.data);
      throw error; // Throwing the error payload
    }
  }
);

const authVerifyLoginReducer = createSlice({
  name: "authVerifyLogin_redux",
  initialState: AuthVerifyLoginState,

  extraReducers: (builder) => {
    builder
      .addCase(authVerifyLogin.pending, (state, action) => {
        state.authVerifyLoginStatus = statusCodes.LOADING;
        state.authVerifyLoginLoader = true;
      })
      .addCase(authVerifyLogin.fulfilled, (state, action) => {
        // console.log("success Login verify")
        // console.log(action.payload);
        state.authVerifyDetails = action.payload;
        state.authVerifyLoginStatus = statusCodes.SUCCESS;
        state.authVerifyLoginLoader = false;
        // localStorage.setItem("isSignIn", true);



      })
      .addCase(authVerifyLogin.rejected, (state, action) => {
        // console.log("Login Failure verify")
        state.authVerifyDetails = action.error;
        state.authVerifyLoginStatus = statusCodes.ERROR;
        state.authVerifyLoginLoader = false;
      });
  },
});

 

export default authVerifyLoginReducer.reducer;


// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
// import statusCodes from "../../utils/statusCodes";
// import endpoints from "../../Endpoints/endpoints";

// // Removed unused imports
// // import { useNavigate, useLocation } from "react-router-dom";

// const AuthVerifyLoginState = {
//   authVerifyDetails: [],
//   authVerifyLoginStatus: statusCodes.IDLE,
//   authVerifyLoginLoader: false,
// };

// // Moved token fetching inside the async function to ensure it's fresh
// export const authVerifyLogin = createAsyncThunk(
//   "authVerifyLogin/post",
//   async (userLoginJSON) => {
//     const access_token = localStorage.getItem("token");

//     try {
//       const userData = await axios.post(
//         `${endpoints.VerifyUser}`,
//         userLoginJSON,
//         {
//           headers: {
//             Authorization: `Bearer ${access_token}`,
//           },
//         }
//       );

//       console.log("Login Details:", userData.data);
//       return userData.data;
//     } catch (error) {
//       console.log("Error:", error);
//       console.log(error.response ? error.response.data : error.message);  // Better error logging
//       throw error; // Rethrow the error to handle it in the reducer
//     }
//   }
// );

// const authVerifyLoginReducer = createSlice({
//   name: "authVerifyLogin_redux",
//   initialState: AuthVerifyLoginState,

//   extraReducers: (builder) => {
//     builder
//       .addCase(authVerifyLogin.pending, (state) => {
//         state.authVerifyLoginStatus = statusCodes.LOADING;
//         state.authVerifyLoginLoader = true;
//       })
//       .addCase(authVerifyLogin.fulfilled, (state, action) => {
//         state.authVerifyDetails = action.payload;
//         state.authVerifyLoginStatus = statusCodes.SUCCESS;
//         state.authVerifyLoginLoader = false;
//         localStorage.setItem("isSignIn", true); // Storing login state in localStorage
//       })
//       .addCase(authVerifyLogin.rejected, (state, action) => {
//         // You can add a specific error message or error code handling here if needed
//         state.authVerifyDetails = [];
//         state.authVerifyLoginStatus = statusCodes.ERROR;
//         state.authVerifyLoginLoader = false;
//         // Optionally store the error message or error details
//       });
//   },
// });

// export default authVerifyLoginReducer.reducer;
