import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../utils/statusCodes";
import endpoints from "../../Endpoints/endpoints"; 

const contactUsApi = endpoints.contactUs

const initial_contactUs = { contactUs: {}, contactUsFetchStatus: statusCodes.IDLE };

const contactUsReducer = createSlice({

  name: "contactUsReducer",
  initialState: initial_contactUs,
  
  extraReducers: (builder) => {
    builder
      .addCase(fetchContactUs.pending, (state, action) => {
        state.contactUsFetchStatus = statusCodes.LOADING;
        // console.log("contact us data pending");
      })
      .addCase(fetchContactUs.fulfilled, (state, action) => {
        state.contactUs = action.payload;
        state.contactUsFetchStatus = statusCodes.SUCCESS;
        // console.log(state.contactUs);
      })
      .addCase(fetchContactUs.rejected, (state, action) => {
        state.contactUsFetchStatus = statusCodes.ERROR;
        // console.log("contact us data error");
      });
  },
});


export const fetchContactUs = createAsyncThunk("contactUs/get", async () => {

 
  try {
    const contactUsData = await axios.get(contactUsApi);
    return contactUsData.data;
  } catch (error) {
    const errorPayload = {
      message: error.response.data.message,
    };
    throw errorPayload;
  }


});

export default contactUsReducer.reducer;
