// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
// import statusCodes from "../../../utils/statusCodes";
// import endpoints from "../../../Endpoints/endpoints";
 
// const formatDate = (date) => {
//   const year = date.getFullYear();
//   const month = String(date.getMonth() + 1).padStart(2, '0');
//   const day = String(date.getDate()).padStart(2, '0');
//   return `${year}-${month}-${day}`;
// };
 
// const daysBetween = (date1, date2) => Math.floor((date1 - date2) / (1000 * 60 * 60 * 24));
 
// // const categorizeWatchHistory = (date) => {
// //   const today = new Date();
// //   const watchDate = new Date(date);
// //   const diffDays = daysBetween(today, watchDate);
// //   const diffYears = Math.floor(diffDays / 365);
 
// //   if (diffDays === 0) return 'Today';
// //   if (diffDays === 1) return 'Yesterday';
// //   if (diffDays <= 6) return `${diffDays} days ago`;
// //   if (diffDays < 7) return '1 week ago';
 
// //   if (diffDays <= 29) return `${diffDays} days ago`;
// //   if (diffDays <= 30) return '1 month ';
// //   if (diffDays <= 59) return '1 month ago';
// //   if (diffDays <= 60) return '2 months';
// //   if (diffDays <= 89) return '2 months ago';
// //   if (diffDays <= 90) return '3 months ago';
// //   if (diffDays <= 180) return '6 months ago';
// //   if (diffDays <= 365) return '1 year ago';
// //   if (diffYears === 1) return '1 year ago';
// //   if (diffYears === 2) return '2 years ago';
// //   if (diffYears <= 3) return '3 years ago';
 
// //   return 'More than 3 years ago';
// // };
 
// const categorizeWatchHistory = (date) => {
//   const today = new Date();
//   const watchDate = new Date(date);
//   const diffDays = daysBetween(today, watchDate);
 
//   if (diffDays === 0) return 'Today';
//   if (diffDays === 1) return 'Yesterday';
//   if (diffDays <= 6) return `${diffDays} days ago`;
 
//   const diffWeeks = Math.floor(diffDays / 7);
//   if (diffWeeks === 1) return '1 week ago';
//   if (diffWeeks < 4) return `${diffWeeks} weeks ago`;
 
//   const diffMonths = Math.floor(diffDays / 30);
//   if (diffMonths === 1) return '1 month ago';
//   if (diffMonths < 12) return `${diffMonths} months ago`;
 
//   const diffYears = Math.floor(diffDays / 365);
//   if (diffYears === 1) return '1 year ago';
//   return `${diffYears} years ago`;
// };
 
 
// const initial_Watch_history = {
//   categorizedWatchHistory: {},
//   watchHistoryFetchStatus: statusCodes.IDLE,
// };
 
// const getWatchHistoryReducer = createSlice({
//   name: "getWatchHistoryReducer",
//   initialState: initial_Watch_history,
 
//   reducers: {},
 
//   extraReducers: (builder) => {
//     builder
//       .addCase(fetchWatchHistory.pending, (state) => {
//         state.watchHistoryFetchStatus = statusCodes.LOADING;
//       })
//       .addCase(fetchWatchHistory.fulfilled, (state, action) => {
//         const data = action.payload.data;
 
//         if (!Array.isArray(data)) {
//           state.watchHistoryFetchStatus = statusCodes.ERROR;
//           return;
//         }
 
//         const categorizedWatchHistory = {};
 
//         data.forEach(item => {
//           const itemDate = item.Date ? item.Date.split(' ')[0] : null;
 
//           if (itemDate) {
//             const category = categorizeWatchHistory(itemDate);
 
//             if (!categorizedWatchHistory[category]) {
//               categorizedWatchHistory[category] = [];
//             }
//             categorizedWatchHistory[category].push(item);
//           }
//         });
 
//         state.categorizedWatchHistory = categorizedWatchHistory;
//         state.watchHistoryFetchStatus = statusCodes.SUCCESS;
//       })
//       .addCase(fetchWatchHistory.rejected, (state, action) => {
//         state.watchHistoryFetchStatus = statusCodes.ERROR;
//       });
//   },
// });
 
// export const fetchWatchHistory = createAsyncThunk(
//   "fetchWatchHistory/get",
//   async (userid) => {
//     try {
//       const response = await axios.get(`${endpoints.getWatchHistory}?userid=${userid}`);
//       // console.log("watch History API Response",response);
//       return response;
//     } catch (error) {
//       const errorPayload = {
//         message: error.response?.data?.message || 'An error occurred',
//       };
//       throw errorPayload;
//     }
//   }
// );
 
// export default getWatchHistoryReducer.reducer;
 
 ///////////////updated code//////////////////

 import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../../utils/statusCodes";
import endpoints from "../../../Endpoints/endpoints";

// Utility function to format a date
const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

// Calculate the difference in days between two dates
const daysBetween = (date1, date2) => {
  const msPerDay = 1000 * 60 * 60 * 24;
  return Math.floor((date1 - date2) / msPerDay);
};

// Categorize watch history based on date
const categorizeWatchHistory = (date) => {
  const today = new Date();
  const watchDate = new Date(date);
  const diffDays = daysBetween(today, watchDate);

  if (diffDays === 0) return "Today";
  if (diffDays === 1) return "Yesterday";
  if (diffDays <= 6) return `${diffDays} days ago`;

  const diffWeeks = Math.floor(diffDays / 7);
  if (diffWeeks === 1) return "1 week ago";
  if (diffWeeks < 4) return `${diffWeeks} weeks ago`;

  const diffMonths = Math.floor(diffDays / 30);
  if (diffMonths === 1) return "1 month ago";
  if (diffMonths < 12) return `${diffMonths} months ago`;

  const diffYears = Math.floor(diffDays / 365);
  if (diffYears === 1) return "1 year ago";
  return `${diffYears} years ago`;
};

// Initial state
const initialState = {
  categorizedWatchHistory: {},
  watchHistoryFetchStatus: statusCodes.IDLE,
};

// Fetch watch history
export const fetchWatchHistory = createAsyncThunk(
  "fetchWatchHistory/get",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${endpoints.getWatchHistory}?userid=${userId}`);
      return response.data; // Ensure only data is returned
    } catch (error) {
      return rejectWithValue({
        message: error.response?.data?.message || "An error occurred",
      });
    }
  }
);

// Slice
const watchHistorySlice = createSlice({
  name: "watchHistory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWatchHistory.pending, (state) => {
        state.watchHistoryFetchStatus = statusCodes.LOADING;
      })
      .addCase(fetchWatchHistory.fulfilled, (state, action) => {
        const data = action.payload;

        if (!Array.isArray(data)) {
          state.watchHistoryFetchStatus = statusCodes.ERROR;
          return;
        }

        const categorizedWatchHistory = {};

        data.forEach((item) => {
          const itemDate = item.Date ? new Date(item.Date).toISOString().split("T")[0] : null;

          if (itemDate) {
            const category = categorizeWatchHistory(itemDate);

            if (!categorizedWatchHistory[category]) {
              categorizedWatchHistory[category] = [];
            }
            categorizedWatchHistory[category].push(item);
          }
        });

        state.categorizedWatchHistory = categorizedWatchHistory;
        state.watchHistoryFetchStatus = statusCodes.SUCCESS;
      })
      .addCase(fetchWatchHistory.rejected, (state, action) => {
        state.watchHistoryFetchStatus = statusCodes.ERROR;
        state.error = action.payload?.message || "An error occurred";
      });
  },
});

export default watchHistorySlice.reducer;
