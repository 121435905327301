import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import { useRef } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
// import { auth } from './../../utils/firebaseConfig';
import { getAuthData } from "../../utils/auth";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchReferralDetails } from "./../../Redux/reducers/referAndEarn/referralDetailsRequestReducer";
import { Share } from "@mui/icons-material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import Wallet from "../Wallet";
import ShareModal from "../DetailedPage/ShareModal";
import endpoints from "../../Endpoints/endpoints";

const ReferAndEarn = () => {
  const authData = getAuthData();
  const dispatch = useDispatch();
  const [referralCode, setReferralCode] = useState("");
  const [error, setError] = useState(null);
  const [hide, setHide] = useState(false);
  const [totalEarnings, setTotalEarnings] = useState(0); // New state for total earnings
  const [amount, setAmount] = useState("");
  const [withdrawError, setWithdrawError] = useState(null);
  const [amountError, setAmountError] = useState(null);
  const [activeButton, setActiveButton] = useState(1);
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState(false);
  const [showWithdrawError, setShowWithdrawError] = useState(false);
  const [buttonText, setButtonText] = useState("Copy Referral Code");
  const [hasBankDetails, setHasBankDetails] = useState(false); // Assume this state holds if the user has bank details
  const [showWithdrawForm, setShowWithdrawForm] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const tableRef = useRef(null);

  const handleClose = () => setOpenModal(!openModal);

  // Function to handle clicks outside of the table
  const handleClickOutside = (e) => {
    if (tableRef.current && !tableRef.current.contains(e.target)) {
      setHide(false); // Hide the table when clicked outside
    }
  };

  useEffect(() => {
    // Add event listener for clicks
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up the event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleAmountChange = (event) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      setAmount(value);
      if (parseFloat(value) >= 100) {
        setAmountError("");
      }
    }
    if (parseFloat(value) < 100) {
      setWithdrawError("Please Enter Minimum Withdrawal Amount 100/-.");
      // setShowWithdrawError(true);
    } else {
      setWithdrawError("");
      setShowWithdrawError(false);
    }
  };

  const handleConfirmClick = async () => {
    if (amount.trim() === "") {
      // If amount is empty
      setConfirmMessage("Please enter an amount.");
      setConfirmMessage(true);
      return;
    }
    if (totalEarnings < 100) {
      setWithdrawError("Please Enter Minimum Withdrawal Amount 100/-.");
      setShowWithdrawError(true);
      setTimeout(() => setShowWithdrawError(false), 10000);
      return;
    }
    if (totalEarnings < 100) {
      setAmountError(
        "You need a minimum of 100 rupees in earnings to withdraw."
      );
      setShowWithdrawError(true);
      setTimeout(() => setShowWithdrawError(false), 10000);
      return;
    }

    if (amount > totalEarnings) {
      setWithdrawError("In suffiecint funds in total earnings");
      return;
    }

    // Proceed with the withdrawal if total earnings are sufficient
    try {
      const userId = authData?.auth?.Id;
      const response = await axios.post(
        endpoints.payment_WithdrawBonusAmount,
        {
          UserId: userId,
          Amount: parseFloat(amount),
        }
      );

      if (response.status === 200) {
        // Handle success response
        // console.log("Withdrawal successful:", response.data);
        setAmount("");
        // setHide(false);
        handleLevelClick(1); // Refresh Level 1 details after successful withdrawal
        setShowConfirmationMessage(true);
        setTimeout(() => setShowConfirmationMessage(false), 10000);
      } else {
        throw new Error("Failed to withdraw bonus amount.");
      }
    } catch (error) {
      setWithdrawError("Error processing withdrawal. Please try again later.");
      console.error("Error processing withdrawal:", error);
    }
  };

  const booleanToString = (value) => (value ? "Active" : "In Active");

  const { details } = useSelector(
    (state) => state.rootReducer.referralDetailsRequestReducer
  );
  // console.log(details);
  // console.log(details.length);

  useEffect(() => {
    const fetchReferralCode = async () => {
      try {
        const userId = authData?.auth?.Id;
        const response = await axios.post(
          endpoints.referral_get_referralcode,
          { UserId: userId }
        );

        if (response.status === 200) {
          setReferralCode(response.data.ReferralCode);
          // console.log(response); // Assuming 'code' is the key in the response containing the referral code
        } else {
          throw new Error("Failed to fetch referral code.");
        }
      } catch (error) {
        setError("Error fetching referral code. Please try again later.");
        console.error("Error fetching referral code:", error);
      }
    };

    const fetchTotalEarnings = async () => {
      try {
        const userId = authData?.auth?.Id;
        // Assuming the ReferredByUserId is the same as the current user's ID
        const response = await axios.get(
          `${endpoints.Payment_GetTotalBonusAmountByReferredByUserId}?ReferredByUserId=${userId}`
        );

        if (response.status === 200) {
          // console.log(response);
          setTotalEarnings(response.data);
        } else {
          throw new Error("Failed to fetch total earnings.");
        }
      } catch (error) {
        setError("Error fetching total earnings. Please try again later.");
        console.error("Error fetching total earnings:", error);
      }
    };
    const fetchInitialData = () => {
      fetchReferralCode();
      fetchTotalEarnings();
      handleLevelClick(1); // Fetch Level 1 details by default
    };
    fetchInitialData();
  }, []);

  const checkData = () => {};

  // useEffect(()=>{

  //   const userId = authData?.auth?.Id;
  //   // dispatch(fetchReferralDetails())

  // })

  const handleLevelClick = (level) => {
    const userId = authData?.auth?.Id;
    dispatch(fetchReferralDetails({ userId, level }));
    setActiveButton(level);
  };

  const getButtonStyle = (level) => ({
    bgcolor: activeButton === level ? "#FFDA78" : "white",
    // color: activeButton === level ? "red" : "black",
  });

  // const handleWithdrawClick = () => {
  //   if (totalEarnings >= 100) {
  //     handleLevelClick(1);
  //     setHide(true);
  //     setWithdrawError("");
  //     setShowWithdrawError(false);
  //   } else {
  //     setWithdrawError(
  //       "You need a minimum of 100 rupees in earnings to withdraw."
  //     );
  //     setShowWithdrawError(true);
  //     setTimeout(() => setShowWithdrawError(false), 3000);
  //   }
  // };

  //   const handleWithdrawClick = async () => {
  //     if (totalEarnings < 100) {
  //       setWithdrawError("You need a minimum of 100 rupees in earnings to withdraw.");
  //       setShowWithdrawError(true);
  //       setTimeout(() => setShowWithdrawError(false), 3000);
  //       return;     }

  //     // Step 2: Check if the user has provided bank details
  //     try {
  //       // const userId = localStorage.getItem('loginUser'); // Fetch the userId from localStorage (or any other source)
  //       // const userObject = JSON.parse(userJson);

  //       const userJson = localStorage.getItem('loginUser');

  // // Check if the value exists in localStorage
  // if (userJson) {
  //   // Parse the JSON string into an object
  //   const userObject = JSON.parse(userJson);

  //   // Access the 'Id' field from the object
  //   const userId = userObject.Id;

  //   // Log the userId
  //   console.log(userId);
  // } else {
  //   console.log('User not found in localStorage.');
  // }

  //       console.log(userObject.Id);
  //       if (!userId) {
  //         setWithdrawError("User ID not found. Please log in.");
  //         setShowWithdrawError(true);
  //         setTimeout(() => setShowWithdrawError(false), 3000);
  //         return; // Exit if no UserId
  //       }

  //       const response = await axios.get(`https://api.iott.co.in/api/payment/GetUserTransactionDetails?UserId=${userId}`);
  //       console.log(`https://api.iott.co.in/api/payment/GetUserTransactionDetails?UserId=${userId}`)
  //       // Adjust this based on actual response structure
  //       const { hasBankDetails } = response.data; // Assuming the API returns an object with this field

  //       if (!hasBankDetails) {
  //         setWithdrawError("Please provide your bank details to proceed.");
  //         setShowWithdrawError(true);
  //         setTimeout(() => setShowWithdrawError(false), 3000);
  //         return; // Exit if no bank details
  //       }

  //       // Step 3: If both conditions are met, show the withdraw form
  //       setHide(true); // Assuming you have a state to handle showing the form
  //     } catch (error) {
  //       console.error('Error fetching transaction details:', error);

  //       // Handle different types of errors
  //       if (error.response) {
  //         setWithdrawError(`Error: ${error.response.data.message}`);
  //       } else if (error.request) {
  //         setWithdrawError("Network error: Unable to connect to the server.");
  //       } else {
  //         setWithdrawError("An unknown error occurred. Please try again.");
  //       }

  //       setShowWithdrawError(true);
  //       setTimeout(() => setShowWithdrawError(false), 3000);
  //     }
  //   };
  // const handleWithdrawClick = async () => {
  //   // Step 1: Check if the user has minimum earnings
  //   if (totalEarnings < 100) {
  //     setWithdrawError("You need a minimum of 100 rupees in earnings to withdraw.");
  //     setShowWithdrawError(true);
  //     setTimeout(() => setShowWithdrawError(false), 3000);
  //     return; // Exit early if this condition is not met
  //   }

  //   // Step 2: Check if the user has provided bank details
  //   try {
  //     // Fetch the userId from localStorage
  //     const userJson = localStorage.getItem('loginUser');

  //     if (!userJson) {
  //       setWithdrawError("User not found in localStorage. Please log in.");
  //       setShowWithdrawError(true);
  //       setTimeout(() => setShowWithdrawError(false), 3000);
  //       return; // Exit if no user data found in localStorage
  //     }

  //     // Parse the JSON string into an object
  //     const userObject = JSON.parse(userJson);
  //     const userId = userObject.Id;

  //     // Check if the userId exists
  //     if (!userId) {
  //       setWithdrawError("User ID not found. Please log in.");
  //       setShowWithdrawError(true);
  //       setTimeout(() => setShowWithdrawError(false), 3000);
  //       return; // Exit if no UserId
  //     }

  //     // Make API call to check if user has provided bank details
  //     const response = await axios.get(`https://api.iott.co.in/api/payment/GetUserTransactionDetails?UserId=${userId}`);
  //     console.log(`API called: https://api.iott.co.in/api/payment/GetUserTransactionDetails?UserId=${userId}`);

  //     // Adjust based on the actual response structure
  //     const { hasBankDetails } = response.data; // Assuming the API returns an object with this field

  //     if (!hasBankDetails) {
  //       setWithdrawError("Please provide your bank details to proceed.");
  //       setShowWithdrawError(true);
  //       setTimeout(() => setShowWithdrawError(false), 3000);
  //       return; // Exit if no bank details
  //     }

  //     // Step 3: If both conditions are met, show the withdraw form
  //     setHide(true); // Assuming you have a state to handle showing the form
  //   } catch (error) {
  //     console.error('Error fetching transaction details:', error);

  //     // Handle different types of errors
  //     if (error.response) {
  //       setWithdrawError(`Error: ${error.response.data.message}`);
  //     } else if (error.request) {
  //       setWithdrawError("Network error: Unable to connect to the server.");
  //     } else {
  //       setWithdrawError("An unknown error occurred. Please try again.");
  //     }

  //     setShowWithdrawError(true);
  //     setTimeout(() => setShowWithdrawError(false), 3000);
  //   }
  // };

  const subscriptionDetails = async (userId) => {
    try {
      const response = await axios.get(
        `${endpoints.videos_CheckSubscriptionStatus}?userId=${userId}`
      );

      // Destructure the data from the response
      const subscriptions = response.data;

      // console.log(subscriptions);

      // Check if the response is an array and log the IsSubscribed status for each subscription
      if (Array.isArray(subscriptions)) {
        subscriptions.forEach((subscription) => {
          // console.log(
          //   `SubscriptionId: ${subscription.SubscriptionId}, IsSubscribed: ${subscription.IsSubscribed}`
          // );
        });
      } else {
        console.error(
          "Expected an array of subscriptions but received:",
          subscriptions
        );
      }

      // If you want to return a boolean indicating if the user is subscribed to any subscription
      const isUserSubscribed = subscriptions.some(
        (subscription) => subscription.IsSubscribed
      );
      return isUserSubscribed; // Return true if subscribed, false otherwise
    } catch (err) {
      console.error("Error fetching subscription details:", err);
      return false; // Return false on error
    }
  };

  const checkBankDetails = async (userId) => {
    try {
      const response = await axios.get(
        `${endpoints.payment_DetailsCheck}?UserId=${userId}`
      );

      // Check if the response contains bank details information

      const { data } = response;
      // console.log(
      //   `https://api.iott.co.in/api/payment/DetailsCheck?UserId=${userId}`
      // );
      // console.log(data);
      // if (data && data.hasBankDetails) {
      if (data === 1) {
        // console.log("User has provided bank details.");
        return true; // User has bank details
      } else {
        // console.log("User has not provided bank details.");
        return false; // User has not provided bank details
      }
    } catch (error) {
      // console.error("Error while checking bank details:", error);
      setWithdrawError("Error while checking bank details:", error);
      return false; // Handle error gracefully
    }
  };

  const handleWithdrawClick = async () => {
    if (totalEarnings < 100) {
      setWithdrawError(
        "You need a minimum of 100 rupees in earnings to withdraw."
      );
      setShowWithdrawError(true);
      setTimeout(() => setShowWithdrawError(false), 3000);
      return;
    }

    try {
      const userJson = localStorage.getItem("loginUser");

      if (!userJson) {
        setWithdrawError("User not found in localStorage. Please log in.");
        setShowWithdrawError(true);
        setTimeout(() => setShowWithdrawError(false), 3000);
        return;
      }

      const userObject = JSON.parse(userJson);
      const userId = userObject.Id;
      // const userId = 38633;

      if (!userId) {
        setWithdrawError("User ID not found. Please log in.");
        setShowWithdrawError(true);
        setTimeout(() => setShowWithdrawError(false), 3000);
        return;
      }

      // Call to check if user has bank details
      const hasBankDetails = await checkBankDetails(userId);

      if (!hasBankDetails) {
        setWithdrawError("Please provide your bank details to proceed.");
        setShowWithdrawError(true);
        setTimeout(() => setShowWithdrawError(false), 3000);
        return;
      }

      // check user has active subscription
      const activeSubscriber = await subscriptionDetails(userId);
      if (!activeSubscriber) {
        setWithdrawError("Please take subscription.");
        setShowWithdrawError(true);
        setTimeout(() => setShowWithdrawError(false), 3000);
        return;
      }

      // Show the withdraw form if user has bank details
      setHide(true);
    } catch (error) {
      console.error("Error in handleWithdrawClick:", error);

      setWithdrawError("An error occurred. Please try again.");
      setShowWithdrawError(true);
      setTimeout(() => setShowWithdrawError(false), 3000);
    }
  };

  // const handleWithdrawClick = async () => {
  //   // Step 1: Check if the user has minimum earnings
  //   if (totalEarnings < 100) {
  //     setWithdrawError("You need a minimum of 100 rupees in earnings to withdraw.");
  //     setShowWithdrawError(true);
  //     setTimeout(() => setShowWithdrawError(false), 3000);
  //     return; // Exit early if this condition is not met
  //   }

  //   // Step 2: Check if the user has provided bank details
  //   try {
  //     const response = await axios.get('https://api.iott.co.in/api/payment/GetUserTransactionDetails?UserId=32621');

  //     // Assuming the API response contains a field 'hasBankDetails' indicating if bank details exist
  //     const hasBankDetails = response.data;
  //     // console.log("bankdetails", hasBankDetails) // Adjust based on actual response structure
  //     console.log(response.data);
  //     if (!hasBankDetails) {
  //       setWithdrawError("Please provide your bank details to proceed.");
  //       setShowWithdrawError(true);
  //       setTimeout(() => setShowWithdrawError(false), 3000);
  //       return; // Exit if no bank details
  //     }

  //     // Step 3: If both conditions are met, show the withdraw form
  //  setHide(true)// Assuming you have a state to handle showing the form
  //   } catch (error) {
  //     console.error('Error fetching transaction details:', error);
  //     setWithdrawError("An error occurred while checking your bank details. Please try again.");
  //     setShowWithdrawError(true);
  //     setTimeout(() => setShowWithdrawError(false), 3000);
  //   }
  // };

  const handleCopyReferralCode = () => {
    navigator.clipboard.writeText(referralCode);
    setButtonText("Copied");

    // Optionally reset the button text after 3 seconds
    setTimeout(() => setButtonText("Copy Referral Code"), 3000);
  };

  const navigate = useNavigate();
  const handleAddBankDetailsClick = () => {
    navigate("/wallet", { state: { from: window.location.pathname } }); // Replace with the actual path for your Add Bank Details page
  };

  return (
    <>
      <Header />
      <Grid
        sx={{
          height: "100vh",
          display: "flex",
          // alignItems: "center",
          // justifyContent: "center",
          bgcolor: "#010113",
          textAlign: "center",
          fontFamily: "Quicksand",
          pt: 2,
        }}
        display={"flex"}
        flexDirection={"column"}
      >
        <Link
          component={RouterLink}
          to="/HowtoEarn"
          underline="always"
          sx={{
            marginLeft: "70vw",
            color: "white",
            textDecorationColor: "white",
            fontWeight: "700",
          }}
        >
          How to earn?
        </Link>

        <Container
          sx={{
            textAlign: "center",
            width: { xs: "100vw", sm: "80vw", md: "60vw" },
            // mt: { xs: 20, sm: 0 },
            mb: { xs: 20, sm: 0 },
          }}
        >
          {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%", // Ensure full container width
                  mt: 5, // Margin top to adjust spacing as needed
                }}
              >
  
            </Box> */}

          <Typography
            sx={{
              font: "Quicksand",
              fontWeight: "700",
              fontSize: { xs: "15px", sm: "20px", md: "32px" },
              color: "#FFDA78",
              marginBottom: "20px",
            }}
          >
            Refer & Earn
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
              width: "100%",
              gap: { xs: "8px", sm: "16px" },

            }}
          >
            {/* TextField */}
            <TextField
              value={referralCode}
              sx={{
                width: { xs: "85vw", sm: "calc(3 * 120px + 2 * 10px)" },
                height: "40px",
                "& .MuiInputBase-root": {
                  height: "100%",
                  bgcolor: "white",
                  color: "black",
                },
                fontSize: "14px",
              }}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
            />

            {/* Buttons Container */}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                gap: "16px",
              }}
            >
              {/* <Button
                variant="contained"
                sx={{
                  borderRadius: "5px",
                  font: "Nunito",
                  fontWeight: "700",
                }}
                onClick={handleCopyReferralCode}
              >
                {buttonText}
              </Button> */}

              <IconButton
                sx={{
                  display: { xs: "inline-flex", sm: "none" },
                  color: "#fff",
                }}
                onClick={() => navigator.clipboard.writeText(referralCode)}
              >
                <ShareModal openModal={openModal} closeIcon={handleClose} />

                <Share />
              </IconButton>
              <Button
                variant="contained"
                sx={{
                  minWidth: "150px",
                  width: { xs: "85vw", sm: "auto" },
                  height: "40px",
                  padding: "0 20px", 
                  borderRadius: "5px",
                  fontFamily: "Quicksand",
                  fontWeight: "700",
                  fontSize: { xs: "12px", sm: "14px" }, 
                  textAlign: "center",
                  whiteSpace: "nowrap",
                }}
                onClick={handleCopyReferralCode}
              >
                {buttonText}
              </Button>

              {/* Share Button */}
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{
                  minWidth: "150px",
                  width: { xs: "85vw", sm: "auto" }, 
                  height: "40px", 
                  padding: "0 20px", 
                  borderRadius: "5px",
                  fontFamily: "Quicksand",
                  fontWeight: "700",
                  fontSize: { xs: "12px", sm: "14px" }, 
                  textAlign: "center",
                }}
              >
                Share
              </Button>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" }, // Stack vertically on mobile, horizontally on larger screens
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
              width: "100%",
              gap: { xs: "8px", sm: "16px" }, // Adjust gap for smaller screens
            }}
          >
            <Box>
              <Button
                variant="contained"
                sx={{
                  fontFamily: "Quicksand",
                  fontWeight: "700",
                  borderRadius: "5px",
                  marginRight: "10px",
                  bgcolor: "white",
                  width: { xs: "60vw", sm: "85px" }, // Match width with TextField
                  ...getButtonStyle(1),
                }}
                onClick={() => handleLevelClick(1)}
              >
                Level 1
              </Button>

              <Button
                variant="contained"
                sx={{
                  fontFamily: "Quicksand",
                  fontWeight: "700",
                  borderRadius: "5px",
                  marginRight: "10px",
                  bgcolor: "white",
                  width: { xs: "60vw", sm: "86px" }, // Match width with TextField
                  ...getButtonStyle(2),
                }}
                onClick={() => handleLevelClick(2)}
              >
                Level 2
              </Button>

              <Button
                variant="contained"
                sx={{
                  fontFamily: "Quicksand",
                  fontWeight: "700",
                  borderRadius: "5px",
                  marginRight: "10px",
                  bgcolor: "white",
                  width: { xs: "60vw", sm: "85px" }, // Match width with TextField
                  ...getButtonStyle(3),
                }}
                onClick={() => handleLevelClick(3)}
              >
                Level 3
              </Button>
            </Box>

            <Button
              variant="contained"
              sx={{
                fontFamily: "Quicksand",
                minWidth: "188px",
                fontWeight: "700",
                borderRadius: "5px",
                padding: "0 20px",
                width: "193px",
                marginLeft: "57px",
                display: { xs: "none", sm: "block" },
                whiteSpace: "nowrap",
                height: "40px", // Keeps the button responsive
              }}
              onClick={handleAddBankDetailsClick}
            >
              Add Bank Details
            </Button>
            <Button
              variant="contained"
              onClick={handleWithdrawClick}
              sx={{
                minWidth: "150px", // Match width with Copy Referral Code button
                width: { xs: "85vw", sm: "auto" }, // Ensure proper responsiveness
                height: "40px", // Set a consistent height for the button
                padding: "0 20px", // Adjust padding to maintain button size
                borderRadius: "5px",
                fontFamily: "Quicksand",
                fontWeight: "700",
                fontSize: { xs: "12px", sm: "14px" },
                display: { xs: "none", sm: "block" }, // Adjust font size for different screens
                textAlign: "center",
              }}
            >
              Withdraw
            </Button>
          </Box>

          {showWithdrawError && withdrawError && (
            <Typography
              sx={{
                color: "red",
                marginBottom: "20px",
                font: "Quicksand",
                fontWeight: "700",
              }}
            >
              {withdrawError}
            </Typography>
          )}

          {/* {setAmountError && (
            <Typography
              sx={{
                color: "red",
                marginBottom: "20px",
                font: "Quicksand",
                fontWeight: "700",
              }}
            >
              {amountError}
            </Typography>
          )} */}
          <Box ml={{ xs: 20 }} mb={2}>
            <Button
              variant="contained"
              sx={{
                font: "Quicksand",
                fontWeight: "700",
                borderRadius: "5px",
                marginLeft: "auto",
                display: { sm: "none" },
              }}
              onClick={handleWithdrawClick}
            >
              Withdraw
            </Button>
          </Box>
          {!hide ? (
            <TableContainer
              sx={{
                font: "Quicksand",
                fontWeight: "200 !important",
                width: "100%",
                margin: "auto",
                maxHeight: { xs: "200px", sm: "250px" },
                overflowY: "auto",
                overflowX: "auto",
                "&::-webkit-scrollbar": {
                  width: "4px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#000",
                  borderRadius: "2px",
                },
              }}
            >
              <Table
                sx={{
                  textAlign: "center",
                  alignContent: "center",
                }}
              >
                <TableHead
                  sx={{
                    bgcolor: "#FFDA78",
                    font: "QuickSand",
                    fontWeight: "700",
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  <TableRow>
                    <TableCell
                      sx={{
                        font: "Quicksand",
                        fontWeight: "900 !important",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}
                    >
                      Sno
                    </TableCell>
                    <TableCell
                      sx={{
                        font: "Quicksand",
                        fontWeight: "900 !important",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}
                    >
                      Id
                    </TableCell>
                    <TableCell
                      sx={{
                        font: "Quicksand",
                        fontWeight: "900 !important",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      sx={{
                        font: "Quicksand",
                        fontWeight: "900 !important",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}
                    >
                      Earnings
                    </TableCell>
                    <TableCell
                      sx={{
                        font: "Quicksand",
                        fontWeight: "900 !important",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}
                    >
                      Subscription
                    </TableCell>
                  </TableRow>
                </TableHead>
                {details.length && details[0].message !== "No Data Found" ? (
                  details.map((each, index) => (
                    <TableRow key={each.Id}>
                      <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                        {index + 1}
                      </TableCell>
                      <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                        {each.UserId}
                      </TableCell>
                      <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                        {each.UserName}
                      </TableCell>
                      <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                        {each.BonusAmount}
                      </TableCell>
                      <TableCell
                        sx={{
                          color: each.IsPurchased ? "#5fde10" : "red",
                          textAlign: "center",
                        }}
                      >
                        {booleanToString(each.IsPurchased)}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      sx={{ color: "red", textAlign: "center" }}
                    >
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </Table>
            </TableContainer>
          ) : (
            <TableContainer
              sx={{
                font: "Quicksand",
                fontWeight: "700",
                width: "100%",
                margin: "auto",
              }}
            >
              <Table
                ref={tableRef}
                sx={{
                  textAlign: "center",
                  alignContent: "center",
                  bgcolor: "white",
                  height: "50vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <IconButton
                  sx={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    width: "40px",
                    height: "40px",
                    // fontWeight: "600",
                    borderRadius: "None",
                  }}
                  variant="contained"
                  onClick={() => setHide(false)}
                >
                  x
                </IconButton>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  {/* <Typography
                    sx={{
                      font: "Quicksand",
                      fontWeight: "700",
                    }}
                    onClick={()=>setHide(false)}
                  >
                  X
                  </Typography> */}

                  <Typography
                    sx={{
                      font: "Quicksand",
                      fontWeight: "700",
                    }}
                  >
                    Enter Amount
                  </Typography>

                  <TextField
                    placeholder="Enter amount"
                    value={amount}
                    onChange={handleAmountChange}
                    sx={{
                      width: "250px",
                      "& .MuiInputBase-root": {
                        height: 30,
                        bgcolor: "#FFDA78",
                        color: "#000000",
                        fontWeight: 600,
                      },
                    }}
                  />
                </Box>
                <Button
                  sx={{
                    mt: 2,
                    borderRadius: "5px",
                    alignSelf: "center",
                    alignItems: "center",
                    fontWeight: "900",
                  }}
                  variant="contained"
                  onClick={handleConfirmClick}
                >
                  Confirm
                </Button>

                {withdrawError && (
                  <Typography
                    sx={{
                      color: "red",
                      marginTop: "10px",
                      font: "Quicksand",
                      fontWeight: "700",
                    }}
                  >
                    {withdrawError}
                  </Typography>
                )}

                {showConfirmationMessage && (
                  <Typography
                    sx={{
                      color: "#6EC207",
                      marginTop: "10vh",
                      font: "Quicksand",
                      fontWeight: "700",
                    }}
                  >
                    Amount will credit in 4 business days
                  </Typography>
                )}
                {confirmMessage && (
                  <Typography
                    sx={{
                      color: "red",
                      marginTop: "10px",
                      font: "Quicksand",
                      fontWeight: "700",
                    }}
                  >
                    {confirmMessage}
                  </Typography>
                )}
              </Table>
            </TableContainer>
          )}

          <Typography
            sx={{
              font: "Quicksand",
              fontWeight: "700",
              color: "#FFDA78",
              marginTop: hide ? "20px" : "12px",
            }}
          >
            Total Earnings: {totalEarnings}
          </Typography>
          {error && (
            <Typography
              sx={{
                color: "red",
                marginBottom: "20px",
              }}
            >
              {error}
            </Typography>
          )}
        </Container>
      </Grid>
      <Footer />
    </>
  );
};
export default ReferAndEarn;
