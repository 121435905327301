import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const useLoginCheck = (authData, ipAddress) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleIsLoginCheck = async () => {
      if (!authData?.auth?.Id || !authData?.authToken || !ipAddress) {
        navigate("/login", { state: { from: location.pathname } });
        console.warn("Missing required data for login check.");
        localStorage.clear();
        return;
      }

      const options = {
        method: "GET",
        headers: { Authorization: `Bearer ${authData?.authToken}` },
      };

      try {
        const response = await fetch(
          `https://api.iott.co.in/api/user/IsLogin?UserId=${authData?.auth?.Id}&DeviceName=${ipAddress}`,
          options
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }

        const isLoginData = await response.json();

        if (isLoginData !== 1) {
          console.warn("User not logged in. Redirecting to login page.");
          // console.log("Redirecting to login from:", location.pathname);
          navigate("/login", { state: { from: location.pathname } });
          localStorage.clear();
        }
      } catch (error) {
        console.error("Error in handleIsLoginCheck:", error.message);
      }
    };

    handleIsLoginCheck();
  }, [authData, ipAddress, navigate, location]);
};

export default useLoginCheck;



///////////// verification use in components/////////////
// const handleIsLoginCheck = async (authData, ipAddress, navigate, location) => {
//   console.log("authData", authData, ipAddress)
//   if (!authData?.auth?.Id || !authData?.authToken) {
//     console.warn("Missing required data for login check. Redirecting to login...");
//     navigate("/login", { state: { from: location.pathname } });
//     localStorage.clear();
//     return;
//   }

//   if (!ipAddress) {
//     console.warn("IP address not available for login check.");
//     return; // Wait for IP address to be available.
//   }

//   const options = {
//     method: "GET",
//     headers: { Authorization: `Bearer ${authData?.authToken}` },
//   };

//   try {
//     // Check if the login is valid for the current IP address.
//     const response = await fetch(
//       `https://api.iott.co.in/api/user/IsLogin?UserId=${authData?.auth?.Id}&DeviceName=${ipAddress}`,
//       options
//     );

//     if (!response.ok) {
//       throw new Error(`Error: ${response.status} - ${response.statusText}`);
//     }

//     const isLoginData = await response.json();

//     if (parseInt(isLoginData, 10) !== 1) {
//       console.warn("User not logged in for this IP address. Redirecting to login...");
//       navigate("/login", { state: { from: location.pathname } });
//       localStorage.clear();
//     } else {
//       console.log("User is logged in for this IP address.");
//     }
//   } catch (error) {
//     console.error("Error in handleIsLoginCheck:", error.message);
//   }
// };

// useEffect(() => {
//   if (authData?.auth?.Id && authData?.authToken && ipAddress) {
//     handleIsLoginCheck(authData, ipAddress, navigate, location);
//   }
// }, [authData?.auth?.Id, authData?.authToken, ipAddress, location.pathname]);


// const MyComponent = ({ authData, ipAddress }) => {
//   const navigate = useNavigate(); 
//   const location = useLocation();  

//   useEffect(() => {
//     const performLoginCheck = async () => {
//       await handleIsLoginCheck(authData, ipAddress, navigate, location);
//     };

//     performLoginCheck();
//   }, [authData, ipAddress, navigate, location]);
// }



// useEffect(() => {
//   const performLoginCheck = async () => {
//     await handleIsLoginCheck(authData, ipAddress, navigate, location);
//   };
//   performLoginCheck();
// }, [authData, ipAddress, navigate, location]);
